import { createAction } from 'redux-actions';

export const initWellboreLists = createAction('MyLists/INIT');
export const fetchMyListsRequested = createAction('MyLists/INIT_FETCH_REQUESTED');
export const fetchMyListsSucceeded = createAction('MyLists/INIT_FETCH_SUCCEDED');
export const fetchMyListsFailed = createAction('MyLists/INIT_FETCH_FAILED');

export const addWellboreList = createAction('MyLists/ADD_WELLBORE_LIST');
export const addWellboreListRequested = createAction('MyLists/ADD_WELLBORE_LIST_REQUESTED');
export const addWellboreListSucceeded = createAction('MyLists/ADD_WELLBORE_LIST_SUCCEDED');
export const addWellboreListFailed = createAction('MyLists/ADD_WELLBORE_LIST_FAILED');

export const removeWellboreList = createAction('MyLists/REMOVE_WELLBORE_LIST');
export const removeWellboreListRequested = createAction('MyLists/REMOVE_WELLBORE_LIST_REQUESTED');
export const removeWellboreListSucceeded = createAction('MyLists/REMOVE_WELLBORE_LIST_SUCCEDED');
export const removeWellboreListFailed = createAction('MyLists/REMOVE_WELLBORE_LIST_FAILED');

export const changeEditedWellboreList = createAction('MyLists/CHANGE_EDITED_WELLBORE_LIST');
export const renameWellboreList = createAction('MyLists/RENAME_WELLBORE_LIST');
export const renameWellboreListRequested = createAction('MyLists/RENAME_WELLBORE_LIST_REQUESTED');
export const renameWellboreListSucceeded = createAction('MyLists/RENAME_WELLBORE_LIST_SUCCEDED');
export const renameWellboreListFailed = createAction('MyLists/RENAME_WELLBORE_LIST_FAILED');

export const addWellboreToMyLists = createAction('MyLists/ADD_WELLBORE');
export const addWellboreToMyListsRequested = createAction('MyLists/ADD_WELLBORE_REQUESTED');
export const addWellboreToMyListsSucceeded = createAction('MyLists/ADD_WELLBORE_SUCCEDED');
export const addWellboreToMyListsFailed = createAction('MyLists/ADD_WELLBORE_FAILED');

export const addWellboreToSpecificList = createAction('MyLists/ADD_WELLBORE_TO_SPECIFIC_LIST');
export const addWellboreToSpecificListRequested = createAction('MyLists/ADD_WELLBORE_TO_SPECIFIC_LIST_REQUESTED');
export const addWellboreToSpecificListSucceeded = createAction('MyLists/ADD_WELLBORE_TO_SPECIFIC_LIST_SUCCEDED');
export const addWellboreToSpecificListFailed = createAction('MyLists/ADD_WELLBORE_TO_SPECIFIC_LIST_FAILED');

export const removeWellbore = createAction('MyLists/REMOVE_WELLBORE');
export const removeWellboreRequested = createAction('MyLists/REMOVE_WELLBORE_REQUESTED');
export const removeWellboreSucceeded = createAction('MyLists/REMOVE_WELLBORE_SUCCEDED');
export const removeWellboreFailed = createAction('MyLists/REMOVE_WELLBORE_FAILED');

export const moveWellbore = createAction('MyLists/MOVE_WELLBORE');
export const moveWellboreRequested = createAction('MyLists/MOVE_WELLBORE_REQUESTED');
export const moveWellboreSucceeded = createAction('MyLists/MOVE_WELLBORE_SUCCEDED');
export const moveWellboreFailed = createAction('MyLists/MOVE_WELLBORE_FAILED');

export const selectWellboreList = createAction('MyLists/SELECT_WELLBORE_LIST');
export const getSelectedWellboreListContentsRequested = createAction('MyLists/GET_SELECTED_WELLBORELIST_CONTENTS_REQUESTED');
export const getSelectedWellboreListContentsSucceeded = createAction('MyLists/GET_SELECTED_WELLBORELIST_CONTENTS_SUCCEDED');
export const getSelectedWellboreListContentsFailed = createAction('MyLists/GET_SELECTED_WELLBORELIST_CONTENTS_FAILED');

export const getRecommendedWellbores = createAction('MyLists/GET_RECOMMENDED_WELLBORES');
export const getRecommendedWellboresRequested = createAction('MyLists/GET_RECOMMENDED_WELLBORES_REQUESTED');
export const getRecommendedWellboresSucceeded = createAction('MyLists/GET_RECOMMENDED_WELLBORES_SUCCEDED');
export const getRecommendedWellboresFailed = createAction('MyLists/GET_RECOMMENDED_WELLBORES_FAILED');
