import React, { Component } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { filterConstants } from '../../types';

const FILTERID_DEPTH = 'depthFilter';
const FILTERNAME_DEPTH = 'Depth';

export default class Depth extends Component {
  constructor(props) {
    super(props);

    this.ID = FILTERID_DEPTH;
    this.name = FILTERNAME_DEPTH;

    this.state = {
      min: props.minValue,
      max: props.maxValue,
    };
  }

  getID = () => this.ID

  getName = () => this.name

  handleChangeMinValue = (event) => {
    let newMinValue = parseInt(event.target.value);
    if (isNaN(newMinValue)) {
      newMinValue = null;
    }

    this.props.onChangeMinValue(newMinValue);
    this.setState({ min: event.target.value });
  }

  handleChangeMaxValue = (event) => {
    let newMaxValue = parseInt(event.target.value);
    if (isNaN(newMaxValue)) {
      newMaxValue = null;
    }

    this.props.onChangeMaxValue(newMaxValue);
    this.setState({ max: event.target.value });
  }

  render() {
    const inputProps = {
      step: 500,
      min: filterConstants.DEFAULT_MIN_DEPTH,
      max: filterConstants.DEFAULT_MAX_DEPTH,
    };

    return (
      <div>
        <div className="row justify-content-start mb-3">
          <div className="col-sm-3">
            <span className="input-group-text w-100 flex-row-reverse text-info">Depth</span>
          </div>

          <div className="col-sm-2">
            <TextField
              id="outlined-number"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              value={this.state.min}
              onChange={this.handleChangeMinValue}
              inputProps={inputProps}
              InputProps={{
                startAdornment: <InputAdornment position="start">Min</InputAdornment>,
                endAdornment: <InputAdornment position="end">meters</InputAdornment>,
              }}
              error={!this.props.isDepthFilterValid}
              helperText={this.props.depthFilterErrorMessage}
            />
          </div>

          <div className="col-sm-2">
            <TextField
              id="outlined-number"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              value={this.state.max}
              onChange={this.handleChangeMaxValue}
              inputProps={inputProps}
              InputProps={{
                startAdornment: <InputAdornment position="start">Max</InputAdornment>,
                endAdornment: <InputAdornment position="end">meters</InputAdornment>,
              }}
              error={!this.props.isDepthFilterValid}
            />
          </div>
        </div>
      </div>
    );
  }
}
