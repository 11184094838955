import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { addToast, ToastTypes } from '../utils/addToastByType';
import * as actions from '../store';
import * as selectors from '../store/selectors';
import {
  targetDivs, rankingMethods, rankingTypes, sectionTypes, filterConstants, getDisplayNameForSectionGroup,
} from '../types';
import { FilterTypes, FilterPropertyPath } from '../components/filters/FilterUtils';
import Panel from '../components/common/Panel';
import SearchResults from '../components/search/results';
import NumberOfResultsDisplayedFilter from '../components/filters/numberOfResultsDisplayedFilter';
import { Title } from '../utils/main';
import ButtonForSelectedWellbores from '../components/search/ButtonForSelectedWellbores';
import './ResultsPanel.css';

class ResultsPanel extends Component {
  static propTypes = {
    getResults: PropTypes.func.isRequired,
    getRankedGroupNames: PropTypes.func.isRequired,
  }

  addSelectedWellboresToMyLists = () => {
    if (this.props.selectedWellPathsForWellLine.length > 0) {
      this.props.selectedWellPathsForWellLine.forEach((selectedWellboreUniqueId) => {
        this.props.addWellboreToMyLists(selectedWellboreUniqueId);
      });
      const addedWellbores = _.join(this.props.selectedWellPathsForWellLine, ',');
      addToast({ text: `Wellbores ${addedWellbores} was added to default list!`, type: ToastTypes.INFO });
    }
  }

  showWellLineForSelectedWellPaths = () => {
    if (this.props.selectedWellPathsForWellLine.length > 0) {
      // TODO: move this fixed url to config
      let url = 'https://wellline.equinor.com/diwa/timeline/s=[';

      for (let i = 0; i < this.props.selectedWellPathsForWellLine.length; i++) {
        if (i !== 0) url += ',';
        url += `well.${encodeURIComponent(this.props.selectedWellPathsForWellLine[i])}`;
      }

      url += ']';

      window.open(url, '_blank');
    }
  }

  handleChangeMaxDisplayed = (event) => {
    this.props.setLoading(true);
    const value = parseInt(event.target.value);
    setTimeout(() => {
      this.props.setMaxDisplayed(value);
      this.props.setLoading(false);
    }, 1000);
  }

  areFiltersApplied = () => {
    const {
      minDepth, maxDepth, minDate, maxDate, selectedFields, selectedFormations, selectedSections, selectedRigs, selectedSectionGroups, minimumScore, rankingType,
    } = this.props;
    return minDepth !== filterConstants.DEFAULT_MIN_DEPTH
      || maxDepth !== filterConstants.DEFAULT_MAX_DEPTH
      || minDate !== null
      || maxDate !== null
      || selectedFields.length > 0
      || selectedFormations.length > 0
      || selectedSections[sectionTypes.DRILLING].length > 0
      || selectedSections[sectionTypes.COMPLETION].length > 0
      || selectedSectionGroups.length > 0
      || selectedRigs.length > 0
      || minimumScore(rankingType) > 0;
  }

  getCurrentSectionRankingFilters = () => {
    const filters = [];

    // GroupresultName
    if (this.props.rankingType === rankingTypes.SECTION_RANKING && this.props.getSelectedResultGroup !== '') {
      filters.push({
        name: FilterPropertyPath.RESULT_GROUP_SECTION_NAME,
        values: [this.props.getSelectedResultGroup],
        unique: true,
        type: FilterTypes.ELEMENTS,
      });

      // Minimum score
      filters.push({
        name: FilterPropertyPath.MINIMUM_SCORE,
        values: [parseFloat(this.props.minimumScore(rankingTypes.SECTION_RANKING)) / 100, 1], // Convert % to decimal
        unique: false,
        type: FilterTypes.RANGE,
        checkAll: false,
      });
    }
    return filters;
  }

  getCurrentWellboreRankingFilters = () => {
    const filters = [];

    // Depth
    if (this.props.minDepth !== filterConstants.DEFAULT_MIN_DEPTH || this.props.maxDepth !== filterConstants.DEFAULT_MAX_DEPTH) {
      filters.push({
        name: FilterPropertyPath.DEPTH,
        values: [this.props.minDepth, this.props.maxDepth],
        unique: true,
        type: FilterTypes.RANGE,
      });
    }

    // Last section
    filters.push({
      name: FilterPropertyPath.LAST_SECTION,
      values: [this.props.minDate, this.props.maxDate],
      unique: true,
      type: FilterTypes.RANGE,
    });

    // Field
    if (this.props.selectedFields.length > 0) {
      filters.push({
        name: FilterPropertyPath.FIELD,
        values: this.props.selectedFields,
        unique: true,
        type: this.props.isExcludeOn(filterConstants.FILTER_FIELD) ? FilterTypes.EXCLUDED_ELEMENTS : FilterTypes.ELEMENTS,
      });
    }

    // Formation
    filters.push({
      name: FilterPropertyPath.FORMATION,
      values: this.props.selectedFormations,
      unique: false,
      type: FilterTypes.ELEMENTS,
      checkAll: true,
    });

    // Drilling Section
    filters.push({
      name: FilterPropertyPath.DRILLING_SECTION,
      values: this.props.selectedSections[sectionTypes.DRILLING],
      unique: false,
      type: FilterTypes.ELEMENTS,
      checkAll: true,
    });

    // Completion Section
    filters.push({
      name: FilterPropertyPath.COMPLETION_SECTION,
      values: this.props.selectedSections[sectionTypes.COMPLETION],
      unique: false,
      type: FilterTypes.ELEMENTS,
      checkAll: true,
    });

    // Rig
    if (this.props.selectedRigs.length > 0) {
      filters.push({
        name: FilterPropertyPath.RIG,
        values: this.props.selectedRigs,
        unique: false,
        type: FilterTypes.ELEMENTS,
        checkAll: false,
      });
    }

    // Section groups
    if (this.props.selectedSectionGroups.length > 0) {
      filters.push({
        name: FilterPropertyPath.SECTION_GROUP,
        values: this.props.selectedSectionGroups,
        unique: false,
        type: FilterTypes.MULTIPLE_PROPERTIES,
        checkAll: false,
      });
    }

    // Minimum score
    filters.push({
      name: FilterPropertyPath.MINIMUM_SCORE,
      values: [parseFloat(this.props.minimumScore(rankingTypes.WELLBORE_RANKING)) / 100, 1],
      unique: false,
      type: FilterTypes.RANGE,
      checkAll: false,
    });

    return filters;
  }

  emptyOrNan = (input) => input.trim().length === 0 || Number.isNaN(input);

  hasValidDepths = (groupFilter) => {
    if (groupFilter.length === 0) {
      return true;
    }
    const selectedGroupName = groupFilter[0].values[0];
    if (selectedGroupName.length === 0) {
      return true;
    }
    const tempData = selectedGroupName.split('(');
    if (tempData.length !== 2) {
      return true;
    }
    const depthStrings = tempData[1].split('-');
    return !this.emptyOrNan(depthStrings[0].replace(/\D/g,'')) && !this.emptyOrNan(depthStrings[1].replace(/\D/g,''));
  }

  getCurrentFilters = (rankingType) => (
    (this.isWellboreRanking(rankingType))
      ? this.getCurrentWellboreRankingFilters()
      : this.getCurrentSectionRankingFilters());

  isWellboreRanking = (rankingType) => (rankingType === rankingTypes.WELLBORE_RANKING);

  updateSelectedWellPaths = (wellPathUID, checked) => {
    if (checked && !this.isWellPathSelected(wellPathUID)) {
      this.props.addSelectedWellPathForWellLine(wellPathUID);
    } else if (!checked && this.isWellPathSelected(wellPathUID)) {
      this.props.removeSelectedWellPathForWellLine(wellPathUID);
    }
  }

  isWellPathSelected = (wellPathUID) => this.props.selectedWellPathsForWellLine.indexOf(wellPathUID) !== -1;

  getResultsPanelTitle = (type) => {
    const resultsCount = (this.props.rankingType === rankingTypes.SECTION_RANKING && this.props.getSelectedResultGroup !== '') ? this.props.getFilteredListSize : this.props.getResults(type).length;
    const filtersApplied = this.areFiltersApplied();
    const isWellboreRanking = this.isWellboreRanking(this.props.rankingType);
    const {
      selectedWellPathsForWellLine,
      getFilteredListSize,
      isDeveloper,
      maxDisplayed,
      setMaxDisplayed,
    } = this.props;

    return (
      <div className="card-header">
        <div className="row" style={{ marginRight: '10px' }}>
          <div className="col-sm-8">
            <Title value="Results" />
            <p className="text-warning-search">
              {this.props.rankingType === rankingTypes.SECTION_RANKING && !this.hasValidDepths(this.getCurrentFilters(this.props.rankingType))
                ? 'Warning: Missing hole section depth. Wellpath and formation ranking are not performed.'
                : null }
            </p>
          </div>
          <div className="col-sm-4" align="right">
            {resultsCount > 0
              && (
              <div className="row">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    {selectedWellPathsForWellLine.length > 0 && (
                    <ButtonForSelectedWellbores onClick={this.addSelectedWellboresToMyLists}>Add to my lists</ButtonForSelectedWellbores>
                    )}
                    {selectedWellPathsForWellLine.length > 0 && isDeveloper && (
                    <ButtonForSelectedWellbores onClick={this.showWellLineForSelectedWellPaths}>View in WellLine</ButtonForSelectedWellbores>
                    )}
                    <span className="input-group-text text-info">Showing </span>
                  </div>

                  <NumberOfResultsDisplayedFilter
                    totalResultsCount={resultsCount}
                    filteredResultsCount={getFilteredListSize}
                    onChange={this.handleChangeMaxDisplayed}
                    filtersApplied={filtersApplied}
                    maxDisplayed={maxDisplayed}
                    setMaxDisplayed={setMaxDisplayed}
                  />

                  <div className="input-group-append">
                    <span className="input-group-text text-info">
                      of
                      {' '}
                      {(filtersApplied && isWellboreRanking) ? `${getFilteredListSize} (filtered)` : resultsCount}
                      {' '}
                      results
                    </span>
                  </div>
                </div>
              </div>
              )}
            <p className="text-muted">
              Click on a wellbore to view in REP
              {isDeveloper ? 'or select wellbores from checkbox to view in WellLine' : ''}
            </p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      // Store
      isDeveloper,
      isElementVisible,
      toggleElementVisibility,
      loadingFilters,
      getRankingInput,
      sourceWellPathFileName,
      rankingType,
      getResults,
      maxDisplayed,
      minimumScore,
      setSelectedResultGroup,
      getSelectedResultGroup,
      setFilteredListSize,
    } = this.props;

    return (
      <Panel
        panelId={targetDivs.RESULTS}
        title="Results"
        toggle={toggleElementVisibility}
        isVisible={isElementVisible}
        isLoading={loadingFilters}
        titleView={this.getResultsPanelTitle(rankingType)}
        navItems={rankingType === rankingTypes.SECTION_RANKING
          ? this.props.getRankedGroupNames(rankingTypes.SECTION_RANKING) : null}
        navItemLabelFunction={getDisplayNameForSectionGroup}
        selectNavItem={setSelectedResultGroup}
        selectedNavItem={getSelectedResultGroup}
      >
        <SearchResults
          results={getResults(rankingType)}
          filters={this.getCurrentFilters(rankingType)}
          updateSelectedWellPaths={this.updateSelectedWellPaths}
          setFilteredListSize={setFilteredListSize}
          maxDisplayed={maxDisplayed}
          minimumScore={minimumScore(rankingType)}
          sourceWellPathName={sourceWellPathFileName(rankingType)}
          sourceWellPathCoordinates={getRankingInput(rankingType, rankingMethods.GEOMETRIC)}
          sectionRanking={rankingType === rankingTypes.SECTION_RANKING}
          isDeveloper={isDeveloper}
          isWellPathSelected={this.isWellPathSelected}
        />
      </Panel>
    );
  }
}

const mapStateToProps = (state) => ({
  // Auth
  isDeveloper: selectors.isDeveloper(state),

  // General
  isElementVisible: (elementId) => selectors.isElementVisible(state, elementId),
  getSelectedResultGroup: selectors.getSelectedResultGroup(state),
  rankingType: selectors.getRankingType(state),

  // Search
  // --- Ranking Methods
  getRankingInput: (type, method) => selectors.getRankingInput(state, type, method),
  // --- Source WellPath
  sourceWellPathFileName: (type) => selectors.getSourceWellPathFileName(state, type),
  // --- Process
  loading: selectors.isLoading(state),
  loadingFilters: selectors.isLoadingFilters(state),
  selectedWellPathsForWellLine: selectors.getSelectedWellPathsForWellLine(state),
  maxDisplayed: selectors.getMaxDisplayed(state),
  getFilteredListSize: selectors.getFilteredListSize(state),
  // --- Results
  getResults: (type) => selectors.getResults(state, type),
  getRankedGroupNames: (type) => selectors.getRankedGroupNames(state, type),

  // Filters
  // --- Depth
  minDepth: selectors.getMinDepth(state),
  maxDepth: selectors.getMaxDepth(state),
  // --- Date
  minDate: selectors.getMinDate(state),
  maxDate: selectors.getMaxDate(state),
  // Minimum score
  minimumScore: (type) => selectors.getMinimumScore(state, type),
  // --- Field
  selectedFields: selectors.getSelectedFields(state),
  // --- Formation
  selectedFormations: selectors.getSelectedFormations(state),
  // --- Section
  selectedSections: {
    [sectionTypes.DRILLING]: selectors.getSelectedSections(state, sectionTypes.DRILLING),
    [sectionTypes.COMPLETION]: selectors.getSelectedSections(state, sectionTypes.COMPLETION),
  },
  // --- Rig
  selectedRigs: selectors.getSelectedRigs(state),
  // --- SectionGroup
  selectedSectionGroups: selectors.getSelectedSectionGroups(state),
  // --- Exclude
  isExcludeOn: (type) => selectors.isExcludeOn(state, type),
});

const mapDispatchToProps = (dispatch) => ({
  // General
  toggleElementVisibility: (elementId) => dispatch(actions.toggleElementVisibility(elementId)),
  setSelectedResultGroup: (resultGroupName) => dispatch(actions.setSelectedResultGroup(resultGroupName)),
  // Search
  // --- Process
  setLoading: (status) => dispatch(actions.setLoading(status)),
  addSelectedWellPathForWellLine: (wp) => dispatch(actions.addSelectedWellPathForWellLine(wp)),
  removeSelectedWellPathForWellLine: (wp) => dispatch(actions.removeSelectedWellPathForWellLine(wp)),
  setMaxDisplayed: (num) => dispatch(actions.setMaxDisplayed(num)),
  setFilteredListSize: (size) => dispatch(actions.setFilteredListSize(size)),

  // MyLits
  addWellboreToMyLists: (wellboreInfo) => dispatch(actions.addWellboreToMyLists(wellboreInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultsPanel);
