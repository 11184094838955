import React, { Component } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { filterConstants } from '../../types';

const FILTERID_MINIMUM_SCORE = 'minimumScoreFilter';
const FILTERNAME_MINIMUM_SCORE = 'MinimumScore';

export default class MinimumScore extends Component {
  constructor(props) {
    super(props);

    this.ID = FILTERID_MINIMUM_SCORE;
    this.name = FILTERNAME_MINIMUM_SCORE;
  }

  getID = () => this.ID

  getName = () => this.name

  handleChangeMinValue = (event) => {
    let newMinValue = parseFloat(event.target.value);
    if (isNaN(newMinValue)) {
      newMinValue = null;
    }

    this.props.onChangeMinValue(this.props.rankingType, typenewMinValue);
  }

  render() {
    const inputProps = {
      step: 5,
      min: 0,
      max: 100,
    };

    const {
      rankingType,
      minValue,
      onChangeMinValue,
      isMinimumScoreFilterValid,
      minimumScoreFilterErrorMessage
    } = this.props;

    return (
      <div>
        <div className="row justify-content-start mb-3">
          <div className="col-sm-3">
            <span className="input-group-text w-100 flex-row-reverse text-info">Minimum score</span>
          </div>

          <div className="col-sm-2">
            <TextField
              id="outlined-number"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              value={minValue(rankingType)}
              onChange={(event) => onChangeMinValue(rankingType, parseInt(event.target.value))}
              inputProps={inputProps}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              error={!isMinimumScoreFilterValid(rankingType)}
              helperText={minimumScoreFilterErrorMessage(rankingType)}
            />
          </div>
        </div>
      </div>
    );
  }
}
