import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../store';
import * as selectors from '../store/selectors';
import {
  sectionTypes, rankingMethods, rankingSectionGroupConstants, targetDivs, rankingConstants, rankingTypes, mainRankingMethods, defaultWeights,
} from '../types';
import {
  CompareRankingDesc, CompareRankingAsc, GoToDiv, ArrayToCSV,
} from '../utils/main';
import { fetchData, submitData } from '../services/api/api-helpers';
import '../components/filters/style.css';
import Toggle from '../components/common/Toggle';
import FiltersPanel from './FiltersPanel';
import WellpathRankingPanel, { IMPORT_WELLPATH_REF, SELECT_WELLBORE_REF } from './WellboreRankingPanel';
import SectionRankingPanel from './SectionRankingPanel';
import ResultsPanel from './ResultsPanel';
import {
  initAppInsights, appInsightsTrackEvent, appInsightsLogError, DIWA_RANK_QUERY,
} from '../services/appInsights/appInsights';
import { round } from '../utils/formatters';
import { getAuthServiceSingleton, getLoginErrorMessage } from '../services/adal/authService';
import { ensureAppInsightsInitialization } from '../services/appInsights/appInsightsSetupIntegration';

export const RESOURCE_NAME_MAIN = 'DIWA';
export const RESOURCE_NAME_GRAPH = 'Graph';

// Azure functions request literals
const GETINITIALINFORMATION_URL = '/api/GetInitialInformation';
export const GETWELLBOREINFORMATION_URL = (wellboreUID) => `/api/GetWellboreInformation?wellBoreID=${wellboreUID}`;
const RANKQUERY_URL = '/api/Rank';
export const GETWELLPATH_URL = (wellboreUID) => `/api/GetWellPath?wellBoreID=${wellboreUID}`;

const GETDEPARTMENT_URL = '/v1.0/me?$select=department';

class MainPage extends Component {
  constructor(props) {
    super(props);

    // Refs
    this.importWellPathElementForWellboreRanking = React.createRef();
    this.importWellPathElementForSectionRanking = React.createRef();
    this.selectWellboreElementForWellboreRanking = React.createRef();
    this.selectWellboreElementForSectionRanking = React.createRef();

    this.rankingElements = {
      [rankingMethods.GEOMETRIC]: React.createRef(),
      [rankingMethods.FORMATION]: React.createRef(),
      [rankingMethods.DRILLINGSECTION]: React.createRef(),
      [rankingMethods.COMPLETIONSECTION]: React.createRef(),
      [rankingMethods.HOLESECTION]: React.createRef(),
      [rankingMethods.KEYWORD]: React.createRef(),
    };
  }

  componentDidMount = () => {
    this.hideAlerts();

    const authService = getAuthServiceSingleton();
    authService.ensureUserLoggedIn()
      .then(() => ensureAppInsightsInitialization(this))
      .then(() => {
        const { match: { params }, type } = this.props;
        if (params.wellboreId) {
          this.props.setLinkedWellboreId(`${rankingConstants.PREFIX_LINKED_WELLBORE}${params.wellboreId}`);
        } else if (!this.dataIsLoaded()) this.setupFilters();
        this.props.setRankingType(type);
        this.props.validateSearchInputs();
        this.props.initWellboreLists();
      })
      .catch((e) => {
        throw new Error(getLoginErrorMessage(e));
      });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.linkedWellboreId !== this.props.linkedWellboreId) {
      this.processLinkedWellbore();
    }
  }

  // This methods sets up the ranking input query
  // TODO: move this to selectors
  // TODO: we should implement two separate functions for wellbore ranking and section ranking
  // because this is a combined version the store.search has to be set up so wellbore and section ranking part
  // contains all input types even if those are not applicable to wellbore or section ranking.
  getRankingInputForQuery = (type) => {
    const ranking = {
      RankingType: type,
    };

    // Normalize weights
    const weights = {};
    for (const i in mainRankingMethods) {
      if (this.props.isRankingOn(type, mainRankingMethods[i])) {
        weights[mainRankingMethods[i]] = this.props.getRankingWeight(type, mainRankingMethods[i]);
      }
    }

    // Create Input Query
    if (this.props.isRankingOn(type, rankingMethods.GEOMETRIC) && this.props.getRankingInput(type, rankingMethods.GEOMETRIC).length > 0) {
      ranking.Geometric = {
        Parameters: this.props.sourceWellPathFileName(type),
        Input: this.props.getRankingInput(type, rankingMethods.GEOMETRIC),
        Weight: weights[rankingMethods.GEOMETRIC],
      };
    }

    if (this.props.isRankingOn(type, rankingMethods.FORMATION)
      && this.props.getRankingInput(type, rankingMethods.FORMATION).length > 0) {
      ranking.Formation = {
        Input: this.props.getRankingInput(type, rankingMethods.FORMATION),
        Weight: weights[rankingMethods.FORMATION],
      };
    }

    if (this.props.isRankingOn(type, rankingMethods.DRILLINGSECTION) && this.props.getRankingInput(type, rankingMethods.DRILLINGSECTION).length > 0) {
      ranking.DrillingSection = {
        Input: this.props.getRankingInput(type, rankingMethods.DRILLINGSECTION),
        Weight: weights[rankingMethods.DRILLINGSECTION],
      };
    }

    if (this.props.isRankingOn(type, rankingMethods.COMPLETIONSECTION) && this.props.getRankingInput(type, rankingMethods.COMPLETIONSECTION).length > 0) {
      ranking.CompletionSection = {
        Input: this.props.getRankingInput(type, rankingMethods.COMPLETIONSECTION),
        Weight: weights[rankingMethods.COMPLETIONSECTION],
      };
    }

    if (this.props.isRankingOn(type, rankingMethods.HOLESECTION) && this.props.getRankingInput(type, rankingMethods.HOLESECTION).length > 0) {
      ranking.HoleSection = {
        Input: this.props.getRankingInput(type, rankingMethods.HOLESECTION),
        Weight: weights[rankingMethods.HOLESECTION],
      };
    }

    if (this.props.isRankingOn(type, rankingMethods.KEYWORD) && this.props.getRankingInput(type, rankingMethods.KEYWORD).length > 0) {
      ranking.Keyword = {
        Input: this.props.getRankingInput(type, rankingMethods.KEYWORD).map((x) => ((x.keyword) ? x.keyword : x)),
        Weight: weights[rankingMethods.KEYWORD],
      };
    }

    if (this.props.isRankingOn(type, rankingMethods.OTHER_SECTION) && this.props.getRankingInput(type, rankingMethods.OTHER_SECTION).length > 0) {
      ranking.RankingSectionGroups = this.props.getRankingInput(type, rankingMethods.OTHER_SECTION);
    }

    if (type = rankingTypes.SECTION_RANKING) {
      ranking.Tubing = {
        Input: this.props.getRankingInput(type, rankingMethods.TUBING),
        Weight: weights[rankingMethods.TUBING],
      };
    }

    if (type = rankingTypes.SECTION_RANKING) {
      ranking.CasingExitSize = {
        Input: this.props.getRankingInput(type, rankingMethods.CASING_EXIT_SIZE),
        Weight: weights[rankingMethods.CASING_EXIT_SIZE],
      };
    }

    return ranking;
  }

  // Checks if any data is loaded, TODO: there should be a different condition for this to be true
  dataIsLoaded = () => {
    const {
      availableWellBores, availableFields, availableFormations, availableSections, availableKeywords, availableRigs,
    } = this.props;
    return availableWellBores.length > 0
      || availableFields.length > 0
      || availableFormations.length > 0
      || availableSections.length > 0
      || availableKeywords.length > 0
      || availableRigs.length > 0;
  }

  processLinkedWellbore = () => {
    if (this.props.rankingType == rankingTypes.WELLBORE_RANKING) this.selectWellboreElementForWellboreRanking.current.toggleCheckbox(true);
    else if (this.props.rankingType == rankingTypes.SECTION_RANKING) this.selectWellboreElementForSectionRanking.current.toggleCheckbox(true);
  }

  navigateTo(route) {
    this.props.history.push(route);
  }

  handleSelectWellboreSections = (wellboreUID, overwrite) => {
    const { setSelectedWellboreName } = this.props;

    this.hideAlerts();
    this.loading(true);
    setSelectedWellboreName(rankingTypes.SECTION_RANKING, wellboreUID);

    fetchData(GETWELLBOREINFORMATION_URL(wellboreUID), RESOURCE_NAME_MAIN).then((e) => {
      if (e.body) {
        const result = JSON.parse(JSON.stringify(e.body));
        if (result.holeSections) {
          result.holeSections.forEach((x) => {
            x.startDepth = round(x.startDepth, 1);
            x.endDepth = round(x.endDepth, 1);
          });
        }

        let informationFound = false;
        this.props.setLinkedWellboreId('');

        const compareEntryMd = (a, b) => ((a.entryMd > b.entryMd) ? 1 : -1);

        const compareStartDepth = (a, b) => ((a.startDepth > b.startDepth) ? 1 : -1);

        if (result.rankingSectionGroups && result.rankingSectionGroups.length > 0) {
          this.selectAndUpdateRankingInput(
            rankingTypes.SECTION_RANKING,
            rankingMethods.OTHER_SECTION,
            result.rankingSectionGroups,
            overwrite,
            true,
          );
        }

        if (result.wellpath && result.wellpath.length > 0) {
          informationFound = true;
          const additionalInformation = result.wellpathAdditionalInformation;
          this.selectAndUpdateRankingInput(
            rankingTypes.SECTION_RANKING,
            rankingMethods.GEOMETRIC,
            result.wellpath,
            true,
            true,
            wellboreUID,
            ArrayToCSV(additionalInformation),
          );
        } else {
          this.selectAndUpdateRankingInput(
            rankingTypes.SECTION_RANKING,
            rankingMethods.GEOMETRIC,
            result.wellpath,
            overwrite,
            false,
            wellboreUID,
          );
        }

        const holeSectionsFound = result.holeSections && result.holeSections.length > 0;
        informationFound = informationFound || holeSectionsFound;
        this.selectAndUpdateRankingInput(
          rankingTypes.SECTION_RANKING,
          rankingMethods.HOLESECTION,
          result.holeSections.sort(compareStartDepth),
          overwrite,
          holeSectionsFound,
        );

        const formationsFound = result.formations && result.formations.length > 0;
        informationFound = informationFound || formationsFound;
        this.selectAndUpdateRankingInput(
          rankingTypes.SECTION_RANKING,
          rankingMethods.FORMATION,
          result.formations.sort(compareEntryMd),
          overwrite,
          formationsFound,
        );

        const keywordsFound = result.keywords && result.keywords.length > 0;
        informationFound = informationFound || keywordsFound;
        this.selectAndUpdateRankingInput(
          rankingTypes.SECTION_RANKING,
          rankingMethods.KEYWORD,
          result.keywords.sort(),
          overwrite,
          keywordsFound,
        );

        const tubingAverageSizeFound = result.tubing.averageSize != null;
        const tubingMainSizeFound = result.tubing.mainSize != null;
        const tubingSecondarySizeFound = result.tubing.secondarySize != null;

        const casingExitSize = result.casingExitSize != null;

        const tubingFound = tubingAverageSizeFound || tubingMainSizeFound || tubingSecondarySizeFound;
        informationFound = informationFound || tubingFound;

        const tubingPrecision = 2;

        if (tubingAverageSizeFound) {
          this.props.setTubingRankingInputAverageSize(round(result.tubing.averageSize, tubingPrecision));
        }

        if (tubingMainSizeFound) {
          this.props.setTubingRankingInputMainSize(round(result.tubing.mainSize, tubingPrecision));
        }

        if (tubingSecondarySizeFound) {
          this.props.setTubingRankingInputSecondarySize(round(result.tubing.secondarySize, tubingPrecision));
        }

        if (casingExitSize) {
          this.props.setCasingExitSize(result.casingExitSize.casingExitSize);
        }

        if (!informationFound) {
          this.showInfo(`No information could be loaded for wellbore [${wellboreUID}]!`);
        }
      }
      this.loading(false);
    }, (r) => {
      this.showError(
        GETWELLBOREINFORMATION_URL(wellboreUID),
        `Could not load wellbore information for [${wellboreUID}]! ${r ? r.message : 'Internal error!'}`, r,
      );
      this.loading(false);
    });
  }

  // TODO : this should be available for Section Ranking too at some point
  // TODO: move this to sagas and reducers
  handleSelectWellbore = (wellboreUID, overwrite, loadingLinkedWellbore) => {
    const { setSelectedWellboreName, setLoadingResults, setLinkedWellboreId } = this.props;

    this.hideAlerts();
    this.loading(true);
    setSelectedWellboreName(rankingTypes.WELLBORE_RANKING, wellboreUID);

    fetchData(GETWELLBOREINFORMATION_URL(wellboreUID), RESOURCE_NAME_MAIN).then((e) => {
      if (e.body) {
        const result = JSON.parse(JSON.stringify(e.body));
        let informationFound = false;
        setLinkedWellboreId('');

        if (result.wellpath && result.wellpath.length > 0) {
          informationFound = true;
          const additionalInformation = result.wellpathAdditionalInformation;
          this.selectAndUpdateRankingInput(
            rankingTypes.WELLBORE_RANKING,
            rankingMethods.GEOMETRIC,
            result.wellpath,
            true,
            true,
            wellboreUID,
            ArrayToCSV(additionalInformation),
          );
        } else {
          this.selectAndUpdateRankingInput(
            rankingTypes.WELLBORE_RANKING,
            rankingMethods.GEOMETRIC,
            result.wellpath,
            overwrite,
            false,
            wellboreUID,
          );
        }

        const formationsFound = result.formations && result.formations.length > 0;
        informationFound = informationFound || formationsFound;
        this.selectAndUpdateRankingInput(
          rankingTypes.WELLBORE_RANKING,
          rankingMethods.FORMATION,
          result.formations,
          overwrite,
          formationsFound,
        );

        const drillingSectionsFound = result.drillingSections && result.drillingSections.length > 0;
        informationFound = informationFound || drillingSectionsFound;
        this.selectAndUpdateRankingInput(
          rankingTypes.WELLBORE_RANKING,
          rankingMethods.DRILLINGSECTION,
          result.drillingSections,
          overwrite,
          drillingSectionsFound,
        );

        const completionSectionsFound = result.completionSections && result.completionSections.length > 0;
        informationFound = informationFound || completionSectionsFound;
        this.selectAndUpdateRankingInput(
          rankingTypes.WELLBORE_RANKING,
          rankingMethods.COMPLETIONSECTION,
          result.completionSections,
          overwrite,
          completionSectionsFound,
        );

        const keywordsFound = result.keywords && result.keywords.length > 0;
        informationFound = informationFound || keywordsFound;
        this.selectAndUpdateRankingInput(
          rankingTypes.WELLBORE_RANKING,
          rankingMethods.KEYWORD,
          result.keywords.map((x) => x.keyword),
          overwrite,
          keywordsFound,
        );

        if (!informationFound) {
          this.showInfo(`No information could be loaded for wellbore [${wellboreUID}]!`);
        }
      }
      this.loading(false);

      if (loadingLinkedWellbore) {
        setLoadingResults(true);
        this.handleStartSearch(rankingTypes.WELLBORE_RANKING, loadingLinkedWellbore);
      }
    }, (r) => {
      this.showError(
        GETWELLBOREINFORMATION_URL(wellboreUID),
        `Could not load wellbore information for [${wellboreUID}]! ${r ? r.message : 'Internal error!'}`, r,
      );
      this.loading(false);
    });
  }

  selectAndUpdateRankingInput = (
    type,
    method,
    values,
    overwrite,
    select,
    wellboreUID,
    additionalInformation,
  ) => {
    if (overwrite) {
      this.props.setRankingInput(type, method, []);
    }

    if (this.rankingElements[method] && this.rankingElements[method].current) {
      if (select || overwrite) {
        this.rankingElements[method].current.toggleCheckbox(select);
      }
    }

    if (select) {
      if (method === rankingMethods.GEOMETRIC) {
        if (type === rankingTypes.WELLBORE_RANKING && this.importWellPathElementForWellboreRanking && this.importWellPathElementForWellboreRanking.current) {
          this.importWellPathElementForWellboreRanking.current.loadPlot(wellboreUID || 'Name_not_loaded', values, additionalInformation);
        } else if (type === rankingTypes.SECTION_RANKING && this.importWellPathElementForSectionRanking && this.importWellPathElementForSectionRanking.current) {
          this.importWellPathElementForSectionRanking.current.loadPlot(wellboreUID || 'Name_not_loaded', values, additionalInformation);
        }
      } else {
        values.forEach((value) => {
          this.props.addRankingInput(type, method, value);
        });
      }
    }
  }

  loading = (status) => {
    if (!this.props.isLoadingResults) this.props.setLoading(status);
  }

  initResults = (type) => {
    this.props.clearResults(type);
    this.props.clearSelectedWellPathsForWellLine();
  }

  showInfo = (message) => {
    this.props.setInfo(message);

    GoToDiv(targetDivs.INFO);
  }

  showError = (source, message, error) => {
    this.props.setError({
      source,
      message,
    });

    if (error) {
      appInsightsLogError(error);
    }

    GoToDiv(targetDivs.ERROR);
  }

  hideAlerts = () => {
    this.props.setInfo('');
    this.props.setError(null);
  }

  sortResults = (type, reverse) => {
    if (this.props.getResults(type)) {
      const sortedResults = JSON.parse(JSON.stringify(this.props.getResults(type)));
      sortedResults.sort(reverse ? CompareRankingDesc : CompareRankingAsc);
      this.props.setResults(type, sortedResults);
    }
  }

  handleWellPathLoaded = (type, sourceFileName, importedData) => {
    this.props.setSourceWellPathFileName(type, sourceFileName);
    this.props.setRankingInput(type, rankingMethods.GEOMETRIC, importedData);
    this.props.setSourceWellPathUID('');

    if (!importedData || importedData.length <= 0) {
      this.showError('WellPath', 'Could not import WellPath coordinates! Please check source.', null);
    } else {
      this.hideAlerts();
      // this.selectWellPathElement.current.reset();
    }
  }

  handleWellPathReset = (type) => {
    this.props.setSourceWellPathFileName(type, '');
    this.props.setRankingInput(type, rankingMethods.GEOMETRIC, []);
  }

  handleDepthChanged = (min, max) => {
    if (min) {
      this.props.setMinDepth(min);
    } else if (max) {
      this.props.setMaxDepth(max);
    }
  }

  handleDateIntervalChanged = (min, max) => {
    if (min) {
      this.props.setMinDate(min);
    } else if (max) {
      this.props.setMaxDate(max);
    }
  }

  handleStartSearch = (type, loadingLinkedWellbore) => {
    this.hideAlerts();

    if (loadingLinkedWellbore) {
      this.handleRankQuery(type, true);
      return;
    }

    if (type === rankingTypes.SECTION_RANKING) {
      mainRankingMethods.forEach((method) => {
        if (this.props.getRankingInput(type, method).length > 0) {
          this.props.setRankingOn(type, method, true);
        }
      });
    }

    this.handleRankQuery(type);
  }

  setupFilters = () => this.loadDepartmentInfo().then((department) => this.loadInitialInformation(department, []))

  loadDepartmentInfo = () => fetchData(GETDEPARTMENT_URL, RESOURCE_NAME_GRAPH).then((e) => {
    if (e && e.body) {
      const { department } = e.body;
      return department;
    }
    const message = 'Could not load department information for app to start properly.';
    // app insights is not initialized at this stage, so no need to send error
    this.showError('', message, null);
  }, (r) => {
    this.showError(GETDEPARTMENT_URL,
      `Could not load department information to start app! ${r ? r.message : ''}`, null);
    this.props.setLoadingFilters(false);
  })

  // This call retreives all information needed to start client app
  // TODO: move this to sagas
  loadInitialInformation = (department, furtherActions) => {
    this.props.setLoadingFilters(true);
    return fetchData(GETINITIALINFORMATION_URL, RESOURCE_NAME_MAIN).then((e) => {
      if (e && e.body) {
        const response = JSON.parse(JSON.stringify(e.body));
        this.setupInitialInformation(response);
        initAppInsights(response.appInsigthsInstrumentationKey, department);
      } else {
        const message = 'Could not load initial information for app to start properly.';
        this.showError(GETINITIALINFORMATION_URL, message, "There was an empty response from endpoint.");
      }

      if (furtherActions && furtherActions.length > 0) {
        furtherActions[0](furtherActions.slice(1));
      } else {
        this.props.setLoadingFilters(false);
      }
    }, (r) => {
      // app insights is not initialized at this stage, so no need to send error
      this.showError(GETINITIALINFORMATION_URL,
        `Could not load initial information to start app! ${r ? r.message : ''}`, r);
      this.props.setLoadingFilters(false);
    });
  }

  // This populates dropdown lists and filter info
  // TODO: move this to reducers
  setupInitialInformation = (info) => {
    if (info.wellboreNames) {
      this.props.setAvailableWellBores(info.wellboreNames);
    }

    if (info.fieldNames) {
      this.props.setAvailableFields(info.fieldNames);
    }

    if (info.formations) {
      this.props.setAvailableFormations(info.formations);
    }

    if (info.drillingSectionNames) {
      this.props.setAvailableSections[sectionTypes.DRILLING](info.drillingSectionNames);
    }

    if (info.completionSectionNames) {
      this.props.setAvailableSections[sectionTypes.COMPLETION](info.completionSectionNames);
    }

    if (info.holeSections) {
      this.props.setAvailableSections[sectionTypes.HOLE](info.holeSections);
    }

    if (info.keywords) {
      this.props.setAvailableKeywords(info.keywords);
    }

    if (info.rigNames) {
      this.props.setAvailableRigs(info.rigNames
        .filter((r) => r.toUpperCase() !== 'UNKNOWN').map((a) => _.startCase(_.toLower(a))));
    }

    if (info.sectionGroups) {
      this.props.setAvailableSectionGroups(info.sectionGroups);
    }

    if (info.rankingSectionGroups) {
      this.props.setRankingSectionGroups(info.rankingSectionGroups);
    }

    if (info.serviceNowFeedbackUrl) {
      this.props.setFeedbackUrl(info.serviceNowFeedbackUrl);
    }
  }

  // Check if multiple ranking methods are selected (currently to allow only one)
  isMultipleRankingMethodsSelected = (type) => {
    let sum = 0;
    mainRankingMethods.forEach((m) => {
      if (this.props.isRankingOn(type, m)) {
        sum += 1;
      }
    });

    return (sum > 1);
  }

  // TODO: move this to reducers
  extendToViewModel = (rankingInput, wp) => {
    wp.lastSection = wp.lastSection === null ? null : new Date(wp.lastSection);

    wp.formationViewModels = wp.formations ? wp.formations.map((x) => (
      {
        formation: x,
        isSearchHit: rankingInput.Formation ? rankingInput.Formation.Input.filter((f) => f.formationName === x.formationName).length > 0 : false,
      }))
      : [];

    wp.keywordViewModels = wp.keywords
      ? wp.keywords.map((x) => ({
        keyword: x,
        isSearchHit: rankingInput.Keyword ? rankingInput.Keyword.Input.includes(x.keyword) : false,
      })) : [];

    if (wp.completionSections) {
      wp.completionSections.forEach((x) => {
        x.isSearchHit = rankingInput.CompletionSection
          ? rankingInput.CompletionSection.Input.includes(x.sectionName) : false;
      });
    }

    if (wp.drillingSections) {
      wp.drillingSections.forEach((x) => {
        x.isSearchHit = rankingInput.DrillingSection
          ? rankingInput.DrillingSection.Input.includes(x.sectionName) : false;
      });
    }

    if (wp.inheritedSections) {
      wp.inheritedSections.forEach((x) => {
        x.isSearchHit = rankingInput.DrillingSection
          ? rankingInput.DrillingSection.Input.includes(x.sectionName) : false;
      });
    }

    if (wp.rigs) wp.rigs = wp.rigs.map((a) => _.startCase(_.toLower(a)));
  }

  // This call gets filtered (ranked) results for an indexed WellPath by
  // UID or for an imported CSV file for WellPath Coordinates
  // TODO: move this call to sagas
  handleRankQuery = (type, loadingLinkedWellbore) => {
    if (!loadingLinkedWellbore) this.loading(true);
    this.initResults(type);

    const createQueryObject = () => ({
      MaxResults: this.props.maxResults(type),
      RankingInput: this.getRankingInputForQuery(type),
      Filters: [], // TODO : remove this, not used
    });

    const queryForAppInsights = createQueryObject();
    // we do not pass the well path coordinates to app insigths
    if (queryForAppInsights.RankingInput.Geometric && queryForAppInsights.RankingInput.Geometric.Input) queryForAppInsights.RankingInput.Geometric.Input = '[wellpath attached]';
    const trackEventParam = { code: DIWA_RANK_QUERY, params: queryForAppInsights };
    if (!loadingLinkedWellbore) {
      appInsightsTrackEvent(trackEventParam);
    }
    const query = createQueryObject();
    const URL = RANKQUERY_URL;
    submitData(URL, query, 'POST', RESOURCE_NAME_MAIN).then((e) => {
      const initialResults = (this.props.getResults(type).length === 0);
      const receivedWPUIDs = [];
      const newResults = [];
      let resultNumber = 0;
      e.result.forEach((wp) => {
        if (receivedWPUIDs.indexOf(wp.uniqueResultIdentifier) === -1) {
          wp.resultNumber = ++resultNumber;
          newResults.push(JSON.parse(JSON.stringify(wp)));
          receivedWPUIDs.push(wp.uniqueResultIdentifier);
        }
      });

      if (newResults.length > 0) {
        newResults.forEach((wp) => this.extendToViewModel(query.RankingInput, wp));
        this.props.addResults(type, newResults);
        if (initialResults) this.goToResults();
      } else {
        this.showInfo('No matching results found. Try modifying your filters or input parameters.');
      }

      if (loadingLinkedWellbore) {
        this.setupFilters().then(() => appInsightsTrackEvent(trackEventParam));
      }
      this.loading(false);
      this.props.setLoadingResults(false);
    }, (r) => {
      if (loadingLinkedWellbore) {
        this.setupFilters().then(() => appInsightsTrackEvent(trackEventParam));
      }
      this.loading(false);
      this.props.setLoadingResults(false);
      this.showError(RANKQUERY_URL, `Could not execute query! ${r}` ? r.message : '', r);
    });
  }

  goToResults = () => {
    GoToDiv(targetDivs.RESULTS);
  }

  areRankingParametersApplied = (type) => {
    const { isRankingOn, getRankingInput } = this.props;
    for (const i in mainRankingMethods) {
      const method = mainRankingMethods[i];

      if (isRankingOn(type, method)
        && getRankingInput(type, method) !== null
        && getRankingInput(type, method).length > 0) {
        return true;
      }
    }

    return false;
  }

  clearRankingInput = (type) => {
    const p = this.props;
    p.clearRankingInput(type);
  }

  handleToggle = (value) => {
    this.loading(true);
    this.hideAlerts();

    setTimeout(() => {
      this.props.setRankingType(value);
      this.loading(false);
    }, 1000);
  }

  render() {
    return (
      <div>
        <div style={{
          position: 'fixed', zIndex: '10', display: 'block', right: '0', paddingTop: '50px',
        }}
        >
          <Toggle
            options={[
              { name: 'Wellbore Ranking', value: rankingTypes.WELLBORE_RANKING },
              { name: 'Section Ranking (demo)', value: rankingTypes.SECTION_RANKING },
            ]}
            compareTo={this.props.rankingType}
            onToggle={this.handleToggle}
          />
        </div>

        <div style={{ paddingTop: '100px' }}>
          <div className="row animate-bottom" style={this.props.loading ? { pointerEvents: 'none', opacity: '0.2' } : {}}>
            <div className="col-sm-12">

              {/* Section Ranking */}
              {this.props.rankingType === rankingTypes.SECTION_RANKING
                && (
                <SectionRankingPanel
                  refs={{
                    [IMPORT_WELLPATH_REF]: this.importWellPathElementForSectionRanking,
                    [SELECT_WELLBORE_REF]: this.selectWellboreElementForSectionRanking,
                  }}
                  onWellPathLoaded={this.handleWellPathLoaded}
                  onWellPathReset={this.handleWellPathReset}
                  loading={this.loading}
                  areRankingParametersApplied={this.areRankingParametersApplied}
                  clearRankingInput={this.clearRankingInput}
                  handleStartSearch={this.handleStartSearch}
                  handleSelectWellbore={this.handleSelectWellboreSections}
                />
                )}

              {/* Wellpath Ranking */}
              {this.props.rankingType === rankingTypes.WELLBORE_RANKING
                && (
                <WellpathRankingPanel
                  refs={{
                    [IMPORT_WELLPATH_REF]: this.importWellPathElementForWellboreRanking,
                    [SELECT_WELLBORE_REF]: this.selectWellboreElementForWellboreRanking,
                    [rankingMethods.GEOMETRIC]: this.rankingElements[rankingMethods.GEOMETRIC],
                    [rankingMethods.DRILLINGSECTION]: this.rankingElements[rankingMethods.DRILLINGSECTION],
                    [rankingMethods.COMPLETIONSECTION]: this.rankingElements[rankingMethods.COMPLETIONSECTION],
                    [rankingMethods.FORMATION]: this.rankingElements[rankingMethods.FORMATION],
                    [rankingMethods.KEYWORD]: this.rankingElements[rankingMethods.KEYWORD],
                  }}
                  isMultipleRankingMethodsSelected={this.isMultipleRankingMethodsSelected}
                  onWellPathLoaded={this.handleWellPathLoaded}
                  onWellPathReset={this.handleWellPathReset}
                  loading={this.loading}
                  handleSelectWellbore={this.handleSelectWellbore}
                  areRankingParametersApplied={this.areRankingParametersApplied}
                  clearRankingInput={this.clearRankingInput}
                  handleStartSearch={this.handleStartSearch}
                />
                )}

              {/* Messages */}
              {this.props.info !== '' && (
              <div id={targetDivs.INFO} className="alert alert-info custom-margin" role="alert">
                <b>{this.props.info}</b>
              </div>
              )}
              {this.props.error && (
              <div id={targetDivs.ERROR} className="alert alert-danger custom-margin" role="alert">
                <b>ERROR</b>
                {' '}
                {this.props.error.source !== '' ? `[${this.props.error.source}] :` : ''}
                {' '}
                [
                {' '}
                <b>{this.props.error.message}</b>
                {' '}
                ]
              </div>
              )}

              {/* Filtering */}
              {this.props.rankingType === rankingTypes.WELLBORE_RANKING && this.props.getResults(rankingTypes.WELLBORE_RANKING).length > 0 ? (
                <FiltersPanel />
              ) : null}
            </div>
          </div>

          {/* Results */}
          {this.props.getResults(this.props.rankingType).length > 0 ? (
            <div style={this.props.loading ? { pointerEvents: 'none', opacity: '0.2' } : {}}>
              <ResultsPanel />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // General
  linkedWellboreId: selectors.getLinkedWellboreId(state),
  rankingType: selectors.getRankingType(state),

  // Search
  // --- Ranking Methods
  isRankingOn: (type, method) => selectors.isRankingOn(state, type, method),
  getRankingWeight: (type, method) => selectors.getRankingWeight(state, type, method),
  getRankingInput: (type, method) => selectors.getRankingInput(state, type, method),
  // --- Source WellPath
  sourceWellPathUID: selectors.getSourceWellPathUID(state),
  sourceWellPathFileName: (type) => selectors.getSourceWellPathFileName(state, type),
  availableWellBores: selectors.getAvailableWellBores(state),
  // --- Process
  loading: selectors.isLoading(state),
  loadingFilters: selectors.isLoadingFilters(state),
  loadingResults: selectors.isLoadingResults(state),
  queryId: selectors.getQueryId(state),
  filteredListSize: selectors.getFilteredListSize(state),
  selectedWellPathsForWellLine: selectors.getSelectedWellPathsForWellLine(state),
  info: selectors.getInfo(state),
  error: selectors.getError(state),
  maxDisplayed: selectors.getMaxDisplayed(state),

  // --- Results
  getResults: (type) => selectors.getResults(state, type),

  // Filters
  // --- Depth
  minDepth: selectors.getMinDepth(state),
  maxDepth: selectors.getMaxDepth(state),
  // --- Field
  availableFields: selectors.getAvailableFields(state),
  selectedFields: selectors.getSelectedFields(state),
  // --- Minimum score
  minimumScore: (type) => selectors.getMinimumScore(state, type),
  // --- Formation
  availableFormations: selectors.getAvailableFormations(state),
  selectedFormations: selectors.getSelectedFormations(state),
  // --- Section
  availableSections: {
    [sectionTypes.DRILLING]: selectors.getAvailableSections(state, sectionTypes.DRILLING),
    [sectionTypes.COMPLETION]: selectors.getAvailableSections(state, sectionTypes.COMPLETION),
    [sectionTypes.HOLE]: selectors.getAvailableSections(state, sectionTypes.HOLE),
  },
  selectedSections: {
    [sectionTypes.DRILLING]: selectors.getSelectedSections(state, sectionTypes.DRILLING),
    [sectionTypes.COMPLETION]: selectors.getSelectedSections(state, sectionTypes.COMPLETION),
    [sectionTypes.HOLE]: selectors.getSelectedSections(state, sectionTypes.HOLE),
  },
  // --- Rig
  availableRigs: selectors.getAvailableRigs(state),
  selectedRigs: selectors.getSelectedRigs(state),
  // --- Keyword
  availableKeywords: selectors.getAvailableKeywords(state),
  selectedKeywords: selectors.getSelectedKeywords(state),
  // --- Number of results
  maxResults: (type) => selectors.getMaxResults(state, type),
});

const mapDispatchToProps = (dispatch) => ({
  // General
  setLinkedWellboreId: (wellboreId) => dispatch(actions.setLinkedWellboreId(wellboreId)),
  setRankingType: (type) => dispatch(actions.setRankingType(type)),

  // MyList
  initWellboreLists: () => dispatch(actions.initWellboreLists()),

  // Filters
  // --- Depth
  setMinDepth: (depth) => dispatch(actions.setMinDepth(depth)),
  setMaxDepth: (depth) => dispatch(actions.setMaxDepth(depth)),
  // --- Minimum score
  setMinimumScore: (type, score) => dispatch(actions.setMinimumScore({ type: type, value: score})),
  // --- Date interval
  setMinDate: (depth) => dispatch(actions.setMinDate(depth)),
  setMaxDate: (depth) => dispatch(actions.setMaxDate(depth)),
  // --- Field
  setAvailableFields: (fields) => dispatch(actions.setAvailableFields(fields)),
  // --- Formation
  setAvailableFormations: (formations) => dispatch(actions.setAvailableFormations(formations)),
  // --- Section groups
  setAvailableSectionGroups: (sg) => dispatch(actions.setAvailableSectionGroups(sg)),
  setRankingSectionGroups: (sg) => dispatch(actions.setRankingSectionGroups(sg)),
  // ---
  setAvailableSections: {
    [sectionTypes.DRILLING]: (sections) => dispatch(actions.setAvailableSections({ type: sectionTypes.DRILLING, value: sections })),
    [sectionTypes.COMPLETION]: (sections) => dispatch(actions.setAvailableSections({ type: sectionTypes.COMPLETION, value: sections })),
    [sectionTypes.HOLE]: (sections) => dispatch(actions.setAvailableSections({ type: sectionTypes.HOLE, value: sections })),
  },
  // --- Rig
  setAvailableRigs: (rigs) => dispatch(actions.setAvailableRigs(rigs)),
  // --- Keyword
  setAvailableKeywords: (keywords) => dispatch(actions.setAvailableKeywords(keywords)),

  // Search
  // --- Ranking Methods
  validateSearchInputs: () => dispatch(actions.validateSearchInputs()),
  setRankingOn: (type, method, status) => dispatch(actions.setRankingOn({ type, method, value: status })),
  setRankingWeight: (type, method, value) => dispatch(actions.setRankingWeight({ type, method, value })),
  setRankingInput: (type, method, value) => dispatch(actions.setRankingInput({ type, method, value })),
  addRankingInput: (type, method, value) => dispatch(actions.addRankingInput({ type, method, value })),
  clearRankingInput: (type) => dispatch(actions.clearRankingInput({ type })),
  setSelectedWellboreName: (type, selectedWellboreName) => dispatch(actions.setSelectedWellboreName({ type, selectedWellboreName })),
  setTubingRankingInputAverageSize: (value) => dispatch(actions.setTubingRankingInputAverageSize({ value })),
  setTubingRankingInputMainSize: (value) => dispatch(actions.setTubingRankingInputMainSize({ value })),
  setTubingRankingInputSecondarySize: (value) => dispatch(actions.setTubingRankingInputSecondarySize({ value })),
  setCasingExitSize: (value) => dispatch(actions.setCasingExitSize({ value })),

  // --- Source WellPath
  setSourceWellPathUID: (wpUID) => dispatch(actions.setSourceWellPathUID(wpUID)),
  setSourceWellPathFileName: (type, value) => dispatch(actions.setSourceWellPathFileName({ type, value })),
  setAvailableWellBores: (wps) => dispatch(actions.setAvailableWellBores(wps)),

  // --- Process
  setLoading: (status) => dispatch(actions.setLoading(status)),
  setLoadingFilters: (status) => dispatch(actions.setLoadingFilters(status)),
  setLoadingResults: (status) => dispatch(actions.setLoadingResults(status)),
  clearSelectedWellPathsForWellLine: (wp) => dispatch(actions.clearSelectedWellPathsForWellLine(wp)),
  setInfo: (info) => dispatch(actions.setInfo(info)),
  setError: (error) => dispatch(actions.setError(error)),
  setFilteredListSize: (size) => dispatch(actions.setFilteredListSize(size)),

  // --- Results
  setResults: (type, newResults) => dispatch(actions.setResults({ type, value: newResults })),
  clearResults: (type) => dispatch(actions.clearResults({ type })),
  addResults: (type, newResults) => dispatch(actions.addResults({ type, value: newResults })),

  // --- Feedback
  setFeedbackUrl: (url) => dispatch(actions.setServiceNowFeedbackUrl({ url })),
});

MainPage.propTypes = {
  linkedWellboreId: PropTypes.string.isRequired,
  rankingType: PropTypes.string.isRequired,

  isRankingOn: PropTypes.func.isRequired,
  getRankingWeight: PropTypes.func.isRequired,
  getRankingInput: PropTypes.func.isRequired,
  setSelectedWellboreName: PropTypes.func.isRequired,
  clearRankingInput: PropTypes.func.isRequired,
  setTubingRankingInputAverageSize: PropTypes.func.isRequired,
  setTubingRankingInputMainSize: PropTypes.func.isRequired,
  setTubingRankingInputSecondarySize: PropTypes.func.isRequired,
  setCasingExitSize: PropTypes.func.isRequired,

  sourceWellPathUID: PropTypes.string.isRequired,
  sourceWellPathFileName: PropTypes.func.isRequired,
  availableWellBores: PropTypes.array.isRequired,

  loading: PropTypes.bool.isRequired,
  loadingFilters: PropTypes.bool.isRequired,
  loadingResults: PropTypes.bool.isRequired,
  queryId: PropTypes.string.isRequired,
  filteredListSize: PropTypes.number.isRequired,
  selectedWellPathsForWellLine: PropTypes.array.isRequired,
  info: PropTypes.string.isRequired,
  error: PropTypes.object,
  maxDisplayed: PropTypes.number.isRequired,

  getResults: PropTypes.func.isRequired,

  minDepth: PropTypes.number.isRequired,
  maxDepth: PropTypes.number.isRequired,
  availableFields: PropTypes.array.isRequired,
  selectedFields: PropTypes.array.isRequired,
  availableFormations: PropTypes.array.isRequired,
  selectedFormations: PropTypes.array.isRequired,

  // function objects
  availableSections: PropTypes.object.isRequired,
  selectedSections: PropTypes.object.isRequired,

  availableRigs: PropTypes.array.isRequired,
  selectedRigs: PropTypes.array.isRequired,

  availableKeywords: PropTypes.array.isRequired,
  selectedKeywords: PropTypes.array.isRequired,
  maxResults: PropTypes.func.isRequired,

  setLinkedWellboreId: PropTypes.func.isRequired,
  setRankingType: PropTypes.func.isRequired,

  setMinDepth: PropTypes.func.isRequired,
  setMaxDepth: PropTypes.func.isRequired,
  setMinimumScore: PropTypes.func.isRequired,
  setMinDate: PropTypes.func.isRequired,
  setMaxDate: PropTypes.func.isRequired,
  setAvailableFields: PropTypes.func.isRequired,
  setAvailableFormations: PropTypes.func.isRequired,

  setAvailableSections: PropTypes.object.isRequired,

  setAvailableRigs: PropTypes.func.isRequired,
  setAvailableKeywords: PropTypes.func.isRequired,

  validateSearchInputs: PropTypes.func.isRequired,
  setRankingOn: PropTypes.func.isRequired,
  setRankingWeight: PropTypes.func.isRequired,
  setRankingInput: PropTypes.func.isRequired,
  addRankingInput: PropTypes.func.isRequired,

  setSourceWellPathUID: PropTypes.func.isRequired,
  setSourceWellPathFileName: PropTypes.func.isRequired,
  setAvailableWellBores: PropTypes.func.isRequired,

  setLoading: PropTypes.func.isRequired,
  setLoadingFilters: PropTypes.func.isRequired,
  setLoadingResults: PropTypes.func.isRequired,
  clearSelectedWellPathsForWellLine: PropTypes.func.isRequired,
  setInfo: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,

  setResults: PropTypes.func.isRequired,
  clearResults: PropTypes.func.isRequired,
  addResults: PropTypes.func.isRequired,

  setFeedbackUrl: PropTypes.func.isRequired,
  setAvailableSectionGroups: PropTypes.func.isRequired,

};

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
