// import { schema, normalize } from 'normalizr';
import { config, fetchData, submitData } from './api-helpers';

config.defaultResource = 'mad';

export function getReleaseNote(version) {
  return fetchData(`/ReleaseNote/TEMPLATE/${version}`, 'common');
}

export function getMyLists() {
  return fetchData('/api/myLists', 'DIWA');
}

export function createWellboreList() {
  return submitData('/api/myLists', null, 'POST', 'DIWA');
}

export function removeWellboreList(wellboreListId) {
  return submitData(`/api/myLists/${wellboreListId}`, null, 'DELETE', 'DIWA');
}

export function renameWellboreList(wellboreListId, newName) {
  return submitData(`/api/myLists/${wellboreListId}/name`, { name: newName }, 'PUT', 'DIWA');
}

export function addWellboreToMyLists(wellboreListId, uniqueWellboreIdentifier) {
  return submitData(`/api/myLists/${wellboreListId}/wellbores`, { uniqueWellboreIdentifier }, 'POST', 'DIWA');
}

export function removeWellbore(wellboreListId, uniqueWellboreIdentifier) {
  return submitData(`/api/myLists/${wellboreListId}/wellbores`, { uniqueWellboreIdentifier }, 'DELETE', 'DIWA');
}

export function moveWellbore(sourceWellboreListId, targetWellboreListId, uniqueWellboreIdentifier) {
  return submitData('/api/myLists/moveWellbore', { sourceWellboreListId, targetWellboreListId, uniqueWellboreIdentifier }, 'POST', 'DIWA');
}

export function getRecommendedWellbores(wellboreListId) {
  return fetchData(`/api/myLists/${wellboreListId}/recommendations`, 'DIWA');
}

export function getWellboresOfList(wellboreListId) {
  return fetchData(`/api/myLists/${wellboreListId}/wellbores`, 'DIWA');
}

export async function getAppInsightsInstrumentationKey() {
  return fetchData(`/api/GetAppInsightsInstrumentationKey`, 'DIWA');
}
