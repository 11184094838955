import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import * as actions from '../../store';
import * as selectors from '../../store/selectors';
import Loader from './Loader';
import brandLogo from '../../../resources/images/equinor-logo.png';
import userIcon from '../../../resources/images/userIcon.png';
import feedbackIcon from '../../../resources/images/comment.svg';
import { getAppVersion } from '../../settings';
import { getAuthServiceSingleton } from '../../services/adal/authService';
import { history } from '../../store/config';
import FeedbackModal from './FeedbackModal';

class AppHeader extends Component {
  constructor(props) {
    super(props);

    // Refs
    this.ServiceNowFeedbackUrl = this.props.ServiceNowFeedbackUrl;
  }

  handleSignIn() {
    const authService = getAuthServiceSingleton();
    authService.loginUser('', (redirectUrl) => history.push(redirectUrl))
      .catch((e) => {
        throw e;
        // TODO: make sure error is displayed
      });
  }

  handleSignOut() {
    const authService = getAuthServiceSingleton();
    authService.logout();
  }

  onNavigateHome() {
    window.location.href = '/';
  }

  redirectToAdminUI = () => {
    const { hostname } = window.location;
    const domain = hostname.split('.').slice(-3).join('.');
    const adminUrl = hostname === 'localhost' ? '#' : `https://admin.${domain}`;
    window.location.replace(adminUrl);
  };

  render() {
    const authService = getAuthServiceSingleton();

    return (
      <div className="navbar navbar-dark navbar-expand-md app-header" style={{ position: 'fixed', width: '100%', zIndex: '20' }}>
        {this.props.loading && <Loader id="header" />}
        <NavLink onClick={() => this.onNavigateHome()} className="link" to={`/${authService.getPopupAuthSearchParameter()}`} exact>
          <span className="navbar-brand">
            <div className="brand">
              <img className="brand-logo" alt="Equinor" src={brandLogo} />
            </div>
          </span>
        </NavLink>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#header-menu" aria-controls="header-menu" aria-expanded="false">
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="header-menu">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item"><h1 style={{ fontSize: '18px', marginBottom: '0px' }}><span className="nav-link">Digital Well Assistant</span></h1></li>

            <li className="nav-item">
              <NavLink to={`/${authService.getPopupAuthSearchParameter()}`}><span className="nav-link">Search wellbores</span></NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={`/MyLists${authService.getPopupAuthSearchParameter()}`}><span className="nav-link">My lists (demo)</span></NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={`/Guides${authService.getPopupAuthSearchParameter()}`}><span className="nav-link">Guides</span></NavLink>
            </li>
          </ul>
          <ul
            className="navbar-nav ml-auto"
            style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
            }}
          >
            <li className="nav-item dropdown" style={{ verticalAlign: 'middle' }}>
              <a className="nav-link " href="#" id="feedbackDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="menu-item">
                  <img className="feedbackIcon" alt="feedback" src={feedbackIcon} style={{ marginRight: '20px', marginTop: '0px' }} />
                </span>
              </a>
              <div className="dropdown-menu dropdown-menu-right navbar-dark" style={{ marginRight: '1rem' }}>
                <a className="dropdown-item" href="#" onClick={() => FeedbackModal.show()} role="menuitem">
                  Feedback
                </a>
                <div className="dropdown-divider" />
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => window.open(this.props.ServiceNowFeedbackUrl.url)}
                  role="menuitem"
                >
                  Report bug
                </a>
              </div>
            </li>
            <li className="nav-item dropdown" style={{ verticalAlign: 'middle' }}>
              <a className="nav-link " href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="menu-item">
                  <img className="userIcon" alt="user" src={userIcon} style={{ marginRight: '20px', marginTop: '0px' }} />
                </span>
              </a>
              <div className="dropdown-menu dropdown-menu-right navbar-dark">
                <div className="dropdown-header">
                  <div>{this.props.currentUser.userId}</div>
                  <div>
                    version:
                    {getAppVersion()}
                  </div>
                </div>
                <div className="dropdown-divider" />
                { authService.isLoggedIn() && authService.getAccount().idTokenClaims.roles.some((role) => role === 'diwa_admin')
                  ? (<a tabIndex={0} href="#" className="dropdown-item" role="menuitem" onClick={this.redirectToAdminUI}>Admin</a>) : ''}

                { authService.isLoggedIn() && <a tabIndex={0} href="#" className="dropdown-item" role="menuitem" onClick={this.handleSignOut}>Sign out</a> }
                { !authService.isLoggedIn() && <a tabIndex={0} href="#" className="dropdown-item" role="menuitem" onClick={this.handleSignIn}>Sign in</a> }
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // --- Process
  loading: selectors.isLoading(state),
  state,
  ServiceNowFeedbackUrl: selectors.getServiceNowFeedbackUrl(state) || {},
  currentUser: selectors.getCurrentUser(state) || {},
});

const mapDispatchToProps = (dispatch) => ({
  // --- Process
  setLoading: (status) => dispatch(actions.setLoading(status)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppHeader));
