import _ from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WellPath3D, { CreatePlotName } from '../visualization/WellPath3D';
import { GoToDiv, ToHex } from '../../utils/main';
import Loader from '../common/Loader';
import { fetchData } from '../../services/api/api-helpers';
import { RESOURCE_NAME_MAIN, GETWELLPATH_URL, GETWELLBOREINFORMATION_URL } from '../../containers/MainPage';
import {
  getDisplayNameForSectionGroup,
  keywordTopics, rankingResultsTypes, rankingSectionGroupConstants, separators,
} from '../../types';
import './style.css';
import * as actions from '../../store';
import * as selectors from '../../store/selectors';
import { addToast, ToastTypes } from '../../utils/addToastByType';
import { formatDate } from '../../utils/dateUtils';
import { formatDepth, round } from '../../utils/formatters';
import { appInsightsLogError } from '../../services/appInsights/appInsights';

export class WellboreInfo extends Component {
  constructor(props) {
    super(props);

    this.key = 0;

    this.state = {
      // TODO: this properties should be factored into a wellboreInfo object
      basinIdentifier: props.basinIdentifier,
      fieldGuid: props.fieldGuid,
      fieldIdentifier: props.fieldIdentifier,
      formations: props.formations,
      formationViewModels: props.formationViewModels,
      drillingSections: props.drillingSections,
      inheritedSections: props.inheritedSections,
      completionSections: props.completionSections,
      keywords: props.keywords,
      rigs: props.rigs,
      sectionEndDate: props.sectionEndDate,
      operatorTitle: props.operatorTitle,
      rank: props.rank,
      rankDetails: props.rankDetails,
      subBasinIdentifier: props.subBasinIdentifier,
      surveyIdentifier: props.surveyIdentifier,
      totalDepth: props.totalDepth,
      totalDepthMd: props.totalDepthMd,
      totalDepthTVD: props.totalDepthTVD,
      uniqueWellboreIdentifier: props.uniqueWellboreIdentifier,
      wellboreSmdaGuid: props.wellboreSmdaGuid,
      id: ToHex(props.uniqueResultIdentifier),
      wellGuid: props.wellGuid,
      wellboreGuid: props.wellboreGuid,
      lastSection: props.lastSection,
      lastSectionName: props.lastSectionName,
      isLastSectionFromAnotherWellboreInSameWellboreTree: props.isLastSectionFromAnotherWellboreInSameWellboreTree,
      resultGroupName: props.resultGroupName,
      rankedSectionName: props.rankedSectionName,
      rankedSection: props.rankedSection,
      searchedSection: {
        sectionName: props.searchedSectionName,
        startDepth: props.searchedSectionStartDepth,
        endDepth: props.searchedSectionEndDepth,
        numericValue: props.searchedSectionNumericValue,
      },
      casingExitSize: props.casingExitSize,
      // end of wellboreInfo

      numberOfIncidents: null,
      plot: false,
      loading: false,
      error: false,
      traces: [],
      sectionRanking: props.sectionRanking,
      dummySectionForTitle: _.sample(props.drillingSections),
      isDeveloper: props.isDeveloper,
      filteredKeywords: props.sectionRanking
        ? this.getFilteredKeywordGroups(props.keywordViewModels, props.rankDetails)
        : this.getUnfilteredKeywordGroup(props.keywordViewModels),
      tubing: props.tubing,
    };
  }

  getUnfilteredKeywordGroup = (keywords) => (keywords.length ? [this.keywordGroup(null, keywords)] : [])

  getFilteredKeywordGroups = (keywords, result) => {
    const groupedKeywords = [];
    if (this.hasRankingValue(result[rankingResultsTypes.KEYWORD_COMPLETION_SECTIONS])) {
      groupedKeywords.push(
        this.keywordGroup(keywordTopics.COMPLETION_SECTIONS, keywords.filter((q) => q.keyword.groupName === keywordTopics.COMPLETION_SECTIONS)),
      );
    }
    if (this.hasRankingValue(result[rankingResultsTypes.KEYWORD_COMPLETION_EQUIPMENT])) {
      groupedKeywords.push(
        this.keywordGroup(keywordTopics.COMPLETION_EQUIPMENT, keywords.filter((q) => q.keyword.groupName === keywordTopics.COMPLETION_EQUIPMENT)),
      );
    }
    if (this.hasRankingValue(result[rankingResultsTypes.KEYWORD_WELL_INFORMATION])) {
      groupedKeywords.push(
        this.keywordGroup(keywordTopics.WELL, keywords.filter((q) => q.keyword.groupName === keywordTopics.WELL)),
      );
    }
    if (this.hasRankingValue(result[rankingResultsTypes.KEYWORD_OTHER])) {
      groupedKeywords.push(
        this.keywordGroup(keywordTopics.OTHER, keywords.filter((q) => q.keyword.groupName === keywordTopics.OTHER)),
      );
    }
    return groupedKeywords;
  }

  keywordGroup = (groupName, keywordsInGroup) => ({ groupName, keywordsInGroup })

  hasRankingValue = (rankingValue) => (rankingValue || (rankingValue === 0));

  getKey = () => {
    this.key += 1;
    return this.key;
  }

  handlePlotWellPath = () => {
    if (!this.state.plot && !this.state.error) {
      this.loading(true);

      if (!this.props.sourceWellPathName || !this.props.sourceWellPathCoordinates) {
        this.setState({
          traces: [],
        });
      } else {
        this.setState({
          traces: [{
            name: this.props.sourceWellPathName,
            data: this.props.sourceWellPathCoordinates,
          }],
        });
      }
      this.requestWellPathCoordinates();
    }
  }

  handleAddToMyLists = () => {
    const wellboreRankingResult = this.state;
    this.props.addWellboreToMyLists(wellboreRankingResult.uniqueWellboreIdentifier);
    addToast({ text: `Wellbore ${wellboreRankingResult.uniqueWellboreIdentifier} was added to default list!`, type: ToastTypes.INFO });
  }

  parseWellpathApiBody = (e) => {
    if (!e.body) {
      this.error(true);
      return null;
    }
    return JSON.parse(JSON.stringify(e.body));
  };

  handleWellpathApiError = (error) => {
    this.error(true);
    this.loading(false);

    if (error) {
      appInsightsLogError(error);
    }
  };

  requestWellPathCoordinates = () => {
    if (this.props.sectionRanking && !this.isSectionGroupRanking()) {
      const wellpathEndpoint = GETWELLBOREINFORMATION_URL;
      // TODO: move this to saga
      fetchData(wellpathEndpoint(this.state.uniqueWellboreIdentifier), RESOURCE_NAME_MAIN).then((e) => {
        const result = this.parseWellpathApiBody(e);
        if (result.wellpath && result.wellpath.length > 0) {
          this.setState((prevState) => ({
            traces: prevState.traces.concat(
              {
                name: this.state.uniqueWellboreIdentifier,
                data: result.wellpath,
                wellpathAdditionalInformation: result.wellpathAdditionalInformation.map((w) => {
                  const container = {};
                  container.md = w.md;
                  return container;
                }),
                holeSections: result.holeSections,
              },
            ),
            plot: true,
          }));
        } else {
          this.error(true);
        }

        this.loading(false);
      }, (e) => {
        this.handleWellpathApiError(e);
      });
    } else {
      const wellpathEndpoint = GETWELLPATH_URL;
      // TODO: move this to saga
      fetchData(wellpathEndpoint(this.state.uniqueWellboreIdentifier), RESOURCE_NAME_MAIN).then((e) => {
        const result = this.parseWellpathApiBody(e);
        if (result.wellpath && result.wellpath.length > 0) {
          this.setState((prevState) => ({
            traces: prevState.traces.concat(
              {
                name: this.state.uniqueWellboreIdentifier,
                data: result.wellpath,
              },
            ),
            plot: true,
          }));
        } else {
          this.error(true);
        }

        this.loading(false);
      }, (e) => {
        this.handleWellpathApiError(e);
      });
    }
    GoToDiv(CreatePlotName(`${this.state.id}`));
  }

  loading = (status) => {
    this.setState({
      loading: status,
    });
  }

  error = (status) => {
    this.setState({
      error: status,
    });
  }

  isMultipleRanking = () => this.state.rankDetails !== null
    && typeof (this.state.rankDetails) !== 'undefined'
    && Object.keys(this.state.rankDetails).length > 1

  listSizeClass = (obj) => ((typeof (obj) !== 'undefined' && obj !== null && obj.length > 2) ? 'list-overflow-y-200' : 'list-overflow-y-120');

  showFormattedDepth = (depth) => ((depth && depth !== 0) ? `${formatDepth(depth)} m` : 'N/A');

  showFormattedDepthInterval = (startDepth, endDepth) => (((startDepth && startDepth !== 0) || (endDepth && endDepth !== 0)) ? `[${formatDepth(startDepth)} - ${formatDepth(endDepth)}]` : '[N/A]');

  getKeywordRankingResult = (keywordLabel, keywordRanking) => (this.hasRankingValue(keywordRanking) && (
    <tr>
      <td align="right"><small>{keywordLabel}</small></td>
      <td><small>{this.percentageWithDecimals(keywordRanking, 1)}</small></td>
    </tr>
  ))

  percentageWithDecimals = (number, decimals) => `${(number * 100).toFixed(decimals)}%`;

  isSectionGroupRanking() {
    const { resultGroupName } = this.props;
    return resultGroupName === rankingSectionGroupConstants.UPPER_COMPLETION.value
    || resultGroupName === rankingSectionGroupConstants.PREPARE_SIDETRACK.value;
  }

  render() {
    const {
      id,
      formationViewModels,
      drillingSections,
      inheritedSections,
      completionSections,
      filteredKeywords,
      tubing,
      rankDetails,
      rank,
      uniqueWellboreIdentifier,
      rankedSectionName,
      isDeveloper,
      wellGuid,
      wellboreSmdaGuid,
      fieldIdentifier,
      rankedSection,
      lastSection,
      lastSectionName,
      isLastSectionFromAnotherWellboreInSameWellboreTree,
      resultGroupName,
      totalDepthMd,
      rigs,
      sectionEndDate,
      casingExitSize,
      plot,
      traces,
      searchedSection,
      error,
      loading,
    } = this.state;
    const {
      sectionRanking,
      isWellboreSaved,
    } = this.props;

    return (
      <div id={`parentForInfo_${id}`}>
        <p style={{ marginBottom: '0' }}>
          <button
            className="btn btn-outline-dark btn-xs"
            type="button"
            data-toggle="collapse"
            data-target={`#info_${id}`}
            aria-expanded="true"
            aria-controls={`info_${id}`}
          >
            Info
          </button>
          <button
            className="btn btn-outline-dark btn-xs"
            type="button"
            data-toggle="collapse"
            data-target={`#info2_${id}`}
            aria-expanded="true"
            aria-controls={`info2_${id}`}
            onClick={this.handlePlotWellPath}
          >
            Plot wellpath
          </button>
          <button
            className={isWellboreSaved(uniqueWellboreIdentifier) ? 'btn btn-success btn-xs' : 'btn btn-outline-dark btn-xs'}
            type="button"
            aria-expanded="true"
            aria-controls={`info2_${id}`}
            title="Add to my lists"
            onClick={this.handleAddToMyLists}
          >
            <FontAwesomeIcon icon="star" />
          </button>
        </p>
        <div className="collapse" id={`info_${id}`} data-parent={`#parentForInfo_${id}`}>
          <div className="row">
            <div className="col-sm-3">
              <div>
                <div className="card card-header">Formations</div>
                {(!Array.isArray(formationViewModels) || !formationViewModels.length)
                  ? (<div className="card card-body"><p className="text-muted">No formations loaded</p></div>)
                  : (
                    <div className={`card card-body ${this.listSizeClass(formationViewModels)}`}>
                      {formationViewModels.map((formationViewModel) => (
                        <p
                          key={`${formationViewModel.formation.formationName}_${formationViewModel.formation.entryMd}`}
                          className={`font-weight-bold ${formationViewModel.isSearchHit ? 'alert-info' : ''}`}
                        >
                          {formationViewModel.formation.formationName}
                          <span className="text-muted float-right">
                            {this.showFormattedDepthInterval(formationViewModel.formation.entryMd, formationViewModel.formation.exitMd)}
                          </span>
                        </p>
                      ))}
                    </div>
                  )}
              </div>
            </div>
            {!sectionRanking && (
              <div className="col-sm-3">
                <div>
                  <div className="card card-header">
                    <p style={{ marginBottom: 'inherit' }}>
                      Drilling sections
                      <span className="float-right">Depth</span>
                    </p>
                  </div>
                  {((!Array.isArray(drillingSections) || !drillingSections.length)
                  && (!Array.isArray(inheritedSections) || !inheritedSections.length))
                    ? (<div className="card card-body"><p className="text-muted">No drilling sections loaded</p></div>)
                    : (
                      <div className={`card card-body ${this.listSizeClass(drillingSections.concat(inheritedSections))}`}>
                        {inheritedSections && inheritedSections.map((inheritedSection, key = this.getKey()) => (
                          <div
                            className="sectionInfoWithCasing"
                            key={`${inheritedSection.sectionName}_${inheritedSection.sectionType}_${id}${key}`}
                          >
                            <div
                              id={`parentForCasing_${id}${key}`}
                              data-toggle="collapse"
                              data-target={`#Inherited_Section_${id}${key}`}
                              className={`font-weight-bold text-primary ${inheritedSection.isSearchHit ? 'alert-info' : ''}`}
                            >
                              {inheritedSection.sectionName}
                              <span className="text-muted float-right">
                                {this.showFormattedDepthInterval(inheritedSection.startDepth, inheritedSection.endDepth)}
                              </span>
                              <div
                                className="collapse"
                                id={`Inherited_Section_${id}${key}`}
                              >
                                Casing
                                <span className="text-muted float-right">
                                  {inheritedSection.casing && inheritedSection.casing.name
                                    ? inheritedSection.casing.name
                                    : 'N/A'}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                        {drillingSections && drillingSections.map((drillingSection, key = this.getKey()) => (
                          <div
                            id={`parentForCasing_${id}${key}`}
                            data-toggle="collapse"
                            data-target={`#Drilling_Section_${id}${key}`}
                            className={`sectionInfoWithCasing font-weight-bold ${drillingSection.isSearchHit ? 'alert-info' : ''}`}
                          >
                            {drillingSection.sectionName}
                            <span className="text-muted float-right">
                              {drillingSection.numericValue > 0
                                ? this.showFormattedDepthInterval(drillingSection.startDepth, drillingSection.endDepth)
                                : '[N/A]'}
                            </span>
                            <div
                              className="collapse"
                              id={`Drilling_Section_${id}${key}`}
                            >
                              Casing
                              <span className="text-muted float-right">
                                {drillingSection.casing && drillingSection.casing.name
                                  ? drillingSection.casing.name
                                  : 'N/A'}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                </div>
              </div>
            )}
            {!sectionRanking
            && (
              <div className="col-sm-3">
                <div>
                  <div className="card card-header">Completion sections</div>
                  {(!Array.isArray(completionSections) || !completionSections.length)
                    ? (<div className="card card-body"><p className="text-muted">No completion sections loaded</p></div>)
                    : (
                      <div className={`card card-body ${this.listSizeClass(completionSections)}`}>
                        {completionSections.map((completionSection) => (
                          <p
                            key={`${completionSection.sectionName}_${completionSection.sectionType}_${this.getKey()}`}
                            className={`font-weight-bold ${completionSection.isSearchHit ? 'alert-info' : ''}`}
                          >
                            {completionSection.sectionName}
                          </p>
                        ))}
                      </div>
                    )}
                </div>
              </div>
            )}
            <div className="col-sm-3">
              <div>
                <div className="card card-header">Keywords</div>
                {(!filteredKeywords.length
                && (
                  <div className="card card-body">
                    <p className="text-muted">No keywords loaded</p>
                  </div>
                ))}
                {!sectionRanking && (!!filteredKeywords.length)
                && (
                  <span>
                    {filteredKeywords.map((keywordGroup) => ((!!keywordGroup.keywordsInGroup.length) && (
                      <div key={keywordGroup.groupName} className={`card card-body ${this.listSizeClass(keywordGroup.keywordsInGroup)}`}>
                        {keywordGroup.keywordsInGroup.map((keywordViewModel) => (
                          <p
                            key={keywordViewModel.keyword.keyword}
                            className={`font-weight-bold ${keywordViewModel.isSearchHit ? 'alert-info' : ''}`}
                          >
                            {keywordViewModel.keyword.keyword}
                          </p>
                        ))}
                      </div>
                    )))}
                  </span>
                )}
                {sectionRanking && (!!filteredKeywords.length)
                  && (
                  <div className="card card-body list-overflow-y-200">
                    {filteredKeywords.map((keywordGroup) => ((!!keywordGroup.keywordsInGroup.length) && (
                    <div key={keywordGroup.groupName}>
                      <p
                        className="card-subtitle font-weight-bold"
                      >
                        {keywordGroup.groupName}
                      </p>
                      <div className="card-body">
                        {keywordGroup.keywordsInGroup.map((keywordViewModel) => (
                          <p
                            key={keywordViewModel.keyword.keyword}
                            className={`font-weight-bold ${keywordViewModel.isSearchHit ? 'alert-info' : ''}`}
                          >
                            {keywordViewModel.keyword.keyword}
                          </p>
                        ))}
                      </div>
                    </div>
                    )))}
                  </div>
                  )}
              </div>
            </div>
            {sectionRanking
            && (
            <div className="col-sm-3">
              <div>
                <div className="card card-header">Tubing sizes</div>
                <div className="card card-body list-overflow-y-200">
                  <p className="font-weight-bold">
                    Average size
                    <span className="text-muted float-right">
                      [
                      {tubing.averageSize != null ? `${round(tubing.averageSize, 2)}\"` : 'N/A'}
                      ]
                    </span>
                  </p>
                  <p className="font-weight-bold">
                    Main size
                    <span className="text-muted float-right">
                      [
                      {tubing.mainSize != null ? `${round(tubing.mainSize, 2)}\"` : 'N/A'}
                      ]
                    </span>
                  </p>
                  <p className="font-weight-bold">
                    Secondary size
                    <span className="text-muted float-right">
                      [
                      {tubing.secondarySize != null ? `${round(tubing.secondarySize, 2)}\"` : 'N/A'}
                      ]
                    </span>
                  </p>
                </div>
              </div>
            </div>
            )}
          </div>
        </div>
        <div className="collapse" id={`info2_${id}`} data-parent={`#parentForInfo_${id}`}>
          {plot
          && (
            <div className="row" align="right">
              <WellPath3D
                traces={traces}
                name={`${id}`}
                searchedSection={searchedSection}
                rankedSection={rankedSection}
              />
            </div>
          )}
          {error
          && (
            <p
              className="alert alert-danger"
              style={{ width: '100%', textAlign: 'left' }}
            >
              Could not load wellpath for
              {' '}
              {uniqueWellboreIdentifier}
              !
            </p>
          )}
          {/* {this.state.plot && <button
            className="btn btn-link"
            style={{ width: '100%', textAlign: 'left' }}
            onClick={this.handleHideWellPath}>
            Hide wellpath
          </button>} */}
          {loading && <div align="left"><Loader id={`loading_${id}`} size="s" /></div>}
        </div>
        <a
          key={id + wellGuid}
          href={`https://rep.equinor.com/#/wellbore/${wellboreSmdaGuid}`}
          target="_blank"
          rel="noreferrer"
          className="list-group-item list-group-item-action"
        >
          <div className="row">
            <div className="col-sm-10">
              <div className="row" style={{ marginLeft: '0px' }}>
                <h5>
                  <b>{uniqueWellboreIdentifier}</b>
                  {sectionRanking
                  && (
                    <p className="text-primary">
                      {`\t${resultGroupName === rankingSectionGroupConstants.LOWER_COMPLETION.value
                        ? rankingSectionGroupConstants.LOWER_COMPLETION.displayName
                        : getDisplayNameForSectionGroup(rankedSectionName)}`}
                    </p>
                  )}
                </h5>
              </div>
              <div className="row">
                <div className="col">
                  Field:
                  {' '}
                  {fieldIdentifier}
                </div>
                {/* <div className="col">
                  Basin: {r.basinIdentifier}
                </div>
                <div className="col">
                  Sub-Basin: {r.subBasinIdentifier}
                </div>
                <div className="col">
                  Operator: {r.operatorTitle}
                </div> */}
                {sectionRanking && rankedSection
                && (
                  <div className="col">
                    Depth:
                    {' '}
                    {this.showFormattedDepthInterval(rankedSection.startDepth, rankedSection.endDepth)}
                  </div>
                )}
                {sectionRanking
                && resultGroupName
                && (resultGroupName === rankingSectionGroupConstants.UPPER_COMPLETION.value || resultGroupName === rankingSectionGroupConstants.PREPARE_SIDETRACK.value)
                && (
                  <div className="col">
                    Depth:
                    {' '}
                    {this.showFormattedDepth(totalDepthMd)}
                  </div>
                )}
                {sectionRanking && rankedSection
                && (
                  <div className="col">
                    Casing:
                    {' '}
                    {rankedSection.casing && rankedSection.casing.name ? rankedSection.casing.name : 'N/A'}
                  </div>
                )}
                {(resultGroupName === rankingSectionGroupConstants.PREPARE_SIDETRACK.value || resultGroupName === rankingSectionGroupConstants.UPPER_COMPLETION.value)
                && (
                  <div className="col">
                    Casing exit size:
                    {' '}
                    {casingExitSize && casingExitSize.name ? casingExitSize.name : 'N/A'}
                  </div>
                )}
                {!sectionRanking
                && (
                  <div className="col">
                    Depth:
                    {' '}
                    {this.showFormattedDepth(totalDepthMd)}
                  </div>
                )}
                {rigs
                && (
                  <div className="col">
                    Rig(s):
                    {' '}
                    {rigs.join(', ')}
                  </div>
                )}
                {sectionRanking
                  && (
                    <div className="col">
                      Section end date:
                      {' '}
                      {formatDate(sectionEndDate)}
                    </div>
                  )}
                <div className="col">
                  Last section
                  {(isLastSectionFromAnotherWellboreInSameWellboreTree) ? ' (from parent wellbore):' : ':'}
                  {' '}
                  {(lastSection) ? `${lastSectionName} at ${formatDate(lastSection)}` : 'N/A'}
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <h5>
                <table className="table table-hover table-sm">
                  <tbody>
                    <tr>
                      <td align="right"><small><b> Rank </b></small></td>
                      <td><span className="badge badge-info">{this.percentageWithDecimals(rank, 1)}</span></td>
                    </tr>
                    {this.hasRankingValue(rankDetails[rankingResultsTypes.GEOMETRIC])
                    && (
                      <tr>
                        <td align="right"><small>{rankingResultsTypes.GEOMETRIC_UI}</small></td>
                        <td><small>{this.percentageWithDecimals(rankDetails[rankingResultsTypes.GEOMETRIC], 1)}</small></td>
                      </tr>
                    )}
                    {this.hasRankingValue(rankDetails[rankingResultsTypes.GEOMETRICSECTION])
                    && (
                      <tr>
                        <td align="right"><small>{rankingResultsTypes.GEOMETRICSECTION_UI}</small></td>
                        <td><small>{this.percentageWithDecimals(rankDetails[rankingResultsTypes.GEOMETRICSECTION], 1)}</small></td>
                      </tr>
                    )}
                    {this.hasRankingValue(rankDetails[rankingResultsTypes.HOLESIZEDEPTH])
                    && (
                      <tr>
                        <td align="right"><small>{rankingResultsTypes.HOLESIZEDEPTH_UI}</small></td>
                        <td><small>{this.percentageWithDecimals(rankDetails[rankingResultsTypes.HOLESIZEDEPTH], 1)}</small></td>
                      </tr>
                    )}
                    {this.hasRankingValue(rankDetails[rankingResultsTypes.FORMATION])
                    && (
                      <tr>
                        <td align="right"><small>{rankingResultsTypes.FORMATION_UI}</small></td>
                        <td><small>{this.percentageWithDecimals(rankDetails[rankingResultsTypes.FORMATION], 1)}</small></td>
                      </tr>
                    )}
                    {this.hasRankingValue(rankDetails[rankingResultsTypes.DRILLINGSECTION])
                      && (
                        <tr>
                          <td align="right"><small>{rankingResultsTypes.DRILLINGSECTION_UI}</small></td>
                          <td><small>{this.percentageWithDecimals(rankDetails[rankingResultsTypes.DRILLINGSECTION], 1)}</small></td>
                        </tr>
                      )}
                    {this.hasRankingValue(rankDetails[rankingResultsTypes.COMPLETIONSECTION])
                      && (
                        <tr>
                          <td align="right"><small>{rankingResultsTypes.COMPLETIONSECTION_UI}</small></td>
                          <td><small>{this.percentageWithDecimals(rankDetails[rankingResultsTypes.COMPLETIONSECTION], 1)}</small></td>
                        </tr>
                      )}
                    {this.hasRankingValue(rankDetails[rankingResultsTypes.HOLESECTION])
                      && (
                        <tr>
                          <td align="right"><small>{rankingResultsTypes.HOLESECTION_UI}</small></td>
                          <td><small>{this.percentageWithDecimals(rankDetails[rankingResultsTypes.HOLESECTION], 1)}</small></td>
                        </tr>
                      )}
                    {this.hasRankingValue(rankDetails[rankingResultsTypes.TUBING])
                      && (
                        <tr>
                          <td align="right"><small>{rankingResultsTypes.TUBING_UI}</small></td>
                          <td><small>{this.percentageWithDecimals(rankDetails[rankingResultsTypes.TUBING], 1)}</small></td>
                        </tr>
                      )}
                    {this.hasRankingValue(rankDetails[rankingResultsTypes.CASINGEXITSIZE])
                      && (
                        <tr>
                          <td align="right"><small>{rankingResultsTypes.CASINGEXITSIZE_UI}</small></td>
                          <td><small>{this.percentageWithDecimals(rankDetails[rankingResultsTypes.CASINGEXITSIZE], 1)}</small></td>
                        </tr>
                      )}
                    {this.getKeywordRankingResult(rankingResultsTypes.KEYWORD_UI, rankDetails[rankingResultsTypes.KEYWORD])}
                    {this.getKeywordRankingResult(rankingResultsTypes.KEYWORDCOMPLETIONSECTIONS_UI,
                      rankDetails[rankingResultsTypes.KEYWORD_COMPLETION_SECTIONS])}
                    {this.getKeywordRankingResult(rankingResultsTypes.KEYWORDCOMPLETIONEQUIPMENT_UI,
                      rankDetails[rankingResultsTypes.KEYWORD_COMPLETION_EQUIPMENT])}
                    {this.getKeywordRankingResult(rankingResultsTypes.KEYWORDWELLINFORMATION_UI, rankDetails[rankingResultsTypes.KEYWORD_WELL_INFORMATION])}
                    {this.getKeywordRankingResult(rankingResultsTypes.KEYWORDOTHER_UI, rankDetails[rankingResultsTypes.KEYWORD_OTHER])}
                    {sectionRanking && isDeveloper
                    && (
                      <tr>
                        <td align="right"><small className="text-primary">Wellbore aggregated score</small></td>
                        <td><small className="text-primary">{this.percentageWithDecimals(Math.floor(Math.random() * 101) / 100, 0)}</small></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </h5>
            </div>
          </div>

        </a>
      </div>
    );
  }
}

// TODO: remove redux dependency from component
const mapStateToProps = (state) => ({
  isWellboreSaved: (uniqueWellboreIdentifier) => selectors.isWellboreSaved(state, uniqueWellboreIdentifier),
});

const mapDispatchToProps = (dispatch) => ({
  addWellboreToMyLists: (uniqueWellboreIdentifier) => dispatch(actions.addWellboreToMyLists(uniqueWellboreIdentifier)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WellboreInfo);
