import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../store';
import * as selectors from '../store/selectors';
import Panel from '../components/common/Panel';
import { fetchData, createUrl } from '../services/api/api-helpers';
import { RESOURCE_NAME_MAIN } from './MainPage';
import { GoToDiv } from '../utils/main';
import { targetDivs } from '../types';
import VideoItem from '../components/media/VideoItem';
import { appInsightsLogError } from '../services/appInsights/appInsights';
import { ensureAppInsightsInitialization } from '../services/appInsights/appInsightsSetupIntegration'

import { getAuthServiceSingleton, getLoginErrorMessage } from '../services/adal/authService';

// Azure functions request literals
const GETVIDEOLIST_URL = '/api/GetVideoList';
const LAUNCHVIDEOPLAYER_URL = '/api/LaunchVideoPlayer';

class GuidesPage extends Component {
  componentDidMount = () => {
    const { setLoading, setVideoList } = this.props;
    const authService = getAuthServiceSingleton();
    this.hideAlerts();
    setLoading(true);
    authService.ensureUserLoggedIn()
      .then(() => ensureAppInsightsInitialization(this))
      .then(() => {
        fetchData(GETVIDEOLIST_URL, RESOURCE_NAME_MAIN).then((e) => {
          if (e && e.body) {
            this.hideAlerts();
            setVideoList(e.body);
          } else {
            const errorMessage = 'Could not load video list.';
            this.showError('', errorMessage, new Error(errorMessage));
          }

          setLoading(false);
        }, (r) => {
          this.showError(GETVIDEOLIST_URL,
            `Could not load video list. ${r ? r.message : ''}`, r);
          setLoading(false);
        });
      })
      .catch((e) => {
        throw new Error(getLoginErrorMessage(e));
      });
  }

  showError = (source, message, error) => {
    const { setLoading } = this.props;
    setLoading(false);
    this.props.setError({
      source,
      message,
    });

    if (error) {
      appInsightsLogError(error);
    }

    GoToDiv(targetDivs.ERROR);
  }

  hideAlerts = () => {
    this.props.setInfo('');
    this.props.setError(null);
  }

  render() {
    const {
      isLoading, getInfo, getError, getVideoList,
    } = this.props;
    return (
      <div className="animate-bottom" style={isLoading ? { pointerEvents: 'none', opacity: '0.2' } : {}}>
        <div style={{ paddingTop: '100px' }}>
          <Panel
            panelId="GuidesPanel"
            title="Guides"
            description="Here you can find some video guides for using DIWA."
            toggle={() => true}
            disableToggle
            isVisible={() => true}
            isLoading={false}
          >
            {getVideoList.map((video) => (
              <div key={video.videoName}>
                <VideoItem url={video.videoUri} videoName={video.videoName} subtitles={video.subtitles} />
              </div>
            ))}
          </Panel>

          {/* Messages */}
          {getInfo !== '' && (
          <div id={targetDivs.INFO} className="alert alert-info custom-margin" role="alert">
            <b>{getInfo}</b>
          </div>
          )}
          {getError && (
          <div id={targetDivs.ERROR} className="alert alert-danger custom-margin" role="alert">
            <b>ERROR</b>
            {' '}
            {getError.source !== '' ? `[${getError.source}] :` : ''}
            {' '}
            [
            {' '}
            <b>{getError.message}</b>
            {' '}
            ]
          </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // Process
  isLoading: selectors.isLoading(state),
  getInfo: selectors.getInfo(state),
  getError: selectors.getError(state),

  // Guides
  getVideoList: selectors.getVideoList(state),
});

const mapDispatchToProps = (dispatch) => ({
  // Process
  setLoading: (status) => dispatch(actions.setLoading(status)),
  setInfo: (info) => dispatch(actions.setInfo(info)),
  setError: (error) => dispatch(actions.setError(error)),
  
  // Guides
  setVideoList: (videoList) => dispatch(actions.setVideoList(videoList)),
});

GuidesPage.propTypes = {
  setLoading: PropTypes.func.isRequired,
};

GuidesPage.defaultProps = {
  //
};

export default connect(mapStateToProps, mapDispatchToProps)(GuidesPage);
