import { XOR } from '../../utils/main';

const FilterPropertyPathSeparator = '|';

export const FilterPropertyPath = {
  DEPTH: 'totalDepthMd',
  LAST_SECTION: 'lastSection',
  FIELD: 'fieldIdentifier',
  FORMATION: 'formations|formationName',
  DRILLING_SECTION: 'drillingSections|sectionName',
  INHERITED_SECTION: 'inheritedSections|sectionName',
  COMPLETION_SECTION: 'completionSections|sectionName',
  RIG: 'rigs',
  RESULT_GROUP_SECTION_NAME: 'resultGroupName',
  SECTION_GROUP: 'sectionGroup',
  MINIMUM_SCORE: 'rank',
};

export const FilterTypes = {
  RANGE: 'range',
  ELEMENTS: 'elements',
  EXCLUDED_ELEMENTS: 'excluded_elements',
  MULTIPLE_PROPERTIES: 'multiple_properties',
};

const GetPropertyValue = (element, propertyNamePath, separator) => {
  const path = Array.isArray(propertyNamePath) ? propertyNamePath : propertyNamePath.split(separator);

  if (path.length === 1 && element) return element[path[0]];

  let value = element;

  if (value && path) {
    for (let i = 0; i < path.length; i++) {
      if (typeof (value) !== 'undefined' && value !== null && typeof (value[path[i]]) !== 'undefined') {
        if (Array.isArray(value[path[i]])) {
          const arrayValue = [];
          for (let j = 0; j < value[path[i]].length; j++) {
            arrayValue.push(GetPropertyValue(value[path[i]][j], path.slice(1), separator));
          }

          value = arrayValue;
          break;
        } else {
          value = value[path[i]];
        }
      }
    }
  }
  return value;
};

const IsInRange = (obj, prop, min, max, separator) => {
  const value = GetPropertyValue(obj, prop, separator);

  if (min === null && max === null) {
    return true;
  } if (min !== null && max === null) {
    return value !== null && value >= min;
  } if (min === null && max !== null) {
    return value !== null && value <= max;
  }

  return value !== null
    && value >= min
    && value <= max;
};

const DoesOneExist = (obj, prop, list, separator) => {
  const value = GetPropertyValue(obj, prop, separator);

  return value !== null
    && list.some((element) => {
      if (value.toLowerCase() === element.toLowerCase()) {
        return true;
      }
    }) === true;
};

const DoSomeExist = (obj, prop, list, separator, allMustExist) => {
  const value = GetPropertyValue(obj, prop, separator);

  return value !== null
    && Array.isArray(value)
    && (allMustExist
      ? list.every((el) => value.indexOf(el) !== -1)
      : list.some((el) => value.indexOf(el) !== -1));
};

const FilterOnRange = (data, filterName, filterValues) => {
  if (Array.isArray(data) && filterValues.length === 2) {
    return data.filter((el) => IsInRange(el, filterName, filterValues[0], filterValues[1], FilterPropertyPathSeparator));
  }

  return data;
};

const FilterOnOneOfMany = (data, filterName, filterValues, exclude) => {
  if (Array.isArray(data)) {
    return data.filter((el) => XOR(exclude,
      DoesOneExist(el, filterName, filterValues, FilterPropertyPathSeparator)));
  }

  return data;
};

const FilterOnSomeOfMany = (data, filterName, filterValues, exclude, allMustExist) => {
  if (Array.isArray(data)) {
    return data.filter((el) => XOR(exclude,
      DoSomeExist(el, filterName, filterValues, FilterPropertyPathSeparator, allMustExist)));
  }

  return data;
};

const FilterBySectionGroup = (data, filterValues) => {
  if (Array.isArray(data)) {
    const drillingSectionFilteredResults = data.filter((el) => XOR(false,
      DoSomeExist(el, 'drillingSections|sectionGroup',
        filterValues, FilterPropertyPathSeparator, false)));
    const completionSectionFilteredResults = data.filter((el) => XOR(false,
      DoSomeExist(el, 'completionSections|sectionGroup',
        filterValues, FilterPropertyPathSeparator, false)));
    const holeSectionFilteredResults = data.filter((el) => XOR(false,
      DoSomeExist(el, 'holeSections|sectionGroup',
        filterValues, FilterPropertyPathSeparator, false)));
    return drillingSectionFilteredResults.concat(completionSectionFilteredResults)
      .concat(holeSectionFilteredResults);
  }

  return data;
};

export const ApplyFilters = (wellInfoSet, filters) => {
  let filteredSet = wellInfoSet;

  filters.map((filter) => {
    if (filter.name && filter.values && filter.values.length > 0 && filter.type) {
      switch (filter.type) {
        case FilterTypes.RANGE:
          filteredSet = FilterOnRange(filteredSet, filter.name, filter.values);
          break;
        case FilterTypes.ELEMENTS:
          filteredSet = (filter.unique
            ? FilterOnOneOfMany(filteredSet, filter.name, filter.values, false)
            : FilterOnSomeOfMany(filteredSet, filter.name, filter.values, false, filter.checkAll)
          );
          break;
        case FilterTypes.EXCLUDED_ELEMENTS:
          filteredSet = (filter.unique
            ? FilterOnOneOfMany(filteredSet, filter.name, filter.values, true)
            : FilterOnSomeOfMany(filteredSet, filter.name, filter.values, true, filter.checkAll)
          );
          break;
        case FilterTypes.MULTIPLE_PROPERTIES:
          filteredSet = FilterBySectionGroup(filteredSet, filter.values);
          break;
        default: //
      }
    }
  });

  return filteredSet;
};
