import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FixedSizeList } from 'react-window';

function renderRow(props) {
  const { data, index, style } = props;

  return React.cloneElement(data[index], {
    style: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
      ...style,
    },
  });
}

// Adapter for react-window
const ListboxComponent = React.forwardRef((props, ref) => {
  const { children, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 48;

  const outerElementType = React.useMemo(() => React.forwardRef((props2, ref2) => <div ref={ref2} {...props2} {...other} />), []);

  return (
    <div ref={ref}>
      <FixedSizeList
        style={{ padding: 0, height: Math.min(8, itemCount) * itemSize, maxHeight: 'auto' }}
        itemData={children}
        height={250}
        width="100%"
        outerElementType={outerElementType}
        innerElementType="ul"
        itemSize={itemSize}
        overscanCount={5}
        itemCount={itemCount}
      >
        {renderRow}
      </FixedSizeList>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

export default function Virtualize(props) {
  const {
    id, options, onChange, value, label, input,
  } = props;

  return (
    <Autocomplete
      id={id}
      style={{ width: 300 }}
      size="small"
      disableListWrap
      ListboxComponent={ListboxComponent}
      options={options}
      onChange={onChange}
      value={value}
      renderInput={(params) => {
        if (input) {
          return input(params, label);
        }
        return <TextField {...params} variant="outlined" label={label} fullWidth />;
      }}
    />
  );
}
