import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../store';
import * as selectors from '../store/selectors';
import Panel from '../components/common/Panel';
import DepthFilter from '../components/filters/depth';
import MinimumScoreFilter from '../components/filters/MinimumScore';
import BaseMultipleInputFilter from '../components/filters/baseMultipleInputFilter';
import DateIntervalFilter from '../components/filters/DateIntervalFilter';
import {
  targetDivs, filterConstants, sectionTypes, propertyNames, rankingConstants, rankingTypes,
} from '../types';

class FiltersPanel extends Component {
  handleDateIntervalMinChanged = (min) => {
    this.props.setMinDate(min);
  }

  handleDateIntervalMaxChanged = (max) => {
    this.props.setMaxDate(max);
  }

  handleDateFilterTypeChange = (dateFilterType) => {
    this.props.setDateFilterType(dateFilterType);
  }

  render() {
    return (
      <Panel
        panelId={targetDivs.FILTERS_PANEL}
        title="Filters"
        description="Configure filters on the result set of reference wells"
        toggle={this.props.toggleElementVisibility}
        isVisible={this.props.isElementVisible}
        isLoading={this.props.loadingFilters}
      >
        <div>
          <DepthFilter
            minValue={this.props.minDepth}
            maxValue={this.props.maxDepth}
            onChangeMinValue={this.props.setMinDepth}
            onChangeMaxValue={this.props.setMaxDepth}
            isDepthFilterValid={this.props.isDepthFilterValid}
            depthFilterErrorMessage={this.props.depthFilterErrorMessage}
          />
          <MinimumScoreFilter
            minValue={this.props.minimumScore}
            onChangeMinValue={this.props.setMinimumScore}
            rankingType={rankingTypes.WELLBORE_RANKING}
            isMinimumScoreFilterValid={this.props.isMinimumScoreFilterValid}
            minimumScoreFilterErrorMessage={this.props.minimumScoreFilterErrorMessage}
          />
          <BaseMultipleInputFilter
            name={filterConstants.FILTER_FIELD}
            handleSelectValue={this.props.addField}
            handleRemoveValue={this.props.removeField}
            selectedValues={this.props.selectedFields}
            availableValues={this.props.availableFields}
            excludeOption="true"
            excludeCondition={this.props.isExcludeOn}
            excludeAction={this.props.setExcludeOn}
          />
          <BaseMultipleInputFilter
            name={filterConstants.FILTER_FORMATION}
            handleSelectValue={this.props.addFormation}
            handleRemoveValue={this.props.removeFormation}
            selectedValues={this.props.selectedFormations}
            availableValues={this.props.availableFormations}
            optionLabelProperty={propertyNames.FORMATION_NAME_PROPERTY}
          />
          <BaseMultipleInputFilter
            name={filterConstants.FILTER_SECTION_DRILLING}
            handleSelectValue={this.props.addSection[sectionTypes.DRILLING]}
            handleRemoveValue={this.props.removeSection[sectionTypes.DRILLING]}
            selectedValues={this.props.selectedSections[sectionTypes.DRILLING]}
            availableValues={this.props.availableSections[sectionTypes.DRILLING]}
          />
          <BaseMultipleInputFilter
            name={filterConstants.FILTER_SECTION_COMPLETION}
            handleSelectValue={this.props.addSection[sectionTypes.COMPLETION]}
            handleRemoveValue={this.props.removeSection[sectionTypes.COMPLETION]}
            selectedValues={this.props.selectedSections[sectionTypes.COMPLETION]}
            availableValues={this.props.availableSections[sectionTypes.COMPLETION]}
          />
          <BaseMultipleInputFilter
            name={filterConstants.FILTER_RIG}
            handleSelectValue={this.props.addRig}
            handleRemoveValue={this.props.removeRig}
            selectedValues={this.props.selectedRigs}
            availableValues={this.props.availableRigs}
          />
          <BaseMultipleInputFilter
            name={filterConstants.FILTER_SECTION_GROUP}
            handleSelectValue={this.props.addSectionGroup}
            handleRemoveValue={this.props.removeSectionGroup}
            selectedValues={this.props.selectedSectionGroups}
            availableValues={this.props.availableSectionGroups}
          />
          <DateIntervalFilter
            onMinChange={this.handleDateIntervalMinChanged}
            onMaxChange={this.handleDateIntervalMaxChanged}
            onDateFilterTypeChange={this.handleDateFilterTypeChange}
            minValue={this.props.minDate}
            maxValue={this.props.maxDate}
            dateFilterType={this.props.dateFilterType}
          />
        </div>
      </Panel>
    );
  }
}

const mapStateToProps = (state) => ({
  // General
  isElementVisible: (elementId) => selectors.isElementVisible(state, elementId),

  // Search
  // --- Process
  loadingFilters: selectors.isLoadingFilters(state),

  // Filters
  // --- Depth
  minDepth: selectors.getMinDepth(state),
  maxDepth: selectors.getMaxDepth(state),
  isDepthFilterValid: selectors.getIsDepthFilterValid(state),
  depthFilterErrorMessage: selectors.getDepthFilterErrorMessage(state),

  // --- date
  minDate: selectors.getMinDate(state),
  maxDate: selectors.getMaxDate(state),
  dateFilterType: selectors.getDateFilterType(state),
  // --- Field
  availableFields: selectors.getAvailableFields(state),
  selectedFields: selectors.getSelectedFields(state),
  // --- Minimum score
  minimumScore: (type) => selectors.getMinimumScore(state, type),
  isMinimumScoreFilterValid: (type) => selectors.getIsMinimumScoreFilterValid(state, type),
  minimumScoreFilterErrorMessage: (type) => selectors.getMinimumScoreFilterErrorMessage(state, type),
  // --- Formation
  availableFormations: selectors.getAvailableFormations(state),
  selectedFormations: selectors.getSelectedFormations(state),
  // --- Section
  availableSections: {
    [sectionTypes.DRILLING]: selectors.getAvailableSections(state, sectionTypes.DRILLING),
    [sectionTypes.COMPLETION]: selectors.getAvailableSections(state, sectionTypes.COMPLETION),
  },
  selectedSections: {
    [sectionTypes.DRILLING]: selectors.getSelectedSections(state, sectionTypes.DRILLING),
    [sectionTypes.COMPLETION]: selectors.getSelectedSections(state, sectionTypes.COMPLETION),
  },
  // --- Rig
  availableRigs: selectors.getAvailableRigs(state),
  selectedRigs: selectors.getSelectedRigs(state),
  // --- Keyword
  availableKeywords: selectors.getAvailableKeywords(state),
  selectedKeywords: selectors.getSelectedKeywords(state),
  // --- Number of results
  maxResults: selectors.getMaxResults(state),
  // --- Exclude
  isExcludeOn: (type) => selectors.isExcludeOn(state, type),
  // -- Section group
  availableSectionGroups: selectors.getAvailableSectionGroups(state),
  selectedSectionGroups: selectors.getSelectedSectionGroups(state),
});

const mapDispatchToProps = (dispatch) => ({
  // General
  toggleElementVisibility: (elementId) => dispatch(actions.toggleElementVisibility(elementId)),

  // Filters
  // --- Depth
  setMinDepth: (depth) => dispatch(actions.setMinDepth(depth)),
  setMaxDepth: (depth) => dispatch(actions.setMaxDepth(depth)),

  // --- Minimum score
  setMinimumScore: (type, score) => dispatch(actions.setMinimumScore({ type: type, value: score })),

  // --- Date intervall
  setMinDate: (minDate) => dispatch(actions.setMinDate(minDate)),
  setMaxDate: (maxDate) => dispatch(actions.setMaxDate(maxDate)),
  setDateFilterType: (dateFilterType) => dispatch(actions.setDateFilterType(dateFilterType)),
  // --- Field
  setAvailableFields: (fields) => dispatch(actions.setAvailableFields(fields)),
  addField: (field) => dispatch(actions.addField(field)),
  removeField: (field) => dispatch(actions.removeField(field)),
  // --- Formation
  setAvailableFormations: (formations) => dispatch(actions.setAvailableFormations(formations)),
  addFormation: (formation) => dispatch(actions.addFormation(formation)),
  removeFormation: (formation) => dispatch(actions.removeFormation(formation)),
  // --- Section
  setAvailableSections: {
    [sectionTypes.DRILLING]: (sections) => dispatch(actions.setAvailableSections({ type: sectionTypes.DRILLING, value: sections })),
    [sectionTypes.COMPLETION]: (sections) => dispatch(actions.setAvailableSections({ type: sectionTypes.COMPLETION, value: sections })),
  },
  addSection: {
    [sectionTypes.DRILLING]: (section) => dispatch(actions.addSection({ type: sectionTypes.DRILLING, value: section })),
    [sectionTypes.COMPLETION]: (section) => dispatch(actions.addSection({ type: sectionTypes.COMPLETION, value: section })),
  },
  removeSection: {
    [sectionTypes.DRILLING]: (section) => dispatch(actions.removeSection({ type: sectionTypes.DRILLING, value: section })),
    [sectionTypes.COMPLETION]: (section) => dispatch(actions.removeSection({ type: sectionTypes.COMPLETION, value: section })),
  },
  // --- Rig
  setAvailableRigs: (rigs) => dispatch(actions.setAvailableRigs(rigs)),
  addRig: (rig) => dispatch(actions.addRig(rig)),
  removeRig: (rig) => dispatch(actions.removeRig(rig)),
  // --- Keyword
  setAvailableKeywords: (keywords) => dispatch(actions.setAvailableKeywords(keywords)),
  addKeyword: (keyword) => dispatch(actions.addKeyword(keyword)),
  removeKeyword: (keyword) => dispatch(actions.removeKeyword(keyword)),
  // --- Number of results
  setMaxResults: (number) => dispatch(actions.setMaxResults(number)),
  // --- Exclude
  setExcludeOn: (type, status) => dispatch(actions.setExcludeOn({ type, value: status })),

  // -- Section group
  setAvailableSetionGroups: (sectionGroups) => dispatch(actions.setAvailableSectionGroups(sectionGroups)),
  addSectionGroup: (sectionGroup) => dispatch(actions.addSectionGroup(sectionGroup)),
  removeSectionGroup: (sectionGroup) => dispatch(actions.removeSectionGroup(sectionGroup)),
});

FiltersPanel.propTypes = {
  setMinDepth: PropTypes.func.isRequired,
  setMaxDepth: PropTypes.func.isRequired,

  setMinimumScore: PropTypes.func.isRequired,

  setMinDate: PropTypes.func.isRequired,
  setMaxDate: PropTypes.func.isRequired,

  toggleElementVisibility: PropTypes.func.isRequired,
  isElementVisible: PropTypes.func.isRequired,
  loadingFilters: PropTypes.bool.isRequired,

  minDepth: PropTypes.number.isRequired,
  maxDepth: PropTypes.number.isRequired,

  minimumScore: PropTypes.func.isRequired,

  addField: PropTypes.func.isRequired,
  removeField: PropTypes.func.isRequired,
  selectedFields: PropTypes.array.isRequired,
  availableFields: PropTypes.array.isRequired,
  isExcludeOn: PropTypes.func.isRequired,
  setExcludeOn: PropTypes.func.isRequired,

  addFormation: PropTypes.func.isRequired,
  removeFormation: PropTypes.func.isRequired,
  selectedFormations: PropTypes.array.isRequired,
  availableFormations: PropTypes.array.isRequired,

  addSection: PropTypes.object.isRequired,
  removeSection: PropTypes.object.isRequired,
  selectedSections: PropTypes.object.isRequired,
  availableSections: PropTypes.object.isRequired,

  addRig: PropTypes.func.isRequired,
  removeRig: PropTypes.func.isRequired,
  selectedRigs: PropTypes.array.isRequired,
  availableRigs: PropTypes.array.isRequired,

  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),

  addSectionGroup: PropTypes.func.isRequired,
  removeSectionGroup: PropTypes.func.isRequired,
  selectedSectionGroups: PropTypes.array.isRequired,
  availableSectionGroups: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersPanel);
