export const extractErrorMessage = (error, defaultErrorMessage = 'Unknown error.') => {
  if (error instanceof Error) {
    return error.message === '' ? defaultErrorMessage : error.message;
  }

  if (typeof error === 'string') {
    return error === '' ? defaultErrorMessage : error;
  }
  return defaultErrorMessage;
};
