import { handleActions } from 'redux-actions';
import * as actions from './filtersActions';
import { filterConstants, sectionTypes, rankingTypes } from '../../types';

const SECTION_MAX_RESULTS_UPPER_THRESHOLD = 250;
const defaultState = {
  minDepth: filterConstants.DEFAULT_MIN_DEPTH,
  maxDepth: filterConstants.DEFAULT_MAX_DEPTH,
  isDepthFilterValid: true,
  depthFilterErrorMessage: '',

  minDate: null,
  maxDate: null,
  dateFilterType: '',
  availableFields: [],
  selectedFields: [],

  availableFormations: [],
  selectedFormations: [],

  availableSections: {
    [sectionTypes.DRILLING]: [],
    [sectionTypes.COMPLETION]: [],
    [sectionTypes.HOLE]: [],
  },
  selectedSections: {
    [sectionTypes.DRILLING]: [],
    [sectionTypes.COMPLETION]: [],
    [sectionTypes.HOLE]: [],
  },

  availableKeywords: [],
  selectedKeywords: [],

  availableRigs: [],
  selectedRigs: [],
  minimumScore: {
    [rankingTypes.WELLBORE_RANKING]: {
      value: filterConstants.DEFAULT_MINIMUM_SCORE,
      isMinimumScoreFilterValid: true,
      depthFilterErrorMessage: '' 
    },
    [rankingTypes.SECTION_RANKING]: {
      value: filterConstants.DEFAULT_MINIMUM_SCORE,
      isMinimumScoreFilterValid: true,
      minimumScoreFilterErrorMessage: '' 
    },
  },
  maxResults: {
    [rankingTypes.WELLBORE_RANKING]: 10000,
    [rankingTypes.SECTION_RANKING]: SECTION_MAX_RESULTS_UPPER_THRESHOLD,
  },

  excludeOn: {
    [filterConstants.FILTER_FIELD]: false,
    [filterConstants.FILTER_FORMATION]: false,
    [filterConstants.FILTER_SECTION_DRILLING]: false,
    [filterConstants.FILTER_SECTION_COMPLETION]: false,
    [filterConstants.FILTER_SECTION_HOLE]: false,
    [filterConstants.FILTER_RIG]: false,
    [filterConstants.FILTER_KEYWORD]: false,
  },

  availableSectionGroups: [],
  selectedSectionGroups: [],
  rankingSectionGroups: [],
};
const depthInvalidMessage = 'Min depth is greater than Max depth.';
const minimumScoreInvalidMessage = 'Min score is greater than 100';

export default handleActions({
  [actions.setMinDepth]: (state, action) => {
    const newMinDepth = action.payload;
    const isDepthFilterValid = state.maxDepth == null || newMinDepth == null || state.maxDepth >= newMinDepth;
    return {
      ...state,
      minDepth: newMinDepth,
      isDepthFilterValid,
      depthFilterErrorMessage: (isDepthFilterValid ? '' : depthInvalidMessage),
    };
  },

  [actions.setMaxDepth]: (state, action) => {
    const newMaxDepth = action.payload;
    const isDepthFilterValid = newMaxDepth == null || state.minDepth == null || newMaxDepth >= state.minDepth;
    return {
      ...state,
      maxDepth: newMaxDepth,
      isDepthFilterValid,
      depthFilterErrorMessage: (isDepthFilterValid ? '' : depthInvalidMessage),
    };
  },

  [actions.setMinimumScore]: (state, action) => {
    const { type, value } = action.payload;
    const isMinimumScoreFilterValid = value >= 0 && value <= 100;
    return {
      ...state,
      minimumScore: {
        ...(state.minimumScore),
        [type]: {
          value: value,
          isMinimumScoreFilterValid: isMinimumScoreFilterValid,
          minimumScoreFilterErrorMessage: (isMinimumScoreFilterValid ? '' : minimumScoreInvalidMessage) 
        }
      }
    };
  },

  [actions.setMinDate]: (state, action) => ({
    ...state,
    minDate: action.payload,
  }),

  [actions.setMaxDate]: (state, action) => ({
    ...state,
    maxDate: action.payload,
  }),
  [actions.setDateFilterType]: (state, action) => ({
    ...state,
    dateFilterType: action.payload,
  }),
  [actions.setAvailableFields]: (state, action) => ({
    ...state,
    availableFields: action.payload,
  }),

  [actions.addField]: (state, action) => ({
    ...state,
    selectedFields: state.selectedFields.concat(action.payload),
  }),

  [actions.removeField]: (state, action) => ({
    ...state,
    selectedFields: state.selectedFields.filter((field) => field !== action.payload),
  }),

  [actions.setAvailableFormations]: (state, action) => ({
    ...state,
    availableFormations: action.payload,
  }),

  [actions.addFormation]: (state, action) => ({
    ...state,
    selectedFormations: state.selectedFormations.concat(action.payload),
  }),

  [actions.removeFormation]: (state, action) => ({
    ...state,
    selectedFormations: state.selectedFormations.filter((formation) => formation !== action.payload),
  }),

  [actions.setAvailableSections]: (state, action) => {
    const { type, value } = action.payload;
    return {
      ...state,
      availableSections: {
        ...state.availableSections,
        [type]: value,
      },
    };
  },

  [actions.addSection]: (state, action) => {
    const { type, value } = action.payload;
    return {
      ...state,
      selectedSections: {
        ...(state.selectedSections),
        [type]: state.selectedSections[type].concat(value),
      },
    };
  },

  [actions.removeSection]: (state, action) => {
    const { type, value } = action.payload;
    return {
      ...state,
      selectedSections: {
        ...(state.selectedSections),
        [type]: state.selectedSections[type].filter((section) => section !== value),
      },
    };
  },

  [actions.setAvailableKeywords]: (state, action) => ({
    ...state,
    availableKeywords: action.payload,
  }),

  [actions.addKeyword]: (state, action) => ({
    ...state,
    selectedKeywords: state.selectedKeywords.concat(action.payload),
  }),

  [actions.removeKeyword]: (state, action) => ({
    ...state,
    selectedKeywords: state.selectedKeywords.filter((keyword) => keyword !== action.payload),
  }),

  [actions.setAvailableRigs]: (state, action) => ({
    ...state,
    availableRigs: action.payload,
  }),

  [actions.addRig]: (state, action) => ({
    ...state,
    selectedRigs: state.selectedRigs.concat(action.payload),
  }),

  [actions.removeRig]: (state, action) => ({
    ...state,
    selectedRigs: state.selectedRigs.filter((rig) => rig !== action.payload),
  }),

  [actions.setMaxResults]: (state, action) => {
    const { type, value } = action.payload;
    const maxResultsWithUpperLimit = (
      value
      && (
        (type === rankingTypes.SECTION_RANKING && value <= SECTION_MAX_RESULTS_UPPER_THRESHOLD)
        || type === rankingTypes.WELLBORE_RANKING
      )
    ) ? value : state.maxResults[type];

    return {
      ...state,
      maxResults: {
        ...(state.maxResults),
        [type]: maxResultsWithUpperLimit,
      },
    };
  },

  [actions.setExcludeOn]: (state, action) => {
    const { type, value } = action.payload;
    return {
      ...state,
      excludeOn: {
        ...state.excludeOn,
        [type]: value,
      },
    };
  },

  [actions.setAvailableSectionGroups]: (state, action) => ({
    ...state,
    availableSectionGroups: action.payload,
  }),

  [actions.setRankingSectionGroups]: (state, action) => ({
    ...state,
    rankingSectionGroups: action.payload,
  }),

  [actions.removeSectionGroup]: (state, action) => ({
    ...state,
    selectedSectionGroups: state.selectedSectionGroups
      .filter((sectionGroup) => sectionGroup !== action.payload),
  }),

  [actions.addSectionGroup]: (state, action) => ({
    ...state,
    selectedSectionGroups: state.selectedSectionGroups.concat(action.payload),
  }),
}, defaultState);
