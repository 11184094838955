import { stateKeys } from '../../types';

export const getWellboreLists = (state) => state[stateKeys.MY_LISTS].wellboreLists;
export const getSelectedWellboreList = (state) => state[stateKeys.MY_LISTS].wellboreLists.find((x) => x.id === state[stateKeys.MY_LISTS].selectedWellboreListId);
export const getRecommendedWellbores = (state) => state[stateKeys.MY_LISTS].recommendedWellbores;

export const getDefaultWellboreList = (state) => state[stateKeys.MY_LISTS].wellboreLists[0];
export const getEditedWellboreList = (state) => state[stateKeys.MY_LISTS].wellboreLists.find((x) => x.id === state[stateKeys.MY_LISTS].editedWellboreListId);
export const getIsLoading = (state) => state[stateKeys.MY_LISTS].isLoading;
export const getWellboreListLoading = (state) => state[stateKeys.MY_LISTS].wellboreListLoading;
export const getRecommendedWellboresLoading = (state) => state[stateKeys.MY_LISTS].recommendedWellboresLoading;

export const isWellboreSaved = (state, uniqueWellboreIdentifier) => state[stateKeys.MY_LISTS].wellboreLists.some((list) => (list.wellbores && list.wellbores.some((wb) => wb.uniqueWellboreIdentifier === uniqueWellboreIdentifier)));
