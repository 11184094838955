import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// This is a basic component for all weighed ranking methods
export default class RankingInput extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    method: PropTypes.string.isRequired,
    availableValues: PropTypes.array.isRequired,
    disabledValues: PropTypes.array,
    selectedValues: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    isInputValid: PropTypes.bool.isRequired,
    inputErrorMessage: PropTypes.string.isRequired,
    optionLabelProperty: PropTypes.string,
    optionLabelFunction: PropTypes.func,
  };

  static defaultProps = {
    disabledValues: [],
  }

  handleChange = (event, values) => {
    this.props.setValues(this.props.type, this.props.method, values);
  }

  doesValueExistInArray = (value, array, propertyName) => {
    if (propertyName) {
      return array.filter((f) => f[propertyName] === value[propertyName]).length > 0;
    }

    return array.indexOf(value) !== -1;
  }

  isValueSelectable = (option) => {
    if (!option || option === '') return false;

    const {
      selectedValues, availableValues, disabledValues, type, method, optionLabelProperty,
    } = this.props;
    const available = this.doesValueExistInArray(option, availableValues, optionLabelProperty);
    const selected = this.doesValueExistInArray(option, selectedValues(type, method), optionLabelProperty);
    const disabled = this.doesValueExistInArray(option, disabledValues, optionLabelProperty);

    return available && !selected && !disabled;
  }

  render() {
    const {
      type,
      method,
      availableValues,
      selectedValues,
      label,
      placeholder,
      optionLabelProperty,
      optionLabelFunction,
      isInputValid,
      inputErrorMessage,
    } = this.props;

    return (
      <Autocomplete
        id={`rankingInput_${type}_${method}_text`}
        size="small"
        multiple
        options={availableValues}
        getOptionDisabled={(option) => !this.isValueSelectable(option)}
        getOptionLabel={(option) => (optionLabelProperty ? option[optionLabelProperty] : optionLabelFunction ? optionLabelFunction(option) : option)}
        value={selectedValues(type, method)}
        onChange={this.handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder={placeholder}
            fullWidth
            error={!isInputValid}
            helperText={inputErrorMessage}
          />
        )}
      />
    );
  }
}
