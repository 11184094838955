import React from 'react';

const SignedOutPage = (props) => (
  <div className="container-fluid" style={{ paddingTop: '100px' }}>
    <div className="jumbotron" style={{ marginTop: 16, textAlign: 'center' }}>
      <h3>You are signed out of DIWA Reference Wellbores application.</h3>
    </div>
    {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
      <button className="btn btn-primary" onClick={} style={{ marginLeft: '15px' }}>
        Sign in
        </button>
    </div> */}
  </div>
);

export default SignedOutPage;
