import { PublicClientApplication, InteractionRequiredAuthError, BrowserAuthError } from '@azure/msal-browser';
import config from '../../settings';
import store from '../../store/config';
import { loginSucceeded, loginFailed } from '../../store/auth/authActions';
import { extractErrorMessage } from '../../common/errorUtils';
import { userRoles } from '../../types';

const DIWA_INTERNAL_ROLE = 'diwa_internal';

export class LoginInProgressError extends Error {
}

class UserIdentity {
  constructor(userId, userName, roles) {
    this.userId = userId;
    this.userName = userName;
    this.roles = roles;
  }
}

export const LoginErrorMessage = 'An error happened during login. ';

export const getLoginErrorMessage = (e) => LoginErrorMessage + extractErrorMessage(e);

export const RESOURCE_NAME_MAIN = 'DIWA';
export const RESOURCE_NAME_GRAPH = 'Graph';
export const AUTH_PARAM_NAME = 'auth';
export const AUTH_PARAM_VALUE_POPUP = 'popup';

export class AuthService {
  constructor() {
    this.popupAuth = new URLSearchParams(window.location.search).get(AUTH_PARAM_NAME) === AUTH_PARAM_VALUE_POPUP;

    this.appHostName = `${window.location.protocol}//${window.location.hostname}${window.location.port === '80' ? '' : `:${window.location.port}`}`;
    this.redirectUri = `${this.appHostName}${'/loginpage/'}`;

    this.msalConfig = {
      auth: {
        authority: `https://login.microsoftonline.com/${config.AzureADTenantId}`,
        clientId: config.AzureADClientId,
        redirectUri: this.redirectUri,
        postLogoutRedirectUri: '', // appHostName + "/loggedout.html"
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      },
    };

    this.msalClient = new PublicClientApplication(this.msalConfig);
  }

  msalClient;

  msalConfig;

  popupAuth;

  appHostName;

  isPopupAuth = () => this.popupAuth;

  async ensureUserLoggedIn() {
    if (!this.isLoggedIn()) {
      const tokenResponse = await this.handleRedirect();
      const isAuthRedirect = tokenResponse !== null;

      if (!isAuthRedirect) {
        await this.loginUser(window.location.href);
      }
    }

    const account = this.getAccount();
    this.authorize(account);
  }

  async handleRedirect() {
    try {
      const tokenResponse = await this.msalClient.handleRedirectPromise();
      return tokenResponse;
    } catch (error) {
      if (this.isAadRoleMissing(error)) {
        this.redirectUnauthorized();
      }

      store.dispatch(loginFailed(getLoginErrorMessage(error)));
      throw new Error(extractErrorMessage(error));
    }
  }

  isAadRoleMissing = (error) => error instanceof InteractionRequiredAuthError
    && (error.errorCode === 'interaction_required' || error.errorCode === 'invalid_grant')
    && error.errorMessage.includes('AADSTS50105');

  authorize(account) {
    if (account === null) {
      throw new Error('User is not logged (Account is null), cannot authorize.');
    }

    const loggedInUser = this.mapAccountToUserIdentity(account);
    if (!loggedInUser.roles.some((value) => value === DIWA_INTERNAL_ROLE)) {
      this.redirectUnauthorized();
    }
    this.msalClient.setActiveAccount(account);
    store.dispatch(loginSucceeded({ userId: loggedInUser.userId, role: this.extractRole(loggedInUser.roles) }));
  }

  mapAccountToUserIdentity(account) {
    // this type conversion is intentional as roles are array of instead of
    if (account === null) {
      throw new Error('Account is null.');
    }

    if (account.idTokenClaims === undefined) {
      throw new Error('idTokenClaims is undefined.');
    }

    if (account.name === undefined) {
      throw new Error('User id (account.name) is empty.');
    }

    return new UserIdentity(account.username, account.name, account.idTokenClaims.roles);
  }

  extractRole(roles) {
    if (roles && roles.length > 0) {
      if (roles.indexOf(userRoles.DIWA_DEVELOPER) !== -1) return userRoles.DIWA_DEVELOPER;

      if (roles.indexOf(userRoles.DIWA_INTERNAL) !== -1) return userRoles.DIWA_INTERNAL;

      if (roles.indexOf(userRoles.DIWA_EXTERNAL) !== -1) return userRoles.DIWA_EXTERNAL;
    }

    return userRoles.UNKNOWN;
  }

  redirectUnauthorized() {
    if (!window.location.href.includes('/unauthorized')) {
      window.location.href = `/unauthorized${this.getPopupAuthSearchParameter()}`;
    }
  }

  getPopupAuthSearchParameter= () => (this.isPopupAuth() ? `?${AUTH_PARAM_NAME}=${AUTH_PARAM_VALUE_POPUP}` : '');

  isLoggedIn = () => {
    const accounts = this.msalClient.getAllAccounts();
    return accounts !== null && accounts.length > 0;
  }

  getAccount = () => (this.isLoggedIn() ? this.msalClient.getAllAccounts()[0] : null);

  async loginUser(redirectUrl = '', afterLoginPopup = null) {
    if (this.isLoggedIn()) {
      // authorize will dispatch loginSucceeded to store
      this.authorize(this.getAccount());
      return;
    }

    const loginRequest = {
      scopes: [],
    };

    if (this.popupAuth) {
      try {
        const response = await this.msalClient.loginPopup(loginRequest);
        this.authorize(response.account);
        if (afterLoginPopup) {
          afterLoginPopup(`/${this.getPopupAuthSearchParameter()}`);
        }
      } catch (error) {
        store.dispatch(loginFailed(getLoginErrorMessage(error)));

        if (error instanceof BrowserAuthError && error.errorCode === 'popup_window_error') {
          throw Error('Error opening popup window. Unblock popup using the icon in the address bar.');
        } else if (this.isAadRoleMissing(error)) {
          this.redirectUnauthorized();
        } else {
          throw error;
        }
      }
    } else {
      try {
        const defaultedRedirectUrl = this.appHostName;
        const loginRedirectRequest = {
          ...loginRequest,
          redirectUri: defaultedRedirectUrl,
        };

        await this.msalClient.loginRedirect(loginRedirectRequest);
      } catch (err) {
        store.dispatch(loginFailed(getLoginErrorMessage(err)));
        throw err;
      }
    }
  }

  async logout() {
    if (this.popupAuth) {
      const redirectUrl = `${this.appHostName}/signedout${this.getPopupAuthSearchParameter()}`;
      await this.msalClient.logoutPopup({
        postLogoutRedirectUri: redirectUrl,
        mainWindowRedirectUri: redirectUrl,
      });
    } else {
      await this.msalClient.logoutRedirect();
    }
  }

  getResourceScope(resource) {
    switch (resource) {
      case RESOURCE_NAME_MAIN: return `${this.msalConfig.auth.clientId}/.default`;
      case RESOURCE_NAME_GRAPH: return 'https://graph.microsoft.com/.default';
      default: throw new Error('Invalid resource name');
    }
  }

  async acquireAccessToken(resource) {
    if (!this.isLoggedIn()) {
      await this.loginUser();
    }

    // Documentation: see last bullet point here https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-compare-msal-js-and-adal-js#scopes-for-acquiring-tokens

    const scope = this.getResourceScope(resource);
    const tokenRequest = {
      scopes: [scope],
    };

    try {
      const response = await this.msalClient.acquireTokenSilent(tokenRequest);
      return response.accessToken;
    } catch (err) {
      if (err instanceof InteractionRequiredAuthError) {
        if (this.popupAuth) {
          const response = await this.msalClient.acquireTokenPopup(tokenRequest);
          this.authorize(response.account);
          return response.accessToken;
        }
        await this.msalClient.acquireTokenRedirect(tokenRequest);
      }

      store.dispatch(loginFailed(getLoginErrorMessage(err)));
      throw err;
    }
  }
}

let authService = null;

export const getAuthServiceSingleton = () => {
  if (authService == null) {
    authService = new AuthService();
  }

  return authService;
};
