// send telemetry to app insights documentation: https://docs.microsoft.com/en-us/azure/azure-monitor/app/usage-overview#send-telemetry-from-your-app
// we do not execute the app insights initialization immediatelly as we receive the Instrumetation Key with a call to the server

let appInsightsDepartment = null;

export const initAppInsights = (instrumentationKey, department) => {
  appInsightsDepartment = department;
  initInsights(window, document, {
    src: 'https://az416426.vo.msecnd.net/scripts/b/ai.2.min.js', // The SDK URL Source
    // name: "appInsights", // Global SDK Instance name defaults to "appInsights" when not supplied
    // ld: 0, // Defines the load delay (in ms) before attempting to load the sdk. -1 = block page load and add to head. (default) = 0ms load after timeout,
    // useXhr: 1, // Use XHR instead of fetch to report failures (if available),
    // crossOrigin: "anonymous", // When supplied this will add the provided value as the cross origin attribute on the script tag
    cfg: { // Application Insights Configuration
      instrumentationKey,
    },
  });

  const telemetryInitializer = (envelope) => {
    const authCodeRedirectUrlRegex = /#code=/;
    const isAuthCodeRedirectUrl = envelope && envelope.baseData && envelope.baseData.uri && authCodeRedirectUrlRegex.test(envelope.baseData.uri);

    // prevent sending any app insights logs which contains authorization code in URI data
    return !isAuthCodeRedirectUrl;
  };
  appInsights.addTelemetryInitializer(telemetryInitializer);
};

export const isAppInsightsInitialized = () => window.hasOwnProperty('appInsights');

// see documentation for custom app insights events:
// https://docs.microsoft.com/en-us/azure/azure-monitor/app/usage-overview#custom-business-events
export const appInsightsTrackEvent = (event) => {
  appInsights.trackEvent({ name: event.code, properties: event.params }, { department: appInsightsDepartment });
};

export const appInsightsLogError = (err) => {
  if (isAppInsightsInitialized()) {
    appInsights.trackException({ exception: err, severityLevel: 3 }, { department: appInsightsDepartment });
  } else {
    console.error('App insights is not initialized.');
  }
};
export const DIWA_RANK_QUERY = 'DIWA_RANK_QUERY';
