import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import ConfirmModal from '../common/ConfirmModal';
import './MyListItem.css';

class MyListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listRenameValue: this.props.wellboreList.name,
    };
    this.listRenameInputRef = React.createRef();
  }

  componentDidMount = () => {
    this.focusEditorIfEdited();
  }

  componentDidUpdate = () => {
    this.focusEditorIfEdited();
  }

  focusEditorIfEdited = () => {
    if (this.props.isEdited) {
      this.listRenameInputRef.current.focus();
      if (this.state.listRenameValue === this.props.wellboreList.name) {
        this.listRenameInputRef.current.select();
      }
    }
  }

  handleClick = () => {
    if (!this.props.isSelected) {
      this.props.onClick(this.props.wellboreList);
    }
  }

  handleStartRename = () => {
    this.props.onEditedStateChange(this.props.wellboreList, true);
  }

  handleFinishRename = () => {
    this.props.onRename(this.props.wellboreList, this.state.listRenameValue);
    this.props.onEditedStateChange(this.props.wellboreList, false);
  }

  handleCancelRename = () => {
    this.setState({ listRenameValue: this.props.wellboreList.name });
    this.props.onEditedStateChange(this.props.wellboreList, false);
  }

  handleWellboreNameChangeKeyPressed = (e) => {
    // handle enter and esc keys while editing list name
    if (e.which === 13) {
      this.handleFinishRename();
      return false;
    }

    if (e.keyCode === 27) {
      this.handleCancelRename();
      return false;
    }

    return true;
  }

  handleRemove = () => {
    const modalConfig = {
      title: 'Delete list',
      message: 'Do you really want to delete list?',
      onOK: this.handleRemoveWellboreListConfirmed,
    };

    ConfirmModal.show(modalConfig);
  }

  handleRemoveWellboreListConfirmed = () => {
    this.props.onRemove(this.props.wellboreList);
  }

  handleWellboreNameChange = (e) => {
    this.setState({ listRenameValue: e.target.value });
  }

  handleDrop = (event) => {
    event.preventDefault();
    const dropInfo = JSON.parse(event.dataTransfer.getData('text'));
    this.props.onMoveWellbore(
      dropInfo.wellboreListId,
      this.props.wellboreList.id,
      dropInfo.uniqueWellboreIdentifier,
    );
  }

  handleDragOver(ev) {
    ev.preventDefault();
  }

  render() {
    const wellboreCountInList = this.props.wellboreList.wellbores
      ? this.props.wellboreList.wellbores.length
      : this.props.wellboreList.wellboreCount;

    return (
      <div className="row">
        <div id="wellboreListContainerCol" className="col">
          <button
            className={`wellboreListContainer align-items-center${this.props.isSelected ? ' activeWellboreListContainer' : ''}`}
            onDrop={(event) => this.handleDrop(event)}
            onDragOver={() => this.handleDragOver(event)}
            onClick={this.handleClick}
          >
            <div className="wellboreListLabel">
              {this.props.isEdited
                ? (
                  <input
                    className="wellboreListLabel"
                    type="text"
                    value={this.state.listRenameValue}
                    onChange={this.handleWellboreNameChange}
                    onKeyUp={this.handleWellboreNameChangeKeyPressed}
                    onBlur={this.handleFinishRename}
                    ref={this.listRenameInputRef}
                  />
                )
                : this.props.wellboreList.name}
            </div>
            <span className="badge badge-primary badge-pill ">{wellboreCountInList}</span>
            <div className="dropdown">
              <div className="btn" data-toggle="dropdown">
                <FontAwesomeIcon icon="ellipsis-v" />
                {' '}
              </div>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                <div className="dropdown-item btn" onClick={this.handleStartRename}>Rename</div>
                <div className="dropdown-item btn" onClick={this.handleRemove}>Delete</div>
              </div>
            </div>
          </button>
        </div>
      </div>
    );
  }
}

MyListItem.propTypes = {
  wellboreList: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isEdited: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onEditedStateChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onMoveWellbore: PropTypes.func.isRequired,
};

export default MyListItem;
