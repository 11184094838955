import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ApplyFilters } from '../filters/FilterUtils';
import WellboreInfo from './WellboreInfo';
import './style.css';

export class SearchResults extends Component {
  static propTypes = {
    results: PropTypes.array.isRequired,
    filters: PropTypes.array.isRequired,
    maxDisplayed: PropTypes.number.isRequired,
    setFilteredListSize: PropTypes.func.isRequired,
    isWellPathSelected: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      filteredResults: [],
    };
  }

  componentDidMount = () => {
    this.updateResults();
  }

  componentDidUpdate = (prevProps) => {
    if (JSON.stringify(this.props.filters) !== JSON.stringify(prevProps.filters) || this.props.maxDisplayed !== prevProps.maxDisplayed) {
      this.updateResults();
    }
  }

  handleSelectWellPath = (event) => this.props.updateSelectedWellPaths(event.target.name, event.target.checked);

  updateResults = () => {
    const {
      results, filters, setFilteredListSize, maxDisplayed,
    } = this.props;
    const filteredResultList = _.orderBy(ApplyFilters(results, filters), 'rank', 'desc');

    setFilteredListSize(filteredResultList.length);
    this.setState({ filteredResults: filteredResultList.slice(0, maxDisplayed) });
  }

  render() {
    const {
      sectionRanking,
      isDeveloper,
      isWellPathSelected,
    } = this.props;

    return (
      <div>
        {this.state.filteredResults.map((r) => (
          <div key={r.uniqueResultIdentifier} className="row">
            <div className="col-sm-1">
              <div className="custom-control custom-checkbox wellline-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  onChange={this.handleSelectWellPath}
                  name={r.uniqueWellboreIdentifier}
                  id={r.uniqueWellboreIdentifier}
                  checked={isWellPathSelected(r.uniqueWellboreIdentifier)}
                />
                <label className="custom-control-label" htmlFor={r.uniqueWellboreIdentifier}>{isDeveloper ? r.resultNumber : ''}</label>
              </div>
            </div>
            <div className="col-sm-11" style={{ marginBottom: '10px' }}>
              <WellboreInfo
                {...r} // TODO better to pass r as an object
                sourceWellPathName={this.props.sourceWellPathName}
                sourceWellPathCoordinates={this.props.sourceWellPathCoordinates}
                sectionRanking={sectionRanking}
                isDeveloper={isDeveloper}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default SearchResults;
