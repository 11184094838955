const CrossProduct = (a, b) => [
  (a[1] * b[2]) - (a[2] * b[1]),
  (a[2] * b[0]) - (a[0] * b[2]),
  (a[0] * b[1]) - (a[1] * b[0]),
];

const GetNormVector = (v) => v.map((x) => x ** 2).reduce((res, current) => res + current) ** 0.5;

const NormVector = (v) => {
  const norm = GetNormVector(v);
  return [v[0] / norm, v[1] / norm, v[2] / norm];
};

const linspace = (startValue, stopValue, cardinality) => {
  const arr = [];
  const step = (stopValue - startValue) / (cardinality - 1);
  for (let i = 0; i < cardinality; i += 1) {
    arr.push(startValue + (step * i));
  }
  return arr;
};

const MapCylinderToCartesianCoordinates = (tMeshed, thetaMeshed, R, resolution, n1, n2, p0, v) => {
  const cartesianCoordinates = [];
  for (let coordinate = 0; coordinate < 3; coordinate += 1) {
    const axis = [];
    for (let i = 0; i < resolution; i += 1) {
      const coordinateBuffer = [];
      for (let j = 0; j < resolution; j += 1) {
        coordinateBuffer.push(
          p0[coordinate]
          + (v[coordinate] * tMeshed[i][j])
          + (R * Math.sin(thetaMeshed[i][j]) * n1[coordinate])
          + (R * Math.cos(thetaMeshed[i][j]) * n2[coordinate]),
        );
      }
      axis.push(coordinateBuffer);
    }
    cartesianCoordinates.push(axis);
  }
  return cartesianCoordinates;
};

const ComputeCylinderCoordinates = (x, y, z, start, end, r) => {
  const R = r * 10;
  const resolution = 10;
  const p0 = [x[start], y[start], z[start]];
  const p1 = [x[end], y[end], z[end]];
  let v = [p1[0] - p0[0], p1[1] - p0[1], p1[2] - p0[2]];
  const mag = GetNormVector(v);
  v = NormVector(v);
  let notV = [1, 0, 0];
  if (v[0] === notV[0] && v[1] === notV[1] && v[2] === notV[2]) {
    notV = [0, 1, 0];
  }
  const n1 = NormVector(CrossProduct(v, notV));
  const n2 = NormVector(CrossProduct(v, n1));
  const t = linspace(0, mag, resolution);
  const theta = linspace(0, 2 * Math.PI, resolution);
  const tMeshed = [];
  const thetaMeshed = [];
  for (let i = 0; i < resolution; i += 1) {
    tMeshed.push(t);
    thetaMeshed.push(Array(resolution).fill(1).map(() => theta[i]));
  }
  const [X, Y, Z] = MapCylinderToCartesianCoordinates(tMeshed, thetaMeshed, R, resolution, n1, n2, p0, v);
  return [X, Y, Z];
};

export const ComputeMultipleCylinderCoordinates = (x, y, z, start, end, r) => {
  const resolution = 5;
  if (end - start <= resolution) {
    return ComputeCylinderCoordinates(x, y, z, start, end, r);
  }
  let X = [];
  let Y = [];
  let Z = [];
  let i = start;
  do {
    const startTemp = i;
    let endTemp = i + resolution < end ? i + resolution : end;
    endTemp = endTemp + Math.floor(resolution / 2) <= end ? endTemp + Math.floor(resolution / 2) : endTemp;
    const [XTemp, YTemp, ZTemp] = ComputeCylinderCoordinates(x, y, z, startTemp, endTemp, r);
    X = X.concat(XTemp);
    Y = Y.concat(YTemp);
    Z = Z.concat(ZTemp);
    i += resolution;
  } while (i < end);
  return [X, Y, Z];
};
