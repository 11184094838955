import { getResource } from '../../settings';
import { NetworkException } from '../../utils/Exception';
import { getAuthServiceSingleton } from '../adal/authService';

export const config = {
  defaultResource: 'DIWA',
  jsonHeaders: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const createUrl = (resource, path) => `${getResource(resource).ApiBaseUrl}${path}`;

// Helper functions
export const fetchData = (path, resource = config.defaultResource) => {
  const authService = getAuthServiceSingleton();
  return authService.acquireAccessToken(resource)
    .then((accessToken) => fetch(createUrl(resource, path), {
      method: 'GET',
      withCredentials: true,
      headers: {
        ...config.jsonHeaders,
        Authorization: `Bearer ${accessToken}`,
      },
    }))
    .then((response) => {
      if (response.ok) {
        return response.json().then((data) => ({ status: response.status, body: data }));
      }
      throw new NetworkException(response.statusText, response.status);
    });
};

export const submitData = (path, data, method = 'POST', resource = config.defaultResource) => {
  const authService = getAuthServiceSingleton();
  return authService.acquireAccessToken(resource)
    .then((accessToken) => fetch(createUrl(resource, path), {
      method,
      body: JSON.stringify(data),
      withCredentials: true,
      headers: {
        ...config.jsonHeaders,
        Authorization: `Bearer ${accessToken}`,
      },
    }))
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new NetworkException(response.statusText, response.status);
    });
};

export const submitFeedback = (path, data, filesfeedback, method = 'POST', resource = config.defaultResource) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(data));
  for (let index = 0; index < filesfeedback.length; index += 1) {
    formData.append('file', filesfeedback[index]);
  }

  const authService = getAuthServiceSingleton();
  return authService.acquireAccessToken(resource)
    .then((accessToken) => fetch(createUrl(resource, path), {
      method,
      body: formData,
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }))
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new NetworkException(response.statusText, response.status);
    });
};
