import NumberFormat from 'react-number-format';
import React from 'react';
import PropTypes from 'prop-types';

export const formatDepth = (numberValue) => {
  if (typeof numberValue !== undefined && numberValue !== null) {
    return numberValue.toFixed(1);
  }

  return '...';
};

export function round(value, precision) {
  const multiplier = 10 ** precision;
  return Math.round(value * multiplier) / multiplier;
}

export function NumberFormatPositiveDecimal(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      prefix=""
      fixedDecimalScale
      allowNegative={false}
      allowEmptyFormatting="true"
    />
  );
}

NumberFormatPositiveDecimal.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
