import { handleActions } from 'redux-actions';
import * as actions from './generalActions';
import { targetDivs, rankingTypes } from '../../types';

const defaultState = {
  elementVisibility: {
    [targetDivs.FILTERS_PANEL]: false,
    [targetDivs.WELLBORE_RANKING_PANEL]: true,
    [targetDivs.SECTION_RANKING_PANEL]: true,
    [targetDivs.SELECT_WELLBORE]: false,
  },
  linkedWellboreId: '',
  rankingType: rankingTypes.WELLBORE_RANKING,
  selectedResultGroup: '',
};

export default handleActions({
  [actions.toggleElementVisibility]: (state, action) => ({
    ...state,
    elementVisibility: {
      ...state.elementVisibility,
      [action.payload]: !state.elementVisibility[action.payload],
    },
  }),

  [actions.setLinkedWellboreId]: (state, action) => ({
    ...state,
    linkedWellboreId: action.payload,
  }),

  [actions.setRankingType]: (state, action) => ({
    ...state,
    rankingType: action.payload,
  }),

  [actions.setSelectedResultGroup]: (state, action) => ({
    ...state,
    selectedResultGroup: action.payload,
  }),
}, defaultState);
