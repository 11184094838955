import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Tooltip } from '@material-ui/core';
import Error from '@material-ui/icons/Error';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';

const elementIdPrefix = 'rankingInputBox_';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      prefix=""
      decimalScale={1}
      fixedDecimalScale
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

// This is a basic component for all weighed ranking methods
export default class RankingInputBox extends Component {
  constructor(props) {
    super(props);

    if (this.props.modifyValueProperty && !this.props.propertyForSelectionId) {
      const message = 'when modifyValueProperty is defined then '
        + 'the propertyForSelectionId must be defined too.';
      throw Error(message);
    }

    this.state = {
      value: '',
      input: '',
      defaultView: !props.children,
      elementId: `${elementIdPrefix + props.type}_${props.method}_`,
      selectedSections: {},
      inputValue: '',
    };
  }

  handleSelectValue = (event, value) => {
    if (!value) return;

    const {
      type,
      method,
      handleSelectValue,
      propertyForOptionLabel,
      propertiesForColumnValues,
    } = this.props;

    if (propertyForOptionLabel && propertiesForColumnValues) {
      const complexSelection = JSON.parse(JSON.stringify(value));
      // let max = 1;

      // propertiesForColumnValues.forEach((prop) => {
      //   complexSelection[prop] = Math.ceil(Math.random() * 10000) + max;
      //   max = complexSelection[prop];
      // });

      propertiesForColumnValues.forEach((prop) => { complexSelection[prop] = null; });

      handleSelectValue(type, method, complexSelection);
    } else {
      handleSelectValue(type, method, value);
    }

    // empty the autocomplet input field after selection was processed
    this.setState({ value: '' });
  }

  isValueSelectable = (option) => {
    if (!option) return false;

    const {
      maximumSelected, selectedValues, type, method, propertyForOptionLabel,
    } = this.props;

    return !(maximumSelected && selectedValues(type, method).length >= maximumSelected)
      && (this.props.allowSelectMultipleTimes || (propertyForOptionLabel
        ? selectedValues(type, method).filter((v) => v[propertyForOptionLabel] === option[propertyForOptionLabel]).length === 0
        : selectedValues(type, method).indexOf(option) === -1));
  }

  handleColumnValueChange = (uiId, propertyName, value) => {
    const parsedValue = parseFloat(value);
    const newValue = Number.isFinite(parsedValue) ? parsedValue : null;
    this.props.modifyValueProperty(
      this.props.type,
      this.props.method,
      uiId,
      propertyName,
      newValue,
    );
  }

  handleEnter = (event) => {
    if (event.key === 'Enter') this.setState({ value: '' });
  }

  handleInputChange = (event, value, reason) => {
    const {
      type,
      method,
      handleClearSelectedValues,
      selectedValues,
    } = this.props;

    if (reason === 'reset') this.setState({ input: '' });
    if (reason === 'input') this.setState({ input: value });

    if (
      reason === 'clear'
      && handleClearSelectedValues !== null
      && selectedValues
      && selectedValues(type, method).length > 0
    ) {
      handleClearSelectedValues();
    }
  }

  render() {
    const {
      title,
      type,
      method,
      propertyForOptionLabel,
      propertyForSelectionId,
      propertyForGroupBy,
      handleRemoveValue,
      availableValues,
      selectedValues,
      orderSelectedValuesBy,
      orderType,
      headers,
      subHeaders,
      propertiesForColumnValues,
      children,
      isInputValid,
      inputErrorMessage,
    } = this.props;

    let userInput = [];
    if (selectedValues && type && method) {
      userInput = selectedValues(type, method);
      if (orderSelectedValuesBy && orderType) {
        userInput = _.orderBy(userInput, [orderSelectedValuesBy], [orderType]);
      }
    }

    const darkTheme = createTheme({
      palette: {
        type: 'dark',
      },
      typography: {
        fontSize: 12,
      },
    });

    const getDistinctGroupNames = () => [...new Set(availableValues.map((x) => x[propertyForGroupBy]))];

    const getUserInputByGroup = (group) => userInput.filter((x) => x[propertyForGroupBy] === group);

    const elementButtonHeader = (group) => (<div key={`${type}_${method}_group_${group}`} className="rankingInputBoxGroupTitle">{`${group}`}</div>);

    const elementButton = (el) => {
      const name = (propertyForOptionLabel) ? el[propertyForOptionLabel] : el;
      return (
        <span key={`${type}_${method}_${name}`} className="badge badge-dark">
          {`${name} `}
          <button
            type="button"
            className="btn"
            style={{ paddingBottom: 0, paddingTop: 0 }}
            onClick={() => handleRemoveValue(type, method, el)}
            aria-label="Close"
          >
            <span aria-hidden="true" className="text-light">&times;</span>
          </button>
        </span>
      );
    };

    return (
      <span
        className="border border-light rounded p-1"
        style={{ width: '-webkit-fill-available' }}
      >
        {children || (
        <div>
          <div>
            <Autocomplete
              id={`rankingInputList_${type}_${method}`}
              options={availableValues}
              getOptionLabel={(option) => {
                if (propertyForOptionLabel && option[propertyForOptionLabel]) {
                  return option[propertyForOptionLabel];
                }
                return option;
              }}
              groupBy={(propertyForGroupBy) ? (option) => option[propertyForGroupBy] : null}
              onChange={this.handleSelectValue}
              value={this.state.value}
              onInputChange={this.handleInputChange}
              inputValue={this.state.input}
              clearOnEscape
              onKeyDown={this.handleEnter}
              size="small"
              getOptionDisabled={(option) => !this.isValueSelectable(option)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label={title} fullWidth error={!isInputValid} helperText={inputErrorMessage} />
              )}
            />
          </div>
            {(headers && userInput && userInput.length > 0) ? (
              <ThemeProvider theme={darkTheme}>
                <div className="card bg-dark">
                  <table className="table table-sm table-dark">
                    <tbody>
                      <tr>
                        {headers.map((header) => {
                          if (subHeaders) {
                            if (subHeaders[header] && subHeaders[header].length > 0) {
                              // eslint-disable-next-line max-len
                              return <th key={header} colSpan={subHeaders[header].length} scope="colgroup">{header}</th>;
                            }
                            return <th key={header} rowSpan="2" scope="rowgroup">{header}</th>;
                          }
                          return <th key={header} scope="col">{header}</th>;
                        })}
                      </tr>
                      {subHeaders && (
                      <tr>
                        {headers.map((header) => {
                          if (subHeaders[header]) {
                            return subHeaders[header].map(
                              (subHeader) => <td key={`${header}_${subHeader}`}>{subHeader}</td>,
                            );
                          }
                          return [];
                        })}
                      </tr>
                      )}
                      {userInput.map((el) => {
                        const name = propertyForOptionLabel ? el[propertyForOptionLabel] : el;
                        const uiId = propertyForSelectionId ? el[propertyForSelectionId] : el;
                        const idPassedToHandler = propertyForSelectionId ? uiId : name;

                        const isInputRowValid = el.isValid === undefined || el.isValid;
                        const inputRowErrorMessage = !isInputRowValid ? el.errorMessage : '';

                        return (
                          <tr key={`${type}_${method}_${idPassedToHandler}`}>
                            <th scope="row" style={{ whiteSpace: 'nowrap' }}>
                              {!isInputRowValid
                      && (
                      <Tooltip title={inputRowErrorMessage} aria-label={inputRowErrorMessage} placement="bottom-start">
                        <span style={{ color: 'red' }}><Error /></span>
                      </Tooltip>
                      )}
                              <span style={{ fontSize: 12 }}>{name}</span>
                            </th>
                            {propertiesForColumnValues && propertiesForColumnValues.map((propertyName) => {
                              const isPropertyValid = el[`${propertyName}IsValid`];
                              const propertyErrorMessage = !isPropertyValid ? el[`${propertyName}ErrorMessage`] : '';
                              const propertyValue = `${el[propertyName] !== null ? el[propertyName] : ''}`;

                              return (
                                <td key={`${type}_${method}_${idPassedToHandler}_${propertyName}`}>
                                  <Tooltip title={propertyErrorMessage} aria-label={propertyErrorMessage} placement="bottom-start">
                                    <TextField
                                      error={!isPropertyValid}
                                      value={propertyValue}
                                      onChange={(event) => this.handleColumnValueChange(
                                        idPassedToHandler,
                                        propertyName,
                                        event.target.value,
                                      )}
                                      InputProps={{
                                        inputComponent: NumberFormatCustom,
                                      }}
                                    />
                                  </Tooltip>
                                </td>
                              );
                            })}
                            <td>
                              <button
                                type="button"
                                className="btn btn-dark"
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                onClick={() => handleRemoveValue(type, method, el)}
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </ThemeProvider>
            ) : null}
            {(!headers && userInput && userInput.length > 0) ? (
              <div>
                {((propertyForGroupBy && userInput[0][propertyForGroupBy] && availableValues && availableValues[0][propertyForGroupBy])
                  ? getDistinctGroupNames()
                    .map((group) => ({
                      header: elementButtonHeader(group),
                      elements: getUserInputByGroup(group).map((x) => elementButton(x)),
                    }))
                    .filter((x) => x.elements.length)
                    .flatMap((x) => [x.header].concat(x.elements))
                  : userInput.map((x) => elementButton(x)))}
              </div>
            ) : null}
        </div>
        )}
      </span>
    );
  }
}

RankingInputBox.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  method: PropTypes.string,
  allowSelectMultipleTimes: PropTypes.bool,
  propertyForOptionLabel: PropTypes.string,
  propertyForSelectionId: PropTypes.string,
  propertyForGroupBy: PropTypes.string,
  handleSelectValue: PropTypes.func,
  handleRemoveValue: PropTypes.func,
  handleClearSelectedValues: PropTypes.func,
  modifyValueProperty: PropTypes.func,
  availableValues: PropTypes.array,
  selectedValues: PropTypes.func,
  orderSelectedValuesBy: PropTypes.string,
  orderType: PropTypes.string,
  headers: PropTypes.array,
  subHeaders: PropTypes.object,
  propertiesForColumnValues: PropTypes.array,
  children: PropTypes.object,
  maximumSelected: PropTypes.number,
  isInputValid: PropTypes.bool,
  inputErrorMessage: PropTypes.string,
};

RankingInputBox.defaultProps = {
  type: '',
  method: '',
  allowSelectMultipleTimes: false,
  propertyForOptionLabel: '',
  propertyForSelectionId: '',
  propertyForGroupBy: '',
  handleSelectValue: null,
  handleRemoveValue: null,
  handleClearSelectedValues: null,
  modifyValueProperty: null,
  availableValues: null,
  selectedValues: null,
  orderSelectedValuesBy: '',
  orderType: '',
  headers: null,
  subHeaders: null,
  propertiesForColumnValues: null,
  children: null,
  maximumSelected: null,
  isInputValid: true,
  inputErrorMessage: '',
};
