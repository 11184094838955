export const authStatusTypes = {
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
  AUTHENTICATING: 'NOT_AUTHENTICATED',
  AUTHENTICATED: 'AUTHENTICATED',
  FAILED: 'FAILED',
  SIGNED_OUT: 'SIGNED_OUT',
};

export const userRoles = {
  UNKNOWN: 'unknown',
  DIWA_INTERNAL: 'diwa_internal',
  DIWA_EXTERNAL: 'diwa_external',
  DIWA_DEVELOPER: 'diwa_developer',
};

export const requestTypes = {
  NONE: 'NONE',
  REQUESTED: 'REQUESTED',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
};

export const stateKeys = {
  AUTH: 'auth',
  CONNECTIVITY: 'connectivity',
  VERSION: 'version',
  MANIFEST: 'schemaVersion',
  CHANGELOG: 'releaseNote',
  NAV: 'router',
  PEOPLE: 'people',
  SEARCH: 'search',
  FILTERS: 'filters',
  GENERAL: 'general',
  MY_LISTS: 'myLists',
  GUIDES: 'guides',
  FEEDBACK: 'feedback',
};

export const filterConstants = {
  DEFAULT_MIN_DEPTH: 0,
  DEFAULT_MAX_DEPTH: 15000,
  FILTER_FIELD: 'Field',
  FILTER_FORMATION: 'Formation',
  FILTER_SECTION_DRILLING: 'Drilling section',
  FILTER_SECTION_COMPLETION: 'Completion section',
  FILTER_SECTION_HOLE: 'Hole section',
  FILTER_KEYWORD: 'Keyword',
  FILTER_RIG: 'Rig',
  FILTER_SECTION_GROUP: 'Section group',
  DEFAULT_MINIMUM_SCORE: 1,
};

export const rankingConstants = {
  PREFIX_LINKED_WELLBORE: 'linked_wellbore|',
};

export const sectionTypes = {
  DRILLING: 'DRILLING',
  COMPLETION: 'COMPLETION',
  HOLE: 'HOLE',
};

export const rankingTypes = {
  WELLBORE_RANKING: 'WELLBORE',
  SECTION_RANKING: 'SECTION',
};

export const rankingSectionGroupConstants = {
  LOWER_COMPLETION: {
    displayName: 'Lower Completion',
    value: 'Lower Completion'
  },
  UPPER_COMPLETION: {
    displayName: 'Upper Completion',
    value: 'Upper Completion'
  },
  PREPARE_SIDETRACK: {
    displayName: 'Prepare Sidetrack',
    value: 'Prep. sidetrack'
  },
};

export const getDisplayNameForSectionGroup = (sectionGroupName) => {
  for (const [sectionGroupTitle, sectionGroupProps] of Object.entries(rankingSectionGroupConstants)) {
    if(sectionGroupProps.value === sectionGroupName){
      return sectionGroupProps.displayName;
    }
  }

  return sectionGroupName;
}

export const rankingMethods = {
  GEOMETRIC: 'GEOMETRIC',
  GEOMETRIC_DISPLAY_NAME: 'Geometric',
  GEOMETRIC_UI: 'Rank wellbores using wellpath',

  FORMATION: 'FORMATION',
  FORMATION_DISPLAY_NAME: 'Formation',
  FORMATION_UI: 'Rank wellbores using selected formation(s)',

  DRILLINGSECTION: 'DRILLINGSECTION',
  DRILLINGSECTION_DISPLAY_NAME: 'Drilling Section',
  DRILLINGSECTION_UI: 'Rank wellbores using selected drilling section(s)',

  COMPLETIONSECTION: 'COMPLETIONSECTION',
  COMPLETIONSECTION_DISPLAY_NAME: 'Completion Section',
  COMPLETIONSECTION_UI: 'Rank wellbores using selected completion section(s)',

  HOLESECTION: 'HOLESECTION',
  HOLESECTION_DISPLAY_NAME: 'Hole Section',
  HOLESECTION_UI: 'Rank wellbores using selected hole section(s)',

  KEYWORD: 'KEYWORD',
  KEYWORD_DISPLAY_NAME: 'Keyword',
  KEYWORD_UI: 'Rank wellbores using selected keyword(s)',

  OTHER_SECTION: 'OTHER_SECTION',
  TUBING: 'TUBING',
  CASING_EXIT_SIZE: 'CASING_EXIT_SIZE',
};

export const defaultWeights = {
  [rankingTypes.WELLBORE_RANKING]: {
    [rankingMethods.GEOMETRIC]: 10,
    [rankingMethods.FORMATION]: 6,
    [rankingMethods.DRILLINGSECTION]: 3,
    [rankingMethods.COMPLETIONSECTION]: 1,
    [rankingMethods.HOLESECTION]: 1,
    [rankingMethods.KEYWORD]: 5,
    [rankingMethods.TUBING]: 0,
    [rankingMethods.CASING_EXIT_SIZE]: 0,
  },
  [rankingTypes.SECTION_RANKING]: {
    [rankingMethods.GEOMETRIC]: 10,
    [rankingMethods.FORMATION]: 10,
    [rankingMethods.DRILLINGSECTION]: 10,
    [rankingMethods.COMPLETIONSECTION]: 10,
    [rankingMethods.HOLESECTION]: 10,
    [rankingMethods.KEYWORD]: 10,
    [rankingMethods.TUBING]: 10,
    [rankingMethods.CASING_EXIT_SIZE]: 10,
  },
};

export const mainRankingMethods = [
  rankingMethods.GEOMETRIC,
  rankingMethods.FORMATION,
  rankingMethods.DRILLINGSECTION,
  rankingMethods.COMPLETIONSECTION,
  rankingMethods.HOLESECTION,
  rankingMethods.KEYWORD,
  rankingMethods.TUBING,
  rankingMethods.CASING_EXIT_SIZE,
];

export const rankingResultsTypes = {
  GEOMETRIC: 'GEOMETRIC',
  GEOMETRIC_UI: 'Wellpath',
  GEOMETRICSECTION: 'GEOMETRIC_SECTION',
  GEOMETRICSECTION_UI: 'Wellpath',
  HOLESIZEDEPTH: 'HOLE_SIZE_DEPTH',
  HOLESIZEDEPTH_UI: 'Holesize depth',
  FORMATION: 'FORMATION',
  FORMATION_UI: 'Formation',
  DRILLINGSECTION: 'DRILLING_SECTION',
  DRILLINGSECTION_UI: 'Drilling section',
  COMPLETIONSECTION: 'COMPLETION_SECTION',
  COMPLETIONSECTION_UI: 'Completion section',
  HOLESECTION: 'HOLE_SECTION',
  HOLESECTION_UI: 'Hole section',
  KEYWORD: 'KEYWORD',
  KEYWORD_COMPLETION_SECTIONS: 'KEYWORD_COMPLETIONSECTIONS',
  KEYWORD_COMPLETION_EQUIPMENT: 'KEYWORD_COMPLETIONEQUIPMENT',
  KEYWORD_WELL_INFORMATION: 'KEYWORD_WELLINFORMATION',
  KEYWORD_OTHER: 'KEYWORD_OTHER',
  KEYWORD_UI: 'Keyword',
  KEYWORDCOMPLETIONSECTIONS_UI: 'Completion sections',
  KEYWORDCOMPLETIONEQUIPMENT_UI: 'Completion equipment',
  KEYWORDWELLINFORMATION_UI: 'Well information',
  KEYWORDOTHER_UI: 'Other',
  TUBING: 'TUBING',
  TUBING_UI: 'Tubing',
  CASINGEXITSIZE: 'CASING_EXIT_SIZE',
  CASINGEXITSIZE_UI: 'Casing exit size',
};

export const toastMessages = {
  GENERAL_ERROR: 'The application encountered an error.\n\nPlease try again later',
  API_UNAVAILABLE: 'The service endpoint is unavailable\n\nPlease try again later',
  NOT_AUTHORIZED: 'You are missing rights to access this application\n\nPlease contact support',
};

export const targetDivs = {
  RESULTS: 'results_main_page_query',
  FILTERS: 'filters_main_page_query',
  INFO: 'info_main_page_query',
  ERROR: 'error_main_page_query',
  SELECT_WELLBORE: 'select_wellbore_by_id',
  WELLBORE_RANKING_PANEL: 'wellpath_ranking_panel',
  SECTION_RANKING_PANEL: 'section_ranking_panel',
  FILTERS_PANEL: 'filters_panel',
};

export const propertyNames = {
  SECTION_NAME_PROPERTY: 'sectionName',
  FORMATION_NAME_PROPERTY: 'formationName',
};

export const separators = {
  WELLBORE_SECTION_SEPARATOR: '|',
};

// TODO: generate this from server side C# class
export const keywordTopics = {
  WELL: 'Well information',
  COMPLETION_SECTIONS: 'Completion sections',
  COMPLETION_EQUIPMENT: 'Completion equipment',
  OTHER: 'Other',
};
