import { handleActions } from 'redux-actions';
import * as actions from './feedbackActions';

const defaultState = {
  serviceNowFeedbackUrl: '/#',
};

export default handleActions({
  [actions.setServiceNowFeedbackUrl]: (state, action) => ({
    ...state,
    serviceNowFeedbackUrl: action.payload,
  }),
}, defaultState);
