import _ from 'lodash';
import {
  stateKeys, rankingTypes, sectionTypes, rankingMethods, rankingSectionGroupConstants,
} from '../../types';
import { getAvailableSections } from '../filters/filtersSelector';

// Ranking Methods
export const isRankingOn = (state, type, method) => state[stateKeys.SEARCH].ranking[type][method].selected;
export const getRankingWeight = (state, type, method) => state[stateKeys.SEARCH].ranking[type][method].weight;
export const getRankingInput = (state, type, method) => state[stateKeys.SEARCH].ranking[type][method].input;
export const getRankingInputParams = (state, type, method) => state[stateKeys.SEARCH].ranking[type][method];
export const getTubingRankingInputAverageSize = (state) => state[stateKeys.SEARCH].ranking[rankingTypes.SECTION_RANKING][rankingMethods.TUBING].input.averageSize;
export const getTubingRankingInputMainSize = (state) => state[stateKeys.SEARCH].ranking[rankingTypes.SECTION_RANKING][rankingMethods.TUBING].input.mainSize;
export const getTubingRankingInputSecondarySize = (state) => state[stateKeys.SEARCH].ranking[rankingTypes.SECTION_RANKING][rankingMethods.TUBING].input.secondarySize;
export const getSelectedWellboreName = (state, type) => state[stateKeys.SEARCH].ranking[type].selectedWellboreName;
export const getCasingExitSize = (state) => state[stateKeys.SEARCH].ranking[rankingTypes.SECTION_RANKING][rankingMethods.CASING_EXIT_SIZE].input.casingExitSize;

export const getSectionRankingEnabled = (state) => state[stateKeys.SEARCH].ranking[rankingTypes.SECTION_RANKING].isValid
  && !state[stateKeys.SEARCH].isLoadingResults;

export const getWellboreRankingEnabled = (state) => {
  const result = state[stateKeys.SEARCH].ranking[rankingTypes.WELLBORE_RANKING].isValid;
  return result;
};
export const getWellboreRankingErrorMessage = (state) => {
  const result = state[stateKeys.SEARCH].ranking[rankingTypes.WELLBORE_RANKING].errorMessage;
  return result;
};
// Source WellPath
export const getSourceWellPathUID = (state) => state[stateKeys.SEARCH].sourceWellPathUID;
export const getSourceWellPathFileName = (state, type) => state[stateKeys.SEARCH].sourceWellPathFileName[type];
export const getAvailableWellBores = (state) => state[stateKeys.SEARCH].availableWellBores;

// Process
export const isLoading = (state) => state[stateKeys.SEARCH].loading;
export const isLoadingFilters = (state) => state[stateKeys.SEARCH].loadingFilters;
export const isReceivingResults = (state) => state[stateKeys.SEARCH].receivingResults;
export const isLoadingResults = (state) => state[stateKeys.SEARCH].loadingResults;
export const getQueryId = (state) => state[stateKeys.SEARCH].queryId;
export const getFilteredListSize = (state) => state[stateKeys.SEARCH].filteredListSize;
export const getSelectedWellPathsForWellLine = (state) => state[stateKeys.SEARCH].selectedWellPathsForWellLine;
export const getInfo = (state) => state[stateKeys.SEARCH].info;
export const getError = (state) => state[stateKeys.SEARCH].error;
export const getMaxDisplayed = (state) => state[stateKeys.SEARCH].maxDisplayed;

// Results
export const getResults = (state, type) => state[stateKeys.SEARCH].results[type];

// creates distinct list of result groups ordered by section diameter
export const getRankedGroupNames = (state, type) => {
  const availableSections = getAvailableSections(state, sectionTypes.HOLE);
  const rankingResults = getResults(state, type);

  const otherSections = [
    rankingSectionGroupConstants.LOWER_COMPLETION.value,
    rankingSectionGroupConstants.UPPER_COMPLETION.value,
    rankingSectionGroupConstants.PREPARE_SIDETRACK.value,
  ];
  const distinctResultGroupSections = [];
  const map = new Map();
  rankingResults.forEach((resultItem) => {
    if (!map.has(resultItem.resultGroupName)) {
      map.set(resultItem.resultGroupName, true);
      distinctResultGroupSections.push({
        searchedSectionName: resultItem.searchedSectionName,
        resultGroupName: resultItem.resultGroupName,
        sectionTypeOrder: otherSections.indexOf(resultItem.resultGroupName),
      });
    }
  });

  const resultGroupNameWithSectionDiameter = distinctResultGroupSections.map((resultGroupSection) => {
    const sectionDiameters = availableSections.filter((y) => y.sectionName === resultGroupSection.searchedSectionName);
    let numericValue = 0;
    if (sectionDiameters.length > 0) {
      numericValue = sectionDiameters[0].numericValue;
    }

    return { resultGroupName: resultGroupSection.resultGroupName, numericValue, sectionTypeOrder: resultGroupSection.sectionTypeOrder };
  });

  const orderedResultGroupNames = _.orderBy(resultGroupNameWithSectionDiameter, ['sectionTypeOrder', 'numericValue'], ['asc', 'desc']);
  const result = orderedResultGroupNames.map((x) => x.resultGroupName);

  return result;
};
