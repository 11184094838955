import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Toggle extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    compareTo: PropTypes.string.isRequired,
    onToggle: PropTypes.func.isRequired,
  };

  render() {
    const { options, compareTo, onToggle } = this.props;

    return (
      <form>
        <div className="btn-group btn-group-toggle custom-margin">
          {options.map((option) => (
            <label
              key={option.value}
              className={`btn btn-secondary${compareTo === option.value ? ' active' : ''}`}
            >
              <input
                type="radio"
                value={option.value}
                checked={compareTo === option.value}
                onChange={(event) => onToggle(event.target.value)}
              />
              {' '}
              {option.name}
            </label>
          ))}
        </div>
      </form>
    );
  }
}
