import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import VirtualizedList from '../common/VirtualizedList';

// This is a basic component for datalists with a button
export default class BaseDropdownWithButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      overwrite: true,
    };
  }

  componentDidMount = () => {
    const { defaultValue, defaultValueContains, buttonClicked } = this.props;
    const { overwrite } = this.state;

    if (defaultValue.indexOf(defaultValueContains) !== -1) {
      const value = defaultValue.replace(defaultValueContains, '');
      this.setState({
        overwrite: true,
      }, () => buttonClicked(value, overwrite, true));
    }
  }

  handleSelectValue = (event, value) => {
    const { buttonClicked } = this.props;
    const { overwrite } = this.state;

    if (value && value !== '') {
      buttonClicked(value, overwrite);
    }
  }

  inputFieldForVirtualizedList = (params, label) => (
    <TextField {...params} variant="outlined" label={label} fullWidth />
  );

  render() {
    const { selectedValue, name, availableValues } = this.props;
    const { overwrite } = this.state;

    return (
      <div>
        <VirtualizedList
          id={`${name}_input`}
          options={availableValues}
          onChange={this.handleSelectValue}
          value={selectedValue}
          label="Wellbores"
          input={this.inputFieldForVirtualizedList}
        />
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="overwriteSelectCheckbox"
            checked={overwrite}
            onChange={(event) => this.setState({
              overwrite: event.target.checked,
            })}
          />
          <label className="custom-control-label" htmlFor="overwriteSelectCheckbox">Overwrite input</label>
        </div>
      </div>
    );
  }
}

BaseDropdownWithButton.propTypes = {
  name: PropTypes.string.isRequired,
  availableValues: PropTypes.array.isRequired,
  selectedValue: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  buttonClicked: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  defaultValueContains: PropTypes.string.isRequired,
};

BaseDropdownWithButton.defaultProps = {
  selectedValue: '',
};
