import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import './DateIntervalFilter.css';

const INTERVAL_FILTER_TYPE_NONE = 'None';
const INTERVAL_FILTER_TYPE_LAST_YEAR = 'LastYear';
const INTERVAL_FILTER_TYPE_LAST_2YEARS = 'Last2Years';
const INTERVAL_FILTER_TYPE_LAST_3YEARS = 'Last3Years';
const INTERVAL_FILTER_TYPE_LAST_4YEARS = 'Last4Years';
const INTERVAL_FILTER_TYPE_LAST_5YEARS = 'Last5Years';
const INTERVAL_FILTER_TYPE_LAST_CUSTOM = 'Custom';

class DateIntervalFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDateFilterType: this.props.dateFilterType,
      customMinDate: this.props.minValue,
      customMaxDate: this.props.maxValue,
    };
  }

  setInterval = (min, max) => {
    this.props.onMinChange(min);
    this.props.onMaxChange(max);
    this.setState({
      customMinDate: min,
      customMaxDate: max,
    });
  }

  substractYears = (date, yearsToSubstract) => {
    const newDate = new Date(date.getTime());
    newDate.setFullYear(date.getFullYear() - yearsToSubstract);
    return newDate;
  }

  handleDateFilterTypeChange = (event) => {
    const selectedValue = event.target[event.target.selectedIndex].value;
    this.setState({
      selectedDateFilterType: selectedValue,
    });
    this.props.onDateFilterTypeChange(selectedValue);
    const now = new Date();

    switch (selectedValue) {
      case INTERVAL_FILTER_TYPE_NONE:
        this.setInterval(null, null);
        break;
      case INTERVAL_FILTER_TYPE_LAST_YEAR:
        this.setInterval(this.substractYears(now, 1), now);
        break;
      case INTERVAL_FILTER_TYPE_LAST_2YEARS:
        this.setInterval(this.substractYears(now, 2), now);
        break;
      case INTERVAL_FILTER_TYPE_LAST_3YEARS:
        this.setInterval(this.substractYears(now, 3), now);
        break;
      case INTERVAL_FILTER_TYPE_LAST_4YEARS:
        this.setInterval(this.substractYears(now, 4), now);
        break;
      case INTERVAL_FILTER_TYPE_LAST_5YEARS:
        this.setInterval(this.substractYears(now, 5), now);
        break;
      case INTERVAL_FILTER_TYPE_LAST_CUSTOM:
        this.setInterval(null, null);
        break;
      default:
        this.setInterval(null, null);
        break;
    }
  }

  handleChangeStartDateValue = (event) => {
    const newMinValue = event.target.valueAsDate;
    this.setState({
      customMinDate: newMinValue,
    });
  }

  handleChangeEndDateValue = (event) => {
    const newMaxValue = event.target.valueAsDate;
    this.setState({
      customMaxDate: newMaxValue,
    });
  }

  handleChangeInterval = () => {
    if (this.props.minValue !== this.state.customMinDate || this.props.maxValue !== this.state.customMaxDate) {
      this.setInterval(this.state.customMinDate, this.state.customMaxDate);
    }
  }

  padToFour = (number) => {
    const result = (`000${number}`).slice(-4);
    return result;
  }

  dateToString = (date) => {
    if (date === null || date === undefined) {
      return date;
    }

    const mm = date.getMonth() + 1; // getMonth() is zero-based
    const dd = date.getDate();
    const yyyy = date.getFullYear();

    const result = [this.padToFour(yyyy),
      (mm > 9 ? '' : '0') + mm,
      (dd > 9 ? '' : '0') + dd,
    ].join('-');

    return result;
  }

  render() {
    return (
      <div>
        <div className="row justify-content-start mb-3">
          <div className="col-sm-3">
            <span className="input-group-text w-100 flex-row-reverse text-info">Last section</span>
          </div>
          <select onChange={(event) => this.handleDateFilterTypeChange(event)} value={this.state.selectedDateFilterType}>
            <option value={INTERVAL_FILTER_TYPE_NONE}>None</option>
            <option value={INTERVAL_FILTER_TYPE_LAST_YEAR}>Last year</option>
            <option value={INTERVAL_FILTER_TYPE_LAST_2YEARS}>Last 2 years</option>
            <option value={INTERVAL_FILTER_TYPE_LAST_3YEARS}>Last 3 years</option>
            <option value={INTERVAL_FILTER_TYPE_LAST_4YEARS}>Last 4 years</option>
            <option value={INTERVAL_FILTER_TYPE_LAST_5YEARS}>Last 5 years</option>
            <option value={INTERVAL_FILTER_TYPE_LAST_CUSTOM}>Custom</option>
          </select>
        </div>
        {this.state.selectedDateFilterType === INTERVAL_FILTER_TYPE_LAST_CUSTOM && (
        <div className="row justify-content-start mb-3">
          <div className="col" />
          <div className="col">
            <input type="date" className="form-control" value={this.state.customMinDate === null ? '' : this.dateToString(this.state.customMinDate)} onChange={this.handleChangeStartDateValue} onBlur={this.handleChangeInterval} />
            <input type="date" className="form-control" value={this.state.customMaxDate === null ? '' : this.dateToString(this.state.customMaxDate)} onChange={this.handleChangeEndDateValue} onBlur={this.handleChangeInterval} />
          </div>
          <div className="col" />
          <div className="col" />
        </div>
        )}
      </div>
    );
  }
}

DateIntervalFilter.propTypes = {
  minValue: PropTypes.instanceOf(Date),
  maxValue: PropTypes.instanceOf(Date),
  dateFilterType: PropTypes.string,
  onMinChange: PropTypes.func.isRequired,
  onMaxChange: PropTypes.func.isRequired,
  onDateFilterTypeChange: PropTypes.func.isRequired,
};

export default DateIntervalFilter;
