import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { formatDepth } from '../../utils/formatters';

class WellboreItem extends Component {
  onClick = () => {
    this.props.onRemove(this.props.wellbore);
  }

  handleDragStart = (event) => {
    const dropInfo = {
      wellboreListId: this.props.wellboreList.id,
      uniqueWellboreIdentifier: this.props.wellbore.uniqueWellboreIdentifier,
    };

    event.dataTransfer.setData('text', JSON.stringify(dropInfo));
  }

  render() {
    return (
      <div id="my-lists-welbore-item" key={this.props.wellbore.id} className="row list-group-item">
        <div className="row" style={{ marginLeft: '0px' }}>
          <h5 draggable="true" onDragStart={(event) => this.handleDragStart(event)}><b>{this.props.wellbore.uniqueWellboreIdentifier}</b></h5>
        </div>
        <div className="row">
          <div className="col">
            Field:
            {' '}
            {this.props.wellbore.fieldIdentifier}
          </div>
          <div className="col">
            Depth:
            {' '}
            {(this.props.wellbore.totalDepthMd && this.props.wellbore.totalDepthMd !== 0) ? `${formatDepth(this.props.wellbore.totalDepthMd)} m` : 'N/A'}
          </div>
          {this.props.wellbore.rigs && (
          <div className="col">
            Rigs:
            {' '}
            {this.props.wellbore.rigs.join(', ')}
          </div>
          )}
          <div className="col">
            <button
              type="button"
              className="btn"
              onClick={this.onClick}
              data-toggle="tooltip"
              data-placement="right"
              title="Remove wellbore from list"
              disabled={this.props.wellbore.isDisabled}
            >
              <FontAwesomeIcon icon="trash-alt" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

WellboreItem.propTypes = {
  wellboreList: PropTypes.object.isRequired,
  wellbore: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default WellboreItem;
