import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from './auth/authReducer';
import manifestReducer from './manifest';
import versionReducer from './version/versionReducer';
import changelogReducer from './changelog/changelogReducer';
import searchReducer from './search/searchReducer';
import filtersReducer from './filters/filtersReducer';
import generalReducer from './general/generalReducer';
import myListsReducer from './myLists/myListsReducer';
import guidesReducer from './guides/guidesReducer';
import { stateKeys } from '../types';
import feedbackReducer from './feedback/feedbackReducer';

const createRootReducer = (history) => combineReducers({
  [stateKeys.MANIFEST]: manifestReducer,
  [stateKeys.AUTH]: authReducer,
  [stateKeys.VERSION]: versionReducer,
  [stateKeys.CHANGELOG]: changelogReducer,
  [stateKeys.NAV]: connectRouter(history),
  [stateKeys.SEARCH]: searchReducer,
  [stateKeys.FILTERS]: filtersReducer,
  [stateKeys.GENERAL]: generalReducer,
  [stateKeys.MY_LISTS]: myListsReducer,
  [stateKeys.GUIDES]: guidesReducer,
  [stateKeys.FEEDBACK]: feedbackReducer,
});

export default createRootReducer;
