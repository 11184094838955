import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Plotly from 'plotly.js-dist';
import { Parse3DCoordinates, Unpack } from '../../utils/main';
import { GenerateSectionPlot } from './SectionPlotGenerator';

export const CreatePlotName = (name) => `${encodeURIComponent(name)}_plot`;

const AXES_INFO = {
  x: { title: 'EW', unit: 'm' },
  y: { title: 'NS', unit: 'm' },
  z: { title: 'TVD-RKB', unit: 'm' },
};

export const hoverTemplate = `<b>${AXES_INFO.x.title}</b>:  %{x} ${AXES_INFO.x.unit
}<br><b>${AXES_INFO.y.title}</b>:  %{y} ${AXES_INFO.y.unit
}<br><b>${AXES_INFO.z.title}</b>:  %{z} ${AXES_INFO.z.unit}`;

const defaultScene = (axis) => ({
  autorange: true,
  title: { text: `${AXES_INFO[axis].title} (${AXES_INFO[axis].unit})` },
});

const rangedScene = (min, max, axis) => ({
  autorange: false,
  range: [min, max],
  title: { text: `${AXES_INFO[axis].title} (${AXES_INFO[axis].unit})` },
});

const GetXYZFromTrace = (trace) => {
  const x = trace.data.map((t) => t.x);
  const y = trace.data.map((t) => t.y);
  const z = trace.data.map((t) => t.z);
  return [x, y, z];
};

// This is a 3D plot of a WellPath
export default class WellPath3D extends Component {
  static propTypes = {
    traces: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    searchedSection: PropTypes.object,
    rankedSection: PropTypes.object,
  };

  static defaultProps = {
    searchedSection: null,
    rankedSection: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      name: CreatePlotName(props.name),
    };
  }

  componentDidMount = () => {
    this.drawVisualization();
  }

  componentWillUnmount = () => {
    Plotly.purge(this.state.name);
  }

  setupRanges = (traceCoordinates) => {
    let xmin = -5000;
    let xmax = 5000;
    let ymin = -5000;
    let ymax = 5000;

    for (let i = 0; i < traceCoordinates.length; i += 1) {
      const currentTrace = traceCoordinates[i].data;
      const x = Unpack(currentTrace, 'x');
      const y = Unpack(currentTrace, 'y');

      for (let j = 0; j < x.length; j += 1) {
        if (x[j] > xmax) xmax = x[j];
        if (x[j] < xmin) xmin = x[j];
      }

      for (let j = 0; j < y.length; j += 1) {
        if (y[j] > ymax) ymax = y[j];
        if (y[j] < ymin) ymin = y[j];
      }
    }

    return {
      xaxis: rangedScene(xmin, xmax, 'x'),
      yaxis: rangedScene(ymin, ymax, 'y'),
      zaxis: defaultScene('z'),
    };
  }

  CreateSectionsForMainPlot(traceCoordinates) {
    if (traceCoordinates.filter((tc) => tc.holeSections.length === 0).length > 0) {
      const traceConfigWithoutSections = traceCoordinates.filter((t) => t.holeSections.length === 0)[0];
      const md = traceConfigWithoutSections.data.map((t) => t.md);
      if (md.length > 0 && this.props.searchedSection) {
        const [x, y, z] = GetXYZFromTrace(traceConfigWithoutSections);
        const sectionsPlot = GenerateSectionPlot(
          this.props.searchedSection,
          x,
          y,
          z,
          md,
          'rgb(0,0,255)',
        );
        return sectionsPlot;
      }
    }
    return null;
  }

  CreateSectionsForSecondaryPlot(traceCoordinates) {
    if (traceCoordinates.filter((tc) => tc.holeSections.length > 0).length > 0) {
      const tc = traceCoordinates.filter((t) => t.holeSections.length > 0)[0];
      const holeSections = tc.holeSections.filter((t) => t.sectionName === this.props.rankedSection.sectionName);
      if (holeSections.length > 0) {
        const holeSection = holeSections[0];
        if (holeSection.startDepth < holeSection.endDepth) {
          const [x, y, z] = GetXYZFromTrace(tc);
          const md = tc.wellpathAdditionalInformation.map((t) => t.md);
          const sectionsPlot = GenerateSectionPlot(
            holeSection,
            x,
            y,
            z,
            md,
            'rgb(255,165,0)',
          );
          return sectionsPlot;
        }
      }
    }
    return null;
  }

  drawVisualization = () => {
    if (!this.props.traces) {
      return;
    }

    this.setState({
      name: CreatePlotName(this.props.name),
    });

    const traceCoordinates = [];
    this.props.traces.map((t) => traceCoordinates.push(
      {
        name: t.name,
        data: Parse3DCoordinates(t.data, true),
        holeSections: t.holeSections ? t.holeSections : [],
        wellpathAdditionalInformation: t.wellpathAdditionalInformation ? t.wellpathAdditionalInformation : [],
      },
    ));

    if (traceCoordinates.length === 0) {
      return;
    }

    let traceConfigs = [];
    traceCoordinates.map((tc) => traceConfigs.push({
      name: tc.name,
      x: Unpack(tc.data, 'x'),
      y: Unpack(tc.data, 'y'),
      z: Unpack(tc.data, 'z'),
      type: 'scatter3d',
      mode: 'lines',
      hovertemplate: hoverTemplate,
    }));

    const mainSectionsPlot = this.CreateSectionsForMainPlot(traceCoordinates);
    if (mainSectionsPlot) traceConfigs = traceConfigs.concat(mainSectionsPlot);

    const secondarySectionsPlot = this.CreateSectionsForSecondaryPlot(traceCoordinates);
    if (secondarySectionsPlot) traceConfigs = traceConfigs.concat(secondarySectionsPlot);

    if (traceConfigs.length === 0) {
      return;
    }

    const layout = {
      autosize: true,
      margin: {
        l: 10,
        r: 10,
        b: 10,
        t: 10,
      },
      scene: this.setupRanges(traceCoordinates),
      showlegend: true,
      legend: {
        x: '0',
        'margin.r': '120',
      },
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
    };

    const plotlyDataSet = JSON.parse(JSON.stringify(traceConfigs));

    Plotly.newPlot(this.state.name, plotlyDataSet, layout, {
      displayModeBar: false,
    });
  }

  render = () => (
    <div>
      <div className="row">
        <div id={this.state.name} />
      </div>
    </div>
  );
}
