import { handleActions } from 'redux-actions';
import produce from 'immer';
import * as actions from './searchActions';
import {
  rankingMethods, rankingTypes, propertyNames, defaultWeights,
} from '../../types';
import { validateSectionRankingInputInSearchState, validateWellboreRankingInputInSearchState } from './searchInputValidation';

export const getDefaultState = () => ({
  ranking: {
    [rankingTypes.WELLBORE_RANKING]: {
      isValid: true,
      errorMessage: '',
      selectedWellboreName: '',
      [rankingMethods.GEOMETRIC]: {
        selected: false,
        weight: defaultWeights[rankingTypes.WELLBORE_RANKING][rankingMethods.GEOMETRIC],
        input: [],
        additionalDetails: [],
      },
      [rankingMethods.FORMATION]: {
        selected: false,
        weight: defaultWeights[rankingTypes.WELLBORE_RANKING][rankingMethods.FORMATION],
        input: [],
        additionalDetails: [],
      },
      [rankingMethods.DRILLINGSECTION]: {
        selected: false,
        weight: defaultWeights[rankingTypes.WELLBORE_RANKING][rankingMethods.DRILLINGSECTION],
        input: [],
        additionalDetails: [],
      },
      [rankingMethods.COMPLETIONSECTION]: {
        selected: false,
        weight: defaultWeights[rankingTypes.WELLBORE_RANKING][rankingMethods.COMPLETIONSECTION],
        input: [],
        additionalDetails: [],
      },
      [rankingMethods.HOLESECTION]: {
        selected: false,
        weight: defaultWeights[rankingTypes.WELLBORE_RANKING][rankingMethods.HOLESECTION],
        input: [],
        additionalDetails: [],
      },
      [rankingMethods.KEYWORD]: {
        selected: false,
        weight: defaultWeights[rankingTypes.WELLBORE_RANKING][rankingMethods.KEYWORD],
        input: [],
        additionalDetails: [],
      },
      [rankingMethods.OTHER_SECTION]: {
        selected: true,
        weight: defaultWeights[rankingTypes.WELLBORE_RANKING][rankingMethods.OTHER_SECTION],
        input: [],
        additionalDetails: [],
      },
      [rankingMethods.TUBING]: {
        selected: false,
        weight: defaultWeights[rankingTypes.SECTION_RANKING][rankingMethods.TUBING],
        input: {
          averageSize: '',
          mainSize: '',
          secondarySize: '',
        },
      },
      [rankingMethods.CASING_EXIT_SIZE]: {
        selected: false,
        weight: defaultWeights[rankingTypes.SECTION_RANKING][rankingMethods.CASING_EXIT_SIZE],
        input: {
          casingExitSize: '',
        },
      },
    },

    // By default all methods are selected in Section Ranking except
    // Drilling & Completion Section ranking (which is included in Keyword ranking)
    [rankingTypes.SECTION_RANKING]: {
      isValid: true,
      errorMessage: '',
      selectedWellboreName: '',
      [rankingMethods.GEOMETRIC]: {
        selected: true,
        weight: defaultWeights[rankingTypes.SECTION_RANKING][rankingMethods.GEOMETRIC],
        input: [],
        additionalDetails: [],
      },
      [rankingMethods.FORMATION]: {
        selected: true,
        weight: defaultWeights[rankingTypes.SECTION_RANKING][rankingMethods.FORMATION],
        input: [],
        inputMaxId: 0,
        additionalDetails: [],
      },
      [rankingMethods.DRILLINGSECTION]: {
        selected: false,
        weight: defaultWeights[rankingTypes.SECTION_RANKING][rankingMethods.DRILLINGSECTION],
        input: [],
        additionalDetails: [],
      },
      [rankingMethods.COMPLETIONSECTION]: {
        selected: false,
        weight: defaultWeights[rankingTypes.SECTION_RANKING][rankingMethods.COMPLETIONSECTION],
        input: [],
        additionalDetails: [],
      },
      [rankingMethods.HOLESECTION]: {
        selected: true,
        weight: defaultWeights[rankingTypes.SECTION_RANKING][rankingMethods.HOLESECTION],
        input: [],
        inputMaxId: 0,
        additionalDetails: [],
      },
      [rankingMethods.KEYWORD]: {
        selected: true,
        weight: defaultWeights[rankingTypes.SECTION_RANKING][rankingMethods.KEYWORD],
        input: [],
        additionalDetails: [],
      },
      [rankingMethods.OTHER_SECTION]: {
        selected: true,
        weight: defaultWeights[rankingTypes.SECTION_RANKING][rankingMethods.OTHER_SECTION],
        input: [],
        additionalDetails: [],
      },
      [rankingMethods.TUBING]: {
        selected: true,
        weight: defaultWeights[rankingTypes.SECTION_RANKING][rankingMethods.TUBING],
        input: {
          averageSize: '',
          mainSize: '',
          secondarySize: '',
        },
      },
      [rankingMethods.CASING_EXIT_SIZE]: {
        selected: true,
        weight: defaultWeights[rankingTypes.SECTION_RANKING][rankingMethods.CASING_EXIT_SIZE],
        input: {
          casingExitSize: '',
        },
      },
    },
  },

  sourceWellPathUID: '',
  sourceWellPathFileName: {
    [rankingTypes.WELLBORE_RANKING]: '',
    [rankingTypes.SECTION_RANKING]: '',
  },
  availableWellBores: [],
  loading: false,
  loadingFilters: false,
  receivingResults: false,
  loadingResults: false,
  queryId: '',
  filteredListSize: 300,
  selectedWellPathsForWellLine: [],
  info: '',
  error: null,
  maxDisplayed: 10,
  results: {
    [rankingTypes.WELLBORE_RANKING]: [],
    [rankingTypes.SECTION_RANKING]: [],
  },
});

const executeValidation = (newSearchState, rankingType) => {
  const newState = produce(newSearchState, (draftState) => {
    switch (rankingType) {
      case rankingTypes.SECTION_RANKING: {
        const validationResult = validateSectionRankingInputInSearchState(draftState);
        draftState.ranking[rankingTypes.SECTION_RANKING].isValid = validationResult;
      }
        break;

      case rankingTypes.WELLBORE_RANKING: {
        const validationResult = validateWellboreRankingInputInSearchState(draftState);
        draftState.ranking[rankingTypes.WELLBORE_RANKING].isValid = validationResult;
      }
        break;

      default:
        throw new Error('Unsupported ranking type');
    }
  });

  return newState;
};

const setTubingRankingInput = (state, action, propertyName) => {
  const type = rankingTypes.SECTION_RANKING;
  const method = rankingMethods.TUBING;
  return setSingeValueRankingInput(state, action, type, method, propertyName);
};

const setCasingExitSizeRankingInput = (state, action, propertyName) => {
  const type = rankingTypes.SECTION_RANKING;
  const method = rankingMethods.CASING_EXIT_SIZE;
  return setSingeValueRankingInput(state, action, type, method, propertyName);
};

const setSingeValueRankingInput = (state, action, type, method, propertyName) => {
  const { value } = action.payload;

  const newState = {
    ...state,
    ranking: {
      ...state.ranking,
      [type]: {
        ...state.ranking[type],
        [method]: {
          ...state.ranking[type][method],
          input: {
            ...state.ranking[type][method].input,
            [propertyName]: value,
          },
        },
      },
    },
  };

  return executeValidation(newState, type);
};

export default handleActions({

  // #region ranking input reducers

  [actions.validateSearchInputs]: (state) => {
    const newState = {
      ...state,
    };

    const validatedState = executeValidation(newState, rankingTypes.WELLBORE_RANKING);
    return executeValidation(validatedState, rankingTypes.SECTION_RANKING);
  },

  [actions.setRankingOn]: (state, action) => {
    const { type, method, value } = action.payload;
    const newState = {
      ...state,
      ranking: {
        ...state.ranking,
        [type]: {
          ...state.ranking[type],
          [method]: {
            ...state.ranking[type][method],
            selected: value,
          },
        },
      },
    };

    return executeValidation(newState, type);
  },

  [actions.setRankingWeight]: (state, action) => {
    const { type, method, value } = action.payload;
    let newValue = parseFloat(value);

    if (newValue < 1) {
      newValue = 1;
    }
    if (newValue > 10) {
      newValue = 10;
    }

    const newState = {
      ...state,
      ranking: {
        ...state.ranking,
        [type]: {
          ...state.ranking[type],
          [method]: {
            ...state.ranking[type][method],
            weight: parseFloat(newValue),
          },
        },
      },
    };

    return executeValidation(newState, type);
  },

  [actions.setRankingInput]: (state, action) => {
    const { type, method, value } = action.payload;
    const newState = {
      ...state,
      ranking: {
        ...state.ranking,
        [type]: {
          ...state.ranking[type],
          [method]: {
            ...state.ranking[type][method],
            input: value,
          },
        },
      },
    };

    return executeValidation(newState, type);
  },

  [actions.addRankingInput]: (state, action) => {
    const { type, method, value } = action.payload;
    let updatedInput = [];
    let newInputId = state.ranking[type][method].inputMaxId;

    const isSectionRankingHoleSectionOrFormationParameter = type === rankingTypes.SECTION_RANKING && (method === rankingMethods.HOLESECTION || method === rankingMethods.FORMATION);

    // TODO?: this check only works for value types (strings)
    // when the input array contains object it will not prevent adding dupicates

    let isValueAlreadyAdded = false;
    if (type === rankingTypes.SECTION_RANKING && method === rankingMethods.KEYWORD) {
      isValueAlreadyAdded = state.ranking[type][method].input.filter((x) => x.groupName === value.groupName && x.keyword === value.keyword).length > 0;
    } else {
      isValueAlreadyAdded = state.ranking[type][method].input.includes(value);
    }

    // when the user can add data to the selected options then we have to
    // explicitly generate a identifier for the selected option ui element
    // this was motivated by the fact that in case of HoleSections a
    // particular HoleSection can be added multiple times to the selected HoleSections list
    // for the sake of simplicity we just followed the same strategy in case of Formations
    // although the same formation cannot added multiple times the implementation in
    // modifyValueProperty action is simpler this way, that it can assume that a
    // uiId property is present
    if (isSectionRankingHoleSectionOrFormationParameter) {
      newInputId += 1;
      value.uiId = newInputId;
    }

    if (!isSectionRankingHoleSectionOrFormationParameter && isValueAlreadyAdded) {
      return state;
    }

    if (type === rankingTypes.WELLBORE_RANKING && method === rankingMethods.FORMATION
      && value.formationName && state.ranking[type][method].input.filter((x) => x.formationName == value.formationName).length > 0) {
      return state;
    }

    updatedInput = state.ranking[type][method].input.concat(value);
    const newState = {
      ...state,
      ranking: {
        ...state.ranking,
        [type]: {
          ...state.ranking[type],
          [method]: {
            ...state.ranking[type][method],
            input: updatedInput,
            inputMaxId: newInputId,
          },
        },
      },
    };

    return executeValidation(newState, type);
  },

  [actions.setTubingRankingInputAverageSize]: (state, action) => setTubingRankingInput(state, action, 'averageSize'),

  [actions.setTubingRankingInputMainSize]: (state, action) => setTubingRankingInput(state, action, 'mainSize'),

  [actions.setTubingRankingInputSecondarySize]: (state, action) => setTubingRankingInput(state, action, 'secondarySize'),

  [actions.setCasingExitSize]: (state, action) => setCasingExitSizeRankingInput(state, action, 'casingExitSize'),

  [actions.modifyValueProperty]: (state, action) => {
    const {
      type, method, uiId, property, value,
    } = action.payload;

    const newState = {
      ...state,
      ranking: {
        ...state.ranking,
        [type]: {
          ...state.ranking[type],
          [method]: {
            ...state.ranking[type][method],
            input: JSON.parse(JSON.stringify(state.ranking[type][method].input)).map((el) => {
              if (el.uiId && el.uiId === uiId) {
                el[property] = value;
              }

              return el;
            }),
          },
        },
      },
    };

    return executeValidation(newState, type);
  },

  [actions.removeRankingInput]: (state, action) => {
    const { type, method, value } = action.payload;
    const newState = {
      ...state,
      ranking: {
        ...state.ranking,
        [type]: {
          ...state.ranking[type],
          [method]: {
            ...state.ranking[type][method],
            input: state.ranking[type][method].input.filter((input) => input !== value),
          },
        },
      },
    };

    return executeValidation(newState, type);
  },

  [actions.clearRankingMethodInput]: (state, action) => {
    const { type, method } = action.payload;
    const newState = {
      ...state,
      ranking: {
        ...state.ranking,
        [type]: {
          ...state.ranking[type],
          [method]: {
            ...state.ranking[type][method],
            input: [],
          },
        },
      },
    };

    return executeValidation(newState, type);
  },
  // #endregion ranking input reducers

  [actions.setRankingAdditionalDetails]: (state, action) => {
    const { type, method, value } = action.payload;
    return {
      ...state,
      ranking: {
        ...state.ranking,
        [type]: {
          ...state.ranking[type],
          [method]: {
            ...state.ranking[type][method],
            additionalDetails: value,
          },
        },
      },
    };
  },

  [actions.setSelectedWellboreName]: (state, action) => {
    const { type, selectedWellboreName } = action.payload;
    const newState = {
      ...state,
      ranking: {
        ...state.ranking,
        [type]: {
          ...state.ranking[type],
          selectedWellboreName,
        },
      },
    };

    return newState;
  },

  [actions.clearRankingInput]: (state, action) => {
    const { type } = action.payload;
    const newState = {
      ...state,
      ranking: {
        ...state.ranking,
        [type]: {
          ...(getDefaultState().ranking[type]),
        },
      },
      sourceWellPathUID: '',
      sourceWellPathFileName: {
        ...state.sourceWellPathFileName,
        [type]: '',
      },
    };

    return executeValidation(newState, type);
  },

  [actions.setSourceWellPathUID]: (state, action) => ({
    ...state,
    sourceWellPathUID: action.payload,
  }),

  [actions.setSourceWellPathFileName]: (state, action) => {
    const { type, value } = action.payload;
    return {
      ...state,
      sourceWellPathFileName: {
        ...state.sourceWellPathFileName,
        [type]: value,
      },
    };
  },

  [actions.setAvailableWellBores]: (state, action) => ({
    ...state,
    availableWellBores: action.payload,
  }),

  [actions.setLoading]: (state, action) => ({
    ...state,
    loading: action.payload,
  }),

  [actions.setLoadingFilters]: (state, action) => ({
    ...state,
    loadingFilters: action.payload,
  }),

  [actions.setReceivingResults]: (state, action) => ({
    ...state,
    receivingResults: action.payload,
  }),

  [actions.setLoadingResults]: (state, action) => ({
    ...state,
    loadingResults: action.payload,
  }),

  [actions.setQueryId]: (state, action) => ({
    ...state,
    queryId: action.payload,
  }),

  [actions.setFilteredListSize]: (state, action) => ({
    ...state,
    filteredListSize: action.payload,
  }),

  [actions.addSelectedWellPathForWellLine]: (state, action) => ({
    ...state,
    selectedWellPathsForWellLine: state.selectedWellPathsForWellLine.concat(action.payload),
  }),

  [actions.removeSelectedWellPathForWellLine]: (state, action) => ({
    ...state,
    selectedWellPathsForWellLine:
      state.selectedWellPathsForWellLine.filter((wp) => wp !== action.payload),
  }),

  [actions.clearSelectedWellPathsForWellLine]: (state) => ({
    ...state,
    selectedWellPathsForWellLine: [],
  }),

  [actions.setInfo]: (state, action) => ({
    ...state,
    info: action.payload,
  }),

  [actions.setError]: (state, action) => ({
    ...state,
    error: action.payload,
  }),

  [actions.setMaxDisplayed]: (state, action) => ({
    ...state,
    maxDisplayed: action.payload,
  }),

  [actions.setResults]: (state, action) => {
    const { type, value } = action.payload;
    return {
      ...state,
      results: {
        ...state.results,
        [type]: value,
      },
    };
  },

  [actions.clearResults]: (state, action) => {
    const { type } = action.payload;
    return {
      ...state,
      results: {
        ...state.results,
        [type]: [],
      },
    };
  },

  [actions.addResults]: (state, action) => {
    const { type, value } = action.payload;
    return {
      ...state,
      results: {
        ...state.results,
        [type]: state.results[type].concat(value),
      },
    };
  },
}, getDefaultState());
