import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { filterConstants } from '../../types';

// This is a basic component for all filters with multiple text inputs and additional dropdown
export default class BaseMultipleInputFilter extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    handleSelectValue: PropTypes.func.isRequired,
    handleRemoveValue: PropTypes.func.isRequired,
    selectedValues: PropTypes.array.isRequired,
    availableValues: PropTypes.array.isRequired,
    optionLabelProperty: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: '',
    };
  }

  addValueOnEnter = () => {
    if (event.keyCode === 13) {
      event.preventDefault();
      document.getElementById(`input_${this.props.name}_button`).click();
    }
  }

  componentDidMount = () => {
    const inputText = document.getElementById(`input_${this.props.name}_text`);
    inputText.addEventListener('keyup', this.addValueOnEnter);
  }

  componentWillUnmount = () => {
    const inputText = document.getElementById(`input_${this.props.name}_text`);
    inputText.removeEventListener('keyup', this.addValueOnEnter);
  }

  handleChange = (event) => {
    this.setState({
      value: event.target.value,
    });
  }

  handleSelectValue = () => {
    this.props.handleSelectValue(this.state.value);
    this.setState({
      value: '',
    });
  }

  isValueSelectable = () => {
    if (this.state.value === '') return false;

    const { selectedValues, availableValues, optionLabelProperty } = this.props;
    const selected = selectedValues.indexOf(this.state.value) !== -1;
    const available = optionLabelProperty
      ? availableValues.filter((el) => el[optionLabelProperty] === this.state.value).length > 0
      : availableValues.indexOf(this.state.value) !== -1;

    return !selected && available;
  }

  render() {
    const {
      name,
      selectedValues,
      availableValues,
      handleRemoveValue,
      optionLabelProperty,
    } = this.props;

    return (
      <div className="row justify-content-start mb-3">
        <div className="col-sm-3">
          <span className="input-group-text w-100 flex-row-reverse text-info">{name}</span>
        </div>
        <input list={`list_${name}`} id={`input_${name}_text`} name={`input_${name}`} value={this.state.value} onChange={this.handleChange} />
        <datalist id={`list_${name}`}>
          {optionLabelProperty
            ? availableValues.map((el) => <option key={el[optionLabelProperty]} onClick={this.handleSelectValue} value={el[optionLabelProperty]} />)
            : availableValues.map((el) => <option key={el} onClick={this.handleSelectValue} value={el} />)}
        </datalist>
        <div className="input-group-append">
          <button
            id={`input_${name}_button`}
            className="btn btn-dark"
            disabled={!this.isValueSelectable()}
            onClick={this.handleSelectValue}
          >
            +
          </button>
        </div>
        {this.props.excludeOption && (
        <div className="input-group-append">
          <div className="input-group-text">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id={`excludeOption_${name}`}
                checked={this.props.excludeCondition(name)}
                onChange={(event) => this.props.excludeAction(name, event.target.checked)}
              />
              <label className="custom-control-label" htmlFor={`excludeOption_${name}`}>exclude</label>
            </div>
          </div>
        </div>
        )}
        <div className="col-sm-6">
          {selectedValues.map((el) => (
            <div key={el} className="alert-info selected-from-list">
              {el}
              <button
                type="button"
                className="btn"
                style={{ paddingBottom: 0, paddingTop: 0 }}
                onClick={() => handleRemoveValue(el)}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
