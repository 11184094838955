/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
import { handleActions } from 'redux-actions';
import produce from 'immer';
import * as actions from './myListsActions';
import { addToast, ToastTypes } from '../../utils/addToastByType';

const defaultState = {
  wellboreLists: [],
  wellboreListLoading: false,
  selectedWellboreListId: -1,
  editedWellboreListId: -1,
  recommendedWellbores: [],
  recommendedWellboresLoading: false,
  isLoading: true,
};

export default handleActions({
  // #region fetchMyLists
  [actions.fetchMyListsRequested]: (state) => {
    return produce(state, (draft) => {
      draft.isLoading = true;
    });
  },

  [actions.fetchMyListsSucceeded]: (state, action) => {
    return produce(state, (draft) => {
      draft.wellboreLists = action.payload.body.wellboreLists;
      draft.selectedWellboreListId = action.payload.body.wellboreLists[0].id;
      draft.isLoading = false;
    });
  },

  [actions.fetchMyListsFailed]: (state) => {
    return produce(state, (draft) => {
      draft.isLoading = false;
      addToast({ text: 'An unexpected error happened while querying wellbore list.', type: ToastTypes.ERROR });
    });
  },
  // #endregion

  // #region addWellboreList
  [actions.addWellboreListRequested]: (state) => {
    return produce(state, () => {
      addToast({ text: 'Creating new wellbore list', type: ToastTypes.INFO });
    });
  },
  [actions.addWellboreListSucceeded]: (state, action) => {
    const newWellboreList = action.payload;
    return produce(state, (draft) => {
      draft.wellboreLists.push(newWellboreList);
      draft.selectedWellboreListId = newWellboreList.id;
      draft.editedWellboreListId = newWellboreList.id;
      addToast({ text: 'New wellbore list created', type: ToastTypes.SUCCESS });
    });
  },
  [actions.addWellboreListFailed]: (state) => {
    return produce(state, () => {
      addToast({ text: 'An unexpected error happened while creating new wellbore list.', type: ToastTypes.ERROR });
    });
  },
  // #endregion

  // #region renameWellboreList
  [actions.renameWellboreListRequested]: (state) => {
    return produce(state, () => {
    });
  },

  [actions.renameWellboreListSucceeded]: (state, action) => {
    const { wellboreListId, newName } = action.payload;
    return produce(state, (draft) => {
      const wellboreList = draft.wellboreLists.filter((i) => i.id === wellboreListId);
      wellboreList[0].name = newName;
    });
  },

  [actions.renameWellboreListFailed]: (state) => {
    return produce(state, () => {
      addToast({ text: 'An unexpected error happened while renaming wellbore list.', type: ToastTypes.ERROR });
    });
  },
  // #endregion renameWellboreList

  // #region removeWellboreList
  [actions.removeWellboreListRequested]: (state) => {
    return produce(state, () => {
      addToast({ text: 'Deleting wellbore list', type: ToastTypes.INFO });
    });
  },
  [actions.removeWellboreListSucceeded]: (state, action) => {
    const { wellboreListId } = action.payload;
    const defaultWellboreListId = state.wellboreLists[0].id;

    return produce(state, (draft) => {
      addToast({ text: 'Deletion of wellborelist success', type: ToastTypes.SUCCESS });
      draft.selectedWellboreListId = defaultWellboreListId;
      draft.wellboreLists = [...state.wellboreLists.filter((i) => i.id !== wellboreListId)];
    });
  },
  [actions.removeWellboreListFailed]: (state) => {
    return produce(state, () => {
      addToast({ text: 'An unexpected error happened while deleting wellbore list.', type: ToastTypes.ERROR });
    });
  },
  // #endregion removeWellboreList

  [actions.selectWellboreList]: (state, action) => ({
    ...state,
    selectedWellboreListId: action.payload,
  }),

  [actions.changeEditedWellboreList]: (state, action) => {
    const { wellboreListId, isEdited } = action.payload;
    return {
      ...state,
      editedWellboreListId: isEdited ? wellboreListId : -1,
    };
  },

  // #region addWellboreToMyLists
  [actions.addWellboreToMyListsSucceeded]: (state, action) => {
    const wellboreInfo = action.payload;
    return produce(state, (draft) => {
      addToast({ text: 'Adding wellbore to list succeeded', type: ToastTypes.SUCCESS });
      const wellboreAlreadyInList = draft.wellboreLists[0]
        .wellbores
        .find((x) => x.uniqueWellboreIdentifier === wellboreInfo.uniqueWellboreIdentifier);

      if (!wellboreAlreadyInList) {
        draft.wellboreLists[0].wellbores.push(wellboreInfo);
      }
    });
  },
  // #endregion

  // #region addWellboreToSpecificList
  [actions.addWellboreToSpecificListRequested]: (state) => {
    return produce(state, () => {
      addToast({ text: 'Adding wellbore to list', type: ToastTypes.INFO });
    });
  },

  [actions.addWellboreToSpecificListSucceeded]: (state, action) => {
    const { wellboreListId, wellboreInfo } = action.payload;
    return produce(state, (draft) => {
      addToast({ text: 'Adding wellbore to list succeeded', type: ToastTypes.SUCCESS });
      const tragetWellboreList = draft.wellboreLists
        .find((y) => y.id === wellboreListId);

      const wellboreAlreadyInList = tragetWellboreList
        .wellbores
        .find((x) => x.uniqueWellboreIdentifier === wellboreInfo.uniqueWellboreIdentifier);

      if (!wellboreAlreadyInList) {
        tragetWellboreList.wellbores.push(wellboreInfo);
      }
    });
  },

  [actions.addWellboreToSpecificListFailed]: (state) => {
    return produce(state, () => {
      addToast({ text: 'An unexpected error happened while adding wellbore to list.', type: ToastTypes.ERROR });
    });
  },
  // #endregion

  // #region removeWellbore
  [actions.removeWellboreRequested]: (state, action) => {
    const { wellboreListId, uniqueWellboreIdentifier } = action.payload;
    return produce(state, (draft) => {
      addToast({ text: 'Removing wellbore from list', type: ToastTypes.INFO });
      const wellboreList = draft.wellboreLists.find((x) => x.id === wellboreListId);

      if (!wellboreList) return;
      const wellboreToBeRemoved = wellboreList.wellbores.find(
        (i) => i.uniqueWellboreIdentifier === uniqueWellboreIdentifier,
      );
      wellboreToBeRemoved.isDisabled = true;
    });
  },

  [actions.removeWellboreSucceeded]: (state, action) => {
    const { wellboreListId, uniqueWellboreIdentifier } = action.payload;
    return produce(state, (draft) => {
      addToast({ text: 'Removing of wellbore from list succeeded', type: ToastTypes.SUCCESS });
      const wellboreList = draft.wellboreLists.find((x) => x.id === wellboreListId);

      if (!wellboreList) return;
      wellboreList.wellbores.splice(wellboreList.wellbores.findIndex(
        (i) => i.uniqueWellboreIdentifier === uniqueWellboreIdentifier,
      ), 1);
    });
  },

  [actions.removeWellboreFailed]: (state, action) => {
    const { wellboreListId, uniqueWellboreIdentifier } = action.payload;
    return produce(state, (draft) => {
      addToast({ text: 'An unexpected error happened while removing wellbore from list.', type: ToastTypes.ERROR });
      const wellboreList = draft.wellboreLists.find((x) => x.id === wellboreListId);

      if (!wellboreList) return;
      const wellboreToBeRemoved = wellboreList.wellbores.find(
        (i) => i.uniqueWellboreIdentifier === uniqueWellboreIdentifier,
      );
      wellboreToBeRemoved.isDisabled = false;
    });
  },
  // #endregion

  // #region moveWellbore
  [actions.moveWellboreRequested]: (state) => {
    return produce(state, () => {
      // addToast({ text: 'Moving wellbore to new wellbore list', type: ToastTypes.INFO });
    });
  },
  [actions.moveWellboreSucceeded]: (state, action) => {
    const {
      sourceWellboreListId, targetWellboreListId, uniqueWellboreIdentifier, targetListWellboreCount,
    } = action.payload;
    return produce(state, (draft) => {
      addToast({ text: 'Moving of wellbore to new wellbore list succeeded', type: ToastTypes.SUCCESS });

      // Find objects by id
      const sourceWellboreList = draft.wellboreLists.find((x) => x.id === sourceWellboreListId);
      const targetWellboreList = draft.wellboreLists.find((x) => x.id === targetWellboreListId);

      const wellbore = sourceWellboreList.wellbores.find(
        (x) => x.uniqueWellboreIdentifier === uniqueWellboreIdentifier,
      );

      // Remove wellbore from source wellbore list
      sourceWellboreList.wellbores = sourceWellboreList
        .wellbores
        .filter((x) => x.uniqueWellboreIdentifier !== uniqueWellboreIdentifier);

      // Update target wellbore list
      if (targetWellboreList.wellbores) {
        if (!targetWellboreList.wellbores.find(
          (x) => x.uniqueWellboreIdentifier === uniqueWellboreIdentifier,
        )) {
          // if wellbores are already downloaded and moved wellbore is not already in the list
          // then add moved wellbore
          targetWellboreList.wellbores.push(wellbore);
        }
      } else {
        // if wellbores are not downloaded then increment counter
        targetWellboreList.wellboreCount = targetListWellboreCount;
      }
    });
  },
  [actions.moveWellboreFailed]: (state) => {
    return produce(state, () => {
      addToast({ text: 'An unexpected error happened while moving wellbore list.', type: ToastTypes.ERROR });
    });
  },
  // #endregion

  // #region getSelectedWellboreListContents
  [actions.getSelectedWellboreListContentsRequested]: (state) => {
    return produce(state, (draft) => {
      draft.wellboreListLoading = true;
    });
  },
  [actions.getSelectedWellboreListContentsSucceeded]: (state, action) => {
    const { wellboreListId, wellbores } = action.payload;

    return produce(state, (draft) => {
      draft.wellboreListLoading = false;
      const draftSelectedWellboreList = draft.wellboreLists
        .filter((i) => i.id === wellboreListId)[0];
      draftSelectedWellboreList.wellbores = wellbores;
    });
  },
  [actions.getSelectedWellboreListContentsFailed]: (state) => {
    return produce(state, (draft) => {
      draft.wellboreListLoading = false;
      addToast({ text: 'An unexpected error happened while getting wellbores.', type: ToastTypes.ERROR });
    });
  },
  // #endregion

  // #region getRecommendedWellbores
  [actions.getRecommendedWellboresRequested]: (state) => {
    return produce(state, (draft) => {
      draft.recommendedWellboresLoading = true;
    });
  },
  [actions.getRecommendedWellboresSucceeded]: (state, action) => {
    const { wellboreListId, recommendedWellbores } = action.payload;

    return produce(state, (draft) => {
      draft.recommendedWellboresLoading = false;
      if (draft.selectedWellboreListId === wellboreListId) {
        draft.recommendedWellbores = recommendedWellbores;
      }
    });
  },
  [actions.getRecommendedWellboresFailed]: (state) => {
    return produce(state, (draft) => {
      draft.recommendedWellboresLoading = false;
      addToast({ text: 'An unexpected error happened while getting recommended wellbores.', type: ToastTypes.ERROR });
    });
  },
  // #endregion

}, defaultState);
