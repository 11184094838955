import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import Error from '@material-ui/icons/Error';
import { GoToDiv } from '../../utils/main';
import WellPath3D, { CreatePlotName } from '../visualization/WellPath3D';

export default class ImportWellpath extends Component {
  static propTypes = {
    sourceWellPathName: PropTypes.string.isRequired,
    afterParsingCSV: PropTypes.func.isRequired,
    loading: PropTypes.func.isRequired,
    isInputValid: PropTypes.bool.isRequired,
    inputErrorMessage: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      importedFileData: this.props.sourceWellPathCoordinates,
      importedFileName: this.props.sourceWellPathName,
      importedCoordinates: this.props.sourceWellPathCoordinates,
      plot: this.props.sourceWellPathCoordinates.length > 0,
      componentId: `importCSV_${this.props.name ? this.props.name : ''}`,
    };

    this.wellPathPlot = React.createRef();
  }

  reset = () => {
    this.setState({
      importedFileData: null,
      importedFileName: null,
      importedCoordinates: [],
      plot: false,
    });

    document.getElementById(this.state.componentId).value = '';
    this.props.onWellPathReset();
  }

  handleInputChange = (event) => {
    const { files } = event.target;
    const file = files[0];

    if (!file) {
      return;
    }

    if (file.type !== 'text/plain') {
      alert('The input file should be a text file with point coordinates!');
      return;
    }

    this.loadFile(file);
  }

  loadFile = (file) => {
    this.props.loading(true);
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      this.loadPlot(`${file.name} (${file.type})`, fileReader.result);
    };

    fileReader.readAsText(file);
  }

  loadPlot = (name, data, additionalInformation) => {
    this.setState({
      importedFileData: data,
      importedFileName: name,
      importedCoordinates: additionalInformation || data,
    });

    this.props.afterParsingCSV(this.props.type, name, additionalInformation || data);
    this.props.loading(false);
    if (!this.state.plot) {
      this.setState({
        plot: true,
      });
    } else {
      this.wellPathPlot.current.drawVisualization();
    }
    GoToDiv(CreatePlotName(`${this.state.importedFileName}${this.state.componentId}`));
  }

  render() {
    return (
      <div>
        <div className="row justify-content-center">
          <div className={this.state.importedFileName !== null ? 'col-sm-10' : 'col-sm-11'}>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id={this.state.componentId}
                onChange={this.handleInputChange}
              />
              <label className={`custom-file-label ${!this.props.isInputValid ? 'invalid' : ''}`} htmlFor={this.state.componentId}>
                {this.state.importedFileName ? this.state.importedFileName : 'Select a file'}
              </label>
              {!this.props.isInputValid
                && <span style={{ color: 'red', display: 'inline', fontSize: '0.75rem' }}>{this.props.inputErrorMessage}</span>}
            </div>
          </div>
          <div className="col-sm-2">
            {this.state.importedFileName !== null && (
            <button
              type="button"
              className="btn btn-dark"
              onClick={() => this.reset()}
            >
              x
            </button>
            )}
          </div>
        </div>
        <div className="row" style={{ overflow: 'auto' }}>
          {this.state.plot && (
          <WellPath3D
            traces={[{ name: this.state.importedFileName, data: this.state.importedFileData }]}
            name={this.state.importedFileName}
            ref={this.wellPathPlot}
          />
          )}
        </div>
      </div>
    );
  }
}
