import { getDepartment } from '../graph/api-methods';
import { getAppInsightsInstrumentationKey } from '../api/api-methods';
import {
  isAppInsightsInitialized, initAppInsights,
} from './appInsights';

const GETAPPINSIGHTSINSTRUMENTATIONKEY_URL = '/api/GetAppInsightsInstrumentationKey';
const GETDEPARTMENT_URL = '/v1.0/me?$select=department';

const loadDepartmentInfo = (component) => getDepartment().then((e) => {
  if (e && e.body) {
    const { department } = e.body;
    return department;
  }
  const message = 'Could not load department information for app to start properly.';
  // app insights is not initialized at this stage, so no need to send error
  component.showError('', message, null);
}, (r) => {
  component.showError(GETDEPARTMENT_URL,
    `Could not load department information to start app! ${r ? r.message : ''}`, null);
});

const loadAppInsightsKey = (component) => getAppInsightsInstrumentationKey().then((e) => {
  if (e && e.body) {
    return e.body.appInsigthsInstrumentationKey;
  }
  const message = 'Could not load App Insights Instrumentation Key for app to start properly.';
  // app insights is not initialized at this stage, so no need to send error
  component.showError('', message, null);
}, (r) => {
  component.showError(GETAPPINSIGHTSINSTRUMENTATIONKEY_URL,
    `Could not load App Insights Instrumentation Key to start app! ${r ? r.message : ''}`, null);
});

// Takes a 'component' which has the .showError function.
export const ensureAppInsightsInitialization = (component) => {
  // App insights will remain initialized when clicking pages, but will be nuked by refreshing page.
  if (!isAppInsightsInitialized()) {
    return loadDepartmentInfo(component)
      .then((department) => loadAppInsightsKey(component)
        .then((appInsightsKey) => initAppInsights(appInsightsKey, department)));
  }
  return Promise.resolve();
};
