import Button from '@material-ui/core/Button';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './RecommendedWellboreItem.css';
import { formatDate } from '../../utils/dateUtils';
import { formatDepth } from '../../utils/formatters';

class RecommendedWellboreItem extends Component {
  hanldeAddToWellboreList = () => {
    this.props.onAddToWellboreListClick(this.props.wellbore.uniqueWellboreIdentifier);
  }

  render() {
    return (
      <div className="recommended-wellbore-card col-md-2">
        <div className="">
          <h2>{this.props.wellbore.uniqueWellboreIdentifier}</h2>
          <div>
            Depth:
            {' '}
            {(this.props.wellbore.totalDepthMd && this.props.wellbore.totalDepthMd !== 0) ? `${formatDepth(this.props.wellbore.totalDepthMd)} m` : 'N/A'}
          </div>
          {this.props.wellbore.rigs && (
          <div>
            Rigs:
            {' '}
            {this.props.wellbore.rigs.join(', ')}
          </div>
          )}
          <div>
            Last section
            {(this.props.wellbore.isLastSectionFromAnotherWellboreInSameWellboreTree) ? ' (from parent wellbore):' : ':'}
            {' '}
            {(this.props.wellbore.lastSection) ? `${this.props.wellbore.lastSectionName} at ${formatDate(this.props.wellbore.lastSection)}` : 'N/A'}
          </div>
          <div>
            <Button className="recommended-wellbore-card-button" color="primary" onClick={() => { this.hanldeAddToWellboreList(); }}>Add to list</Button>
          </div>
        </div>
      </div>
    );
  }
}

RecommendedWellboreItem.propTypes = {
  wellbore: PropTypes.object.isRequired,
  onAddToWellboreListClick: PropTypes.func.isRequired,
};

export default RecommendedWellboreItem;
