import { toast } from 'react-toastify';

export const ToastTypes = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
};

export const addToast = (toastParam) => {
  switch (toastParam.type) {
    case ToastTypes.INFO:
      toast.info(toastParam.text);
      break;
    case ToastTypes.WARNING:
      toast.warning(toastParam.text);
      break;
    case ToastTypes.ERROR:
      toast.error(toastParam.text);
      break;
    case ToastTypes.SUCCESS:
      toast.success(toastParam.text);
      break;
    default:
      toast(toastParam.text);
  }
};
