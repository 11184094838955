import React, { Component } from 'react';
import PropTypes from 'prop-types';

const defaultValues = [10, 50, 100, 500, 1000, 5000];

// This is a basic component for selecting maximum displayed number of results whether filters are applied or not
export default class NumberOfResultsDisplayedFilter extends Component {
  static propTypes = {
    totalResultsCount: PropTypes.number.isRequired,
    filteredResultsCount: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    filtersApplied: PropTypes.bool.isRequired,
    maxDisplayed: PropTypes.number.isRequired,
    setMaxDisplayed: PropTypes.func.isRequired,
  };

  componentDidUpdate = () => {
    const { maxDisplayed, filteredResultsCount, setMaxDisplayed } = this.props;
    if (maxDisplayed > filteredResultsCount) {
      setMaxDisplayed(filteredResultsCount);
    } else if (maxDisplayed < 10 && filteredResultsCount >= 10) {
      setMaxDisplayed(10);
    }
  }

  render() {
    const {
      totalResultsCount,
      filteredResultsCount,
      onChange,
      filtersApplied,
      maxDisplayed,
    } = this.props;

    const totalCount = filtersApplied ? filteredResultsCount : totalResultsCount;
    const values = defaultValues.filter((val) => val <= totalCount);

    if (values.indexOf(totalCount) === -1) {
      values.push(totalCount);
    }

    if (values.indexOf(maxDisplayed) === -1) {
      values.push(maxDisplayed);
    }

    return (
      <select className="custom-select" onChange={onChange} value={maxDisplayed}>
        {values.sort((a, b) => a - b).map((val) => <option key={val} value={val}>{val}</option>)}
      </select>
    );
  }
}
