import { createAction } from 'redux-actions';

// Ranking Methods
export const validateSearchInputs = createAction('Search/VALIDATE_SEARCH_INPUTS');
export const setRankingOn = createAction('Search/SET_RANKINGON');
export const setRankingWeight = createAction('Search/SET_RANKINGWEIGHT');
export const setRankingInput = createAction('Search/SET_RANKINGINPUT');
export const setTubingRankingInputAverageSize = createAction('Search/SET_TUBING_RANKINGINPUT_AVERAGE_SIZE');
export const setTubingRankingInputMainSize = createAction('Search/SET_TUBING_RANKINGINPUT_MAIN_SIZE');
export const setTubingRankingInputSecondarySize = createAction('Search/SET_TUBING_RANKINGINPUT_SECONDARY_SIZE');
export const setCasingExitSize = createAction('Search/SET_CASING_EXIT_SIZE');

export const addRankingInput = createAction('Search/ADD_RANKINGINPUT');
export const removeRankingInput = createAction('Search/REMOVE_RANKINGINPUT');
export const clearRankingInput = createAction('Search/CLEAR_RANKINGINPUT');
export const modifyValueProperty = createAction('Search/MODIFY_VALUEPROPERTY');
export const setRankingAdditionalDetails = createAction('Search/SET_RANKINGADDITIONALDETAILS');
export const setSelectedWellboreName = createAction('Search/SET_SELECTED_WELLBORE_NAME');
export const clearRankingMethodInput = createAction('Search/CLEAR_RANKING_METHOD_INPUT');

// Source WellPath
export const setSourceWellPathUID = createAction('Search/SET_SOURCEWELLPATHUID');
export const setSourceWellPathFileName = createAction('Search/SET_SOURCEWELLPATHFILENAME');
export const setAvailableWellBores = createAction('Search/SET_AVAILABLEWELLBORES');

// Process
export const setLoading = createAction('Search/SET_LOADING');
export const setLoadingFilters = createAction('Search/SET_LOADINGFILTERS');
export const setReceivingResults = createAction('Search/SET_RECEIVINGRESULTS');
export const setLoadingResults = createAction('Search/SET_LOADINGRESULTS');
export const setQueryId = createAction('Search/SET_QUERYID');
export const setFilteredListSize = createAction('Search/SET_FILTEREDLISTSIZE');
export const addSelectedWellPathForWellLine = createAction('Search/ADD_SELECTEDWELLPATHFORWELLLINE');
export const removeSelectedWellPathForWellLine = createAction('Search/REMOVE_SELECTEDWELLPATHFORWELLLINE');
export const clearSelectedWellPathsForWellLine = createAction('Search/CLEAR_SELECTEDWELLPATHSFORWELLLINE');
export const setInfo = createAction('Search/SET_INFO');
export const setError = createAction('Search/SET_ERROR');
export const setMaxDisplayed = createAction('Search/SET_MAXDISPLAYED');

// Results
export const setResults = createAction('Search/SET_RESULTS');
export const clearResults = createAction('Search/CLEAR_RESULTS');
export const addResults = createAction('Search/ADD_RESULTS');
