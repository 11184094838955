import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import Error from '@material-ui/icons/Error';

// This is a basic component for all weighted ranking methods
export default class BaseMultipleInputRankingMethod extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    method: PropTypes.string.isRequired,
    methodSelected: PropTypes.func.isRequired,
    handleSelectMethod: PropTypes.func.isRequired,
    weight: PropTypes.func.isRequired,
    handleChangeWeight: PropTypes.func.isRequired,
    weightIsValid: PropTypes.bool.isRequired,
    weightErrorMessage: PropTypes.string.isRequired,
  };

  toggleCheckbox = (status) => {
    this.props.handleSelectMethod(this.props.type, this.props.method, status);
  }

  render() {
    const {
      name,
      type,
      method,
      methodSelected,
      handleSelectMethod,
      weight,
      handleChangeWeight,
      weightIsValid,
      weightErrorMessage,
    } = this.props;

    return (
      <div className="row input-group mb-3">
        <div className="input-group-prepend">
          <div className="col">
            <span className="">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={name}
                  name={name}
                  checked={methodSelected(type, method)}
                  onChange={(event) => handleSelectMethod(type, method, event.target.checked)}
                />
                <label className="custom-control-label" htmlFor={name}>{name}</label>
              </div>
              <div className="col">
                {(this.props.multipleRanking(type) && methodSelected(type, method)) && (
                <div>
                  <span className="text-info">Weight </span>
                  {!weightIsValid
                  && (
                  <Tooltip title={weightErrorMessage} aria-label={weightErrorMessage} placement="bottom-start">
                    <span style={{ color: 'red', display: 'inline' }}><Error /></span>
                  </Tooltip>
                  )}
                  <input
                    type="number"
                    min="1"
                    max="10"
                    placeholder="Weight (from 1 to 10)"
                    value={weight(type, method)}
                    onChange={(event) => handleChangeWeight(type, method, event.target.value)}
                    style={{ borderRadius: '10px', padding: '0 10px 0 10px' }}
                  />
                </div>
                )}
              </div>
            </span>
          </div>
        </div>
      </div>
    );
  }
}
