import { stateKeys } from '../../types';

// Depth filter
export const getMinDepth = (state) => state[stateKeys.FILTERS].minDepth;
export const getMaxDepth = (state) => state[stateKeys.FILTERS].maxDepth;
export const getIsDepthFilterValid = (state) => state[stateKeys.FILTERS].isDepthFilterValid;
export const getDepthFilterErrorMessage = (state) => state[stateKeys.FILTERS].depthFilterErrorMessage;

// Minimum score filter
export const getMinimumScore = (state, type) => state[stateKeys.FILTERS].minimumScore[type].value;
export const getIsMinimumScoreFilterValid = (state, type) => state[stateKeys.FILTERS].minimumScore[type].isMinimumScoreFilterValid;
export const getMinimumScoreFilterErrorMessage = (state, type) => state[stateKeys.FILTERS].minimumScore[type].minimumScoreFilterErrorMessage;

// Date interval filter
export const getMinDate = (state) => state[stateKeys.FILTERS].minDate;
export const getMaxDate = (state) => state[stateKeys.FILTERS].maxDate;
export const getDateFilterType = (state) => state[stateKeys.FILTERS].dateFilterType;

// Field filter
export const getAvailableFields = (state) => state[stateKeys.FILTERS].availableFields;
export const getSelectedFields = (state) => state[stateKeys.FILTERS].selectedFields;

// Formation filter
export const getAvailableFormations = (state) => state[stateKeys.FILTERS].availableFormations;
export const getSelectedFormations = (state) => state[stateKeys.FILTERS].selectedFormations;

// Section filter
export const getAvailableSections = (state, type) => state[stateKeys.FILTERS].availableSections[type];
export const getSelectedSections = (state, type) => state[stateKeys.FILTERS].selectedSections[type];

// Keyword filter
export const getAvailableKeywords = (state) => state[stateKeys.FILTERS].availableKeywords;
export const getSelectedKeywords = (state) => state[stateKeys.FILTERS].selectedKeywords;

// Rig filter
export const getAvailableRigs = (state) => state[stateKeys.FILTERS].availableRigs;
export const getSelectedRigs = (state) => state[stateKeys.FILTERS].selectedRigs;

// Number of results for ranking type (Wellbore or Section)
export const getMaxResults = (state, type) => state[stateKeys.FILTERS].maxResults[type];

// Exclude
export const isExcludeOn = (state, type) => state[stateKeys.FILTERS].excludeOn[type];

// Section groups
export const getAvailableSectionGroups = (state) => state[stateKeys.FILTERS].availableSectionGroups;
export const getSelectedSectionGroups = (state) => state[stateKeys.FILTERS].selectedSectionGroups;

export const getRankingSectionGroups = (state) => state[stateKeys.FILTERS].rankingSectionGroups;
