import { createAction } from 'redux-actions';

// Depth filter
export const setMinDepth = createAction('Filters/SET_MINDEPTH');
export const setMaxDepth = createAction('Filters/SET_MAXDEPTH');

// Minimum score filter
export const setMinimumScore = createAction('Filters/SET_MINIMUM_SCORE');

// Date interval filter
export const setMinDate = createAction('Filters/SET_MINDATE');
export const setMaxDate = createAction('Filters/SET_MAXDATE');
export const setDateFilterType = createAction('Filters/SET_DATE_FILTER_TYPE');

// Field filter
export const setAvailableFields = createAction('Filters/SET_AVAILABLEFIELDS');
export const addField = createAction('Filters/ADD_FIELD');
export const removeField = createAction('Filters/REMOVE_FIELD');

// Formation filter
export const setAvailableFormations = createAction('Filters/SET_AVAILABLEFORMATIONS');
export const addFormation = createAction('Filters/ADD_FORMATION');
export const removeFormation = createAction('Filters/REMOVE_FORMATION');

// Section Filter
export const setAvailableSections = createAction('Filters/SET_AVAILABLESECTIONS');
export const addSection = createAction('Filters/ADD_SECTION');
export const removeSection = createAction('Filters/REMOVE_SECTION');

// Keyword Filter
export const setAvailableKeywords = createAction('Filters/SET_AVAILABLEKEYWORDS');
export const addKeyword = createAction('Filters/ADD_KEYWORD');
export const removeKeyword = createAction('Filters/REMOVE_KEYWORD');

// Rig filter
export const setAvailableRigs = createAction('Filters/SET_AVAILABLERIGS');
export const addRig = createAction('Filters/ADD_RIG');
export const removeRig = createAction('Filters/REMOVE_RIG');

// Number of results
export const setMaxResults = createAction('Filters/SET_MAXRESULTS');

// Exclude
export const setExcludeOn = createAction('Filters/SET_EXCLUDEON');

// Section group filter
export const setAvailableSectionGroups = createAction('Filters/SET_AVAILABLESECTIONGROUPS');
export const setRankingSectionGroups = createAction('Filters/SET_RANKINGSECTIONGROUPS');
export const addSectionGroup = createAction('Filters/ADD_SECTIONGROUP');
export const removeSectionGroup = createAction('Filters/REMOVE_SECTIONGROUP');
