import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import withAuthorization from '../components/hoc/withAuthorization';
import MainPage from '../containers/MainPage';
import MyListsPage from '../containers/MyListsPage';
import GuidesPage from '../containers/GuidesPage';
import UnauthorizedPage from '../components/common/UnauthorizedPage';
import SignedOutPage from '../containers/SignedOutPage';
import LogInPage from '../containers/LogInPage';
import { userRoles, rankingTypes } from '../types';

export default () => (
  <Switch>
    <Route exact path="/unauthorized" component={withRouter(UnauthorizedPage)} />
    <Route exact path="/signedout" component={withRouter(SignedOutPage)} />
    <Route exact path="/loginpage" component={LogInPage} />
    <Route exact path="/" component={withRouter((props) => <MainPage {...props} type={rankingTypes.WELLBORE_RANKING} />)} />
    <Route exact path="/MyLists" component={withRouter(MyListsPage)} />
    <Route exact path="/Guides" component={withRouter(GuidesPage)} />
    <Route exact path="/RankOnWellbore/:wellboreId" component={withRouter((props) => <MainPage {...props} type={rankingTypes.WELLBORE_RANKING} />)} />
  </Switch>
);
