import { ComputeMultipleCylinderCoordinates } from './VectorOperations';
import { hoverTemplate } from './WellPath3D';

const FindStartEndIndexes = (section, md) => {
  let start = 0;
  let end = md.length - 1;
  if (section.startDepth >= 0 && section.endDepth >= section.startDepth) {
    let currentIndex = 0;
    let currentDepth = md[currentIndex];
    do {
      if (currentDepth <= section.startDepth) {
        start = currentIndex;
      } else {
        end = currentIndex;
      }
      currentIndex += 1;
      currentDepth = md[currentIndex];
    } while (currentDepth <= section.endDepth);
  }
  return [start, end];
};

export const GenerateSectionPlot = (section, xInput, yInput, zInput, md, color) => {
  const [start, end] = FindStartEndIndexes(section, md);
  const [X, Y, Z] = ComputeMultipleCylinderCoordinates(xInput, yInput, zInput, start, end, section.numericValue);
  const result = {
    name: section.sectionName,
    x: X,
    y: Y,
    z: Z,
    type: 'surface',
    showscale: false,
    colorscale: [[0, color], [1, color]],
    opacity: 0.5,
    hovertemplate: hoverTemplate,
  };
  return result;
};
