import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Recommendations.css';
import RecommendedWellboreItem from './RecommendedWelboreItem';
import Loader from '../common/Loader';

class Recommendations extends Component {
  handleAddToWellboreList = (uniqueWellboreIdentifier) => {
    this.props.onAddToWellboreListClick(uniqueWellboreIdentifier);
  }

  render() {
    return (
      <div className="container-fluid recommendations">
        <h2>People who selected these wellbores have also selected</h2>
        {this.props.isLoading
          && (
          <div>
            <Loader id="recommendedWellboresLoader" size="s" />
          </div>
          )}
        {!this.props.isLoading && (
        <div className="recommendations-scroll-panel">
          <div className="row flex-nowrap">
            {this.props.wellbores.map((wellbore) => (
              <RecommendedWellboreItem
                wellbore={wellbore}
                onAddToWellboreListClick={this.handleAddToWellboreList}
                key={wellbore.uniqueWellboreIdentifier}
              />
            ))}
          </div>
        </div>
        )}
      </div>
    );
  }
}

Recommendations.propTypes = {
  wellbores: PropTypes.array.isRequired,
  onAddToWellboreListClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Recommendations;
