import React from 'react';

const LogInPage = (props) => (
  <div className="container-fluid" style={{ paddingTop: '100px' }}>
    <div className="jumbotron" style={{ marginTop: 16, textAlign: 'center' }}>
      <h3>Logging in to DIWA Reference Wellbores application...</h3>
    </div>
  </div>
);

export default LogInPage;
