import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../store';
import * as selectors from '../store/selectors';
import {
  targetDivs, rankingConstants, rankingMethods, rankingTypes, sectionTypes, propertyNames,
} from '../types';
import Panel from '../components/common/Panel';
import BaseMultipleInputRankingMethod from '../components/search/BaseMultipleInputRankingMethod';
import ImportWellpath from '../components/search/importWellpath';
import RankingInput from '../components/search/RankingInput';
import BaseRankingOption from '../components/search/BaseRankingOption';
import OutlineButton from '../components/common/OutlineButton';
import Loader from '../components/common/Loader';
import BaseDropdownWithButton from '../components/search/BaseDropdownWithButton';

export const IMPORT_WELLPATH_REF = 'importWellPathElement';
export const SELECT_WELLBORE_REF = 'selectWellboreElement';

class WellboreRankingPanel extends Component {
  handleWellPathReset = () => {
    this.props.onWellPathReset(rankingTypes.WELLBORE_RANKING);
  }

  render() {
    const {
      // Passed from Parent
      refs,
      isMultipleRankingMethodsSelected,
      onWellPathLoaded,
      loading,
      handleSelectWellbore,
      areRankingParametersApplied,
      clearRankingInput,
      handleStartSearch,

      // Store
      isElementVisible,
      toggleElementVisibility,
      loadingResults,
      loadingFilters,
      isRankingOn,
      setMaxResults,
      setRankingOn,
      setRankingWeight,
      getRankingWeight,
      setRankingInput,
      getRankingInput,
      getSelectedWellboreName,
      availableFormations,
      availableSections,
      availableKeywords,
      availableWellBores,
      maxResults,
      sourceWellPathFileName,
      linkedWellboreId,
      wellboreRankingErrorMessage,
      wellboreRankingEnabled,
      getRankingInputParams,
    } = this.props;

    const errorMessageOnSearchButtionTooltip = wellboreRankingErrorMessage !== '' ? wellboreRankingErrorMessage : 'Please correct the search criteria';

    const geometricInputParams = getRankingInputParams(rankingTypes.WELLBORE_RANKING, rankingMethods.GEOMETRIC);
    const geometricInputIsValid = geometricInputParams.isValid === undefined || geometricInputParams.isValid;
    const geometricInputErrorMessage = geometricInputIsValid ? '' : geometricInputParams.errorMessage;
    const geometricWeightIsValid = geometricInputParams.weightIsValid === undefined || geometricInputParams.weightIsValid;
    const geometricWeightErrorMessage = geometricWeightIsValid ? '' : geometricInputParams.weightErrorMessage;

    const formationsInputParams = getRankingInputParams(rankingTypes.WELLBORE_RANKING, rankingMethods.FORMATION);
    const formationsInputIsValid = formationsInputParams.isValid === undefined || formationsInputParams.isValid;
    const formationsInputErrorMessage = formationsInputIsValid ? '' : formationsInputParams.errorMessage;
    const formationsWeightIsValid = formationsInputParams.weightIsValid === undefined || formationsInputParams.weightIsValid;
    const formationsWeightErrorMessage = formationsWeightIsValid ? '' : formationsInputParams.weightErrorMessage;

    const drillingSectionsInputParams = getRankingInputParams(rankingTypes.WELLBORE_RANKING, rankingMethods.DRILLINGSECTION);
    const drillingSectionsInputIsValid = drillingSectionsInputParams.isValid === undefined || drillingSectionsInputParams.isValid;
    const drillingSectionsInputErrorMessage = drillingSectionsInputIsValid ? '' : drillingSectionsInputParams.errorMessage;
    const drillingSectionsWeightIsValid = drillingSectionsInputParams.weightIsValid === undefined || drillingSectionsInputParams.weightIsValid;
    const drillingSectionsWeightErrorMessage = drillingSectionsWeightIsValid ? '' : drillingSectionsInputParams.weightErrorMessage;

    const completionSectionsInputParams = getRankingInputParams(rankingTypes.WELLBORE_RANKING, rankingMethods.COMPLETIONSECTION);
    const completionSectionsInputIsValid = completionSectionsInputParams.isValid === undefined || completionSectionsInputParams.isValid;
    const completionSectionsInputErrorMessage = completionSectionsInputIsValid ? '' : completionSectionsInputParams.errorMessage;
    const completionSectionsWeightIsValid = completionSectionsInputParams.weightIsValid === undefined || completionSectionsInputParams.weightIsValid;
    const completionSectionsWeightErrorMessage = completionSectionsWeightIsValid ? '' : completionSectionsInputParams.weightErrorMessage;

    const keywordsInputParams = getRankingInputParams(rankingTypes.WELLBORE_RANKING, rankingMethods.KEYWORD);
    const keywordsInputIsValid = keywordsInputParams.isValid === undefined || keywordsInputParams.isValid;
    const keywordsInputErrorMessage = keywordsInputIsValid ? '' : keywordsInputParams.errorMessage;
    const keywordsWeightIsValid = keywordsInputParams.weightIsValid === undefined || keywordsInputParams.weightIsValid;
    const keywordsWeightErrorMessage = keywordsWeightIsValid ? '' : keywordsInputParams.weightErrorMessage;

    const norwegianStringComparer = new Intl.Collator('no').compare;

    const keywords = availableKeywords.map((x) => x.keyword).sort(norwegianStringComparer);

    return (
      <Panel
        panelId={targetDivs.WELLBORE_RANKING_PANEL}
        title="Wellbore Ranking"
        description="To find reference wells, select the desired ranking method(s) and configure input parameters."
        toggle={toggleElementVisibility}
        isVisible={isElementVisible}
        isLoading={loadingFilters}
      >
        <div>
          <div className="row" style={{ marginLeft: '10px' }}>
            <div className="col-xl-4">
              <BaseMultipleInputRankingMethod
                name={rankingMethods.GEOMETRIC_UI}
                type={rankingTypes.WELLBORE_RANKING}
                method={rankingMethods.GEOMETRIC}
                methodSelected={isRankingOn}
                handleSelectMethod={setRankingOn}
                weight={getRankingWeight}
                handleChangeWeight={setRankingWeight}
                multipleRanking={isMultipleRankingMethodsSelected}
                ref={refs[rankingMethods.GEOMETRIC]}
                weightIsValid={geometricWeightIsValid}
                weightErrorMessage={geometricWeightErrorMessage}
              />
            </div>
            <div className="col-xl-8">
              {isRankingOn(rankingTypes.WELLBORE_RANKING, rankingMethods.GEOMETRIC) && (
              <div style={{ marginBottom: '10px' }}>
                <ImportWellpath
                  type={rankingTypes.WELLBORE_RANKING}
                  afterParsingCSV={onWellPathLoaded}
                  onWellPathReset={this.handleWellPathReset}
                  loading={loading}
                  ref={refs[IMPORT_WELLPATH_REF]}
                  sourceWellPathName={sourceWellPathFileName}
                  sourceWellPathCoordinates={getRankingInput(rankingTypes.WELLBORE_RANKING, rankingMethods.GEOMETRIC)}
                  isInputValid={geometricInputIsValid}
                  inputErrorMessage={geometricInputErrorMessage}
                />
              </div>
              )}
            </div>
          </div>
          <div className="row" style={{ marginLeft: '10px' }}>
            <div className="col-xl-4">
              <BaseMultipleInputRankingMethod
                name={rankingMethods.FORMATION_UI}
                type={rankingTypes.WELLBORE_RANKING}
                method={rankingMethods.FORMATION}
                methodSelected={isRankingOn}
                handleSelectMethod={setRankingOn}
                weight={getRankingWeight}
                handleChangeWeight={setRankingWeight}
                multipleRanking={isMultipleRankingMethodsSelected}
                ref={refs[rankingMethods.FORMATION]}
                weightIsValid={formationsWeightIsValid}
                weightErrorMessage={formationsWeightErrorMessage}
              />
            </div>
            <div className="col-xl-6">
              {isRankingOn(rankingTypes.WELLBORE_RANKING, rankingMethods.FORMATION) && (
              <div style={{ marginBottom: '15px' }}>
                {/* <div>Select ranking formation</div> */}
                <div>
                  <RankingInput
                    type={rankingTypes.WELLBORE_RANKING}
                    method={rankingMethods.FORMATION}
                    availableValues={availableFormations}
                    selectedValues={getRankingInput}
                    setValues={setRankingInput}
                    label="Formations"
                    placeholder="Select..."
                    optionLabelProperty={propertyNames.FORMATION_NAME_PROPERTY}
                    isInputValid={formationsInputIsValid}
                    inputErrorMessage={formationsInputErrorMessage}
                  />
                </div>
              </div>
              )}
            </div>
          </div>
          <div className="row" style={{ marginLeft: '10px' }}>
            <div className="col-xl-4">
              <BaseMultipleInputRankingMethod
                name={rankingMethods.DRILLINGSECTION_UI}
                type={rankingTypes.WELLBORE_RANKING}
                method={rankingMethods.DRILLINGSECTION}
                methodSelected={isRankingOn}
                handleSelectMethod={setRankingOn}
                weight={getRankingWeight}
                handleChangeWeight={setRankingWeight}
                multipleRanking={isMultipleRankingMethodsSelected}
                ref={refs[rankingMethods.DRILLINGSECTION]}
                weightIsValid={drillingSectionsWeightIsValid}
                weightErrorMessage={drillingSectionsWeightErrorMessage}
              />
            </div>
            <div className="col-xl-6">
              {isRankingOn(rankingTypes.WELLBORE_RANKING, rankingMethods.DRILLINGSECTION) && (
              <div style={{ marginBottom: '15px' }}>
                <div>
                  <RankingInput
                    type={rankingTypes.WELLBORE_RANKING}
                    method={rankingMethods.DRILLINGSECTION}
                    availableValues={availableSections[sectionTypes.DRILLING]}
                    selectedValues={getRankingInput}
                    setValues={setRankingInput}
                    label="Drilling sections"
                    placeholder="Select..."
                    isInputValid={drillingSectionsInputIsValid}
                    inputErrorMessage={drillingSectionsInputErrorMessage}
                  />
                </div>
              </div>
              )}
            </div>
          </div>
          <div className="row" style={{ marginLeft: '10px' }}>
            <div className="col-xl-4">
              <BaseMultipleInputRankingMethod
                name={rankingMethods.COMPLETIONSECTION_UI}
                type={rankingTypes.WELLBORE_RANKING}
                method={rankingMethods.COMPLETIONSECTION}
                methodSelected={isRankingOn}
                handleSelectMethod={setRankingOn}
                weight={getRankingWeight}
                handleChangeWeight={setRankingWeight}
                multipleRanking={isMultipleRankingMethodsSelected}
                ref={refs[rankingMethods.COMPLETIONSECTION]}
                weightIsValid={completionSectionsWeightIsValid}
                weightErrorMessage={completionSectionsWeightErrorMessage}
              />
            </div>
            <div className="col-xl-6">
              {isRankingOn(rankingTypes.WELLBORE_RANKING, rankingMethods.COMPLETIONSECTION) && (
              <div style={{ marginBottom: '15px' }}>
                <div>
                  <RankingInput
                    type={rankingTypes.WELLBORE_RANKING}
                    method={rankingMethods.COMPLETIONSECTION}
                    availableValues={availableSections[sectionTypes.COMPLETION]}
                    selectedValues={getRankingInput}
                    setValues={setRankingInput}
                    label="Completion sections"
                    placeholder="Select..."
                    isInputValid={completionSectionsInputIsValid}
                    inputErrorMessage={completionSectionsInputErrorMessage}
                  />
                </div>
              </div>
              )}
            </div>
          </div>
          <div className="row" style={{ marginLeft: '10px' }}>
            <div className="col-xl-4">
              <BaseMultipleInputRankingMethod
                name={rankingMethods.KEYWORD_UI}
                type={rankingTypes.WELLBORE_RANKING}
                method={rankingMethods.KEYWORD}
                methodSelected={isRankingOn}
                handleSelectMethod={setRankingOn}
                weight={getRankingWeight}
                handleChangeWeight={setRankingWeight}
                multipleRanking={isMultipleRankingMethodsSelected}
                ref={refs[rankingMethods.KEYWORD]}
                weightIsValid={keywordsWeightIsValid}
                weightErrorMessage={keywordsWeightErrorMessage}
              />
            </div>
            <div className="col-xl-6">
              {isRankingOn(rankingTypes.WELLBORE_RANKING, rankingMethods.KEYWORD) && (
              <div style={{ marginBottom: '15px' }}>
                <div>
                  <RankingInput
                    type={rankingTypes.WELLBORE_RANKING}
                    method={rankingMethods.KEYWORD}
                    availableValues={keywords}
                    selectedValues={getRankingInput}
                    setValues={setRankingInput}
                    label="Keywords"
                    placeholder="Select..."
                    isInputValid={keywordsInputIsValid}
                    inputErrorMessage={keywordsInputErrorMessage}
                  />
                </div>
              </div>
              )}
            </div>
          </div>
          <div className="row" style={{ marginLeft: '10px' }}>
            <div className="col-xl-4">
              <BaseRankingOption
                name="selectWellboreByID"
                label="Or autofill ranking input using an existing wellbore's ID"
                isElementVisible={isElementVisible}
                toggleElementVisibility={toggleElementVisibility}
                inputDivID={targetDivs.SELECT_WELLBORE}
                ref={refs[SELECT_WELLBORE_REF]}
              />
            </div>
            <div className="col-xl-8">
              {isElementVisible(targetDivs.SELECT_WELLBORE) && (
              <div id={targetDivs.SELECT_WELLBORE}>
                <BaseDropdownWithButton
                  name="selectWellboreByIDList"
                  availableValues={availableWellBores}
                  selectedValue={getSelectedWellboreName()}
                  buttonLabel="Load information for this wellbore"
                  buttonClicked={handleSelectWellbore}
                  defaultValue={decodeURIComponent(linkedWellboreId)}
                  defaultValueContains={rankingConstants.PREFIX_LINKED_WELLBORE}
                />
              </div>
              )}
            </div>
          </div>
          <div className="row justify-content-end" style={{ marginTop: '10px' }}>
            {loadingResults && (
            <div className="col-xl-3">
              <span className="alert alert-info">Ranking reference wellbores...</span>
              <Loader id="resultsLoader" size="xs" />
            </div>
            )}
            {areRankingParametersApplied(rankingTypes.WELLBORE_RANKING) && (
            <div className="col-xl-1">
              <OutlineButton
                label="Clear"
                onClick={() => clearRankingInput(rankingTypes.WELLBORE_RANKING)}
              />
            </div>
            )}
            <div className="col-xl-2">
              <OutlineButton
                label="Search & rank"
                onClick={() => handleStartSearch(rankingTypes.WELLBORE_RANKING)}
                disabled={!wellboreRankingEnabled}
                title={!wellboreRankingEnabled ? errorMessageOnSearchButtionTooltip : ''}
              />
            </div>
          </div>
          <div className="row justify-content-end" style={{ marginTop: '10px' }}>
            <div className="col-xl-2">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Max results</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  value={maxResults}
                  onChange={(event) => setMaxResults(rankingTypes.WELLBORE_RANKING, parseInt(event.target.value))}
                  onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      handleStartSearch(rankingTypes.WELLBORE_RANKING);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Panel>
    );
  }
}

const mapStateToProps = (state) => ({
  // General
  isElementVisible: (elementId) => selectors.isElementVisible(state, elementId),
  linkedWellboreId: selectors.getLinkedWellboreId(state),

  // Search
  // --- Ranking Methods
  isRankingOn: (type, method) => selectors.isRankingOn(state, type, method),
  getRankingWeight: (type, method) => selectors.getRankingWeight(state, type, method),
  getRankingInput: (type, method) => selectors.getRankingInput(state, type, method),
  getRankingInputParams: (type, method) => selectors.getRankingInputParams(state, type, method),
  getSelectedWellboreName: () => selectors.getSelectedWellboreName(state, rankingTypes.WELLBORE_RANKING),

  wellboreRankingEnabled: selectors.getWellboreRankingEnabled(state),
  wellboreRankingErrorMessage: selectors.getWellboreRankingErrorMessage(state),

  // --- Source WellPath
  sourceWellPathUID: selectors.getSourceWellPathUID(state),
  sourceWellPathFileName: selectors.getSourceWellPathFileName(state, rankingTypes.WELLBORE_RANKING),
  availableWellBores: selectors.getAvailableWellBores(state),
  // --- Process
  loadingFilters: selectors.isLoadingFilters(state),
  receivingResults: selectors.isReceivingResults(state),
  loadingResults: selectors.isLoadingResults(state),
  queryId: selectors.getQueryId(state),
  filteredListSize: selectors.getFilteredListSize(state),
  selectedWellPathsForWellLine: selectors.getSelectedWellPathsForWellLine(state),
  info: selectors.getInfo(state),
  error: selectors.getError(state),
  maxDisplayed: selectors.getMaxDisplayed(state),

  // Filters
  // --- Formation
  availableFormations: selectors.getAvailableFormations(state),
  // --- Section
  availableSections: {
    [sectionTypes.DRILLING]: selectors.getAvailableSections(state, sectionTypes.DRILLING),
    [sectionTypes.COMPLETION]: selectors.getAvailableSections(state, sectionTypes.COMPLETION),
  },
  // --- Keyword
  availableKeywords: selectors.getAvailableKeywords(state),
  // --- Number of results
  maxResults: selectors.getMaxResults(state, rankingTypes.WELLBORE_RANKING),
});

const mapDispatchToProps = (dispatch) => ({
  // General
  toggleElementVisibility: (elementId) => dispatch(actions.toggleElementVisibility(elementId)),

  // Search
  // --- Ranking Methods
  setRankingOn: (type, method, status) => dispatch(actions.setRankingOn({ type, method, value: status })),
  setRankingWeight: (type, method, value) => dispatch(actions.setRankingWeight({ type, method, value })),
  setRankingInput: (type, method, value) => dispatch(actions.setRankingInput({ type, method, value })),
  // --- Source WellPath
  setSourceWellPathUID: (wpUID) => dispatch(actions.setSourceWellPathUID(wpUID)),
  setSourceWellPathFileName: (type, value) => dispatch(actions.setSourceWellPathFileName({ type, value })),
  setAvailableWellBores: (wps) => dispatch(actions.setAvailableWellBores(wps)),

  // Filters
  // --- Number of results
  setMaxResults: (type, number) => dispatch(actions.setMaxResults({ type, value: number })),
});

WellboreRankingPanel.propTypes = {
  // passed from parent
  refs: PropTypes.object.isRequired,
  isMultipleRankingMethodsSelected: PropTypes.func.isRequired,
  onWellPathLoaded: PropTypes.func.isRequired,
  onWellPathReset: PropTypes.func.isRequired,
  loading: PropTypes.func.isRequired,
  handleSelectWellbore: PropTypes.func.isRequired,
  areRankingParametersApplied: PropTypes.func.isRequired,
  clearRankingInput: PropTypes.func.isRequired,
  handleStartSearch: PropTypes.func.isRequired,

  // Store
  isElementVisible: PropTypes.func.isRequired,
  toggleElementVisibility: PropTypes.func.isRequired,
  loadingResults: PropTypes.bool.isRequired,
  loadingFilters: PropTypes.bool.isRequired,
  isRankingOn: PropTypes.func.isRequired,
  setMaxResults: PropTypes.func.isRequired,
  setRankingOn: PropTypes.func.isRequired,
  setRankingWeight: PropTypes.func.isRequired,
  getRankingWeight: PropTypes.func.isRequired,
  setRankingInput: PropTypes.func.isRequired,
  getRankingInput: PropTypes.func.isRequired,
  getSelectedWellboreName: PropTypes.func.isRequired,
  availableFormations: PropTypes.array.isRequired,
  availableSections: PropTypes.object.isRequired,
  availableKeywords: PropTypes.array.isRequired,
  availableWellBores: PropTypes.array.isRequired,
  maxResults: PropTypes.number.isRequired,
  sourceWellPathFileName: PropTypes.string.isRequired,
  linkedWellboreId: PropTypes.string.isRequired,
  wellboreRankingErrorMessage: PropTypes.string.isRequired,

  wellboreRankingEnabled: PropTypes.bool.isRequired,
  getRankingInputParams: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(WellboreRankingPanel);
