import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class OutlineButton extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string,
  };

  static defaultProps ={
    title: '',
  };

  render() {
    const {
      label, onClick, disabled, title,
    } = this.props;
    return <button type="button" onClick={onClick} title={title} className="btn btn-outline-dark btn-block" disabled={disabled}>{label}</button>;
  }
}
