import React, { Component } from 'react';
import {
  TextField,
  Button,
  IconButton,
  FormLabel,
  DialogContentText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { submitFeedback } from '../../services/api/api-helpers';
import { addToast, ToastTypes } from '../../utils/addToastByType';

class FeedbackModal extends Component {
  static show() {
    FeedbackModal.singletonRef.instanceShow();
  }

  constructor() {
    super();
    FeedbackModal.singletonRef = this;
    this.state = {
      feedbackFiles: [],
      feedbackTitle: '',
      feedbackDescription: '',
    };
  }

  createQueryObject = () => ({
    Title: this.state.feedbackTitle,
    Description: this.state.feedbackDescription,
    Url: window.location.href,
    Browser: this.getBrowser(),
  });

  getBrowser = () => {
    if ((navigator.userAgent.indexOf('Opera') !== -1 || navigator.userAgent.indexOf('OPR')) !== -1) {
      return 'Opera';
    }
    if (navigator.userAgent.indexOf('Edg') !== -1) {
      return 'Edge';
    }
    if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return 'Chrome';
    }
    if (navigator.userAgent.indexOf('Safari') !== -1) {
      return 'Safari';
    }
    if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return 'Firefox';
    }
    if ((navigator.userAgent.indexOf('MSIE') !== -1) || document.documentMode) {
      return 'IE';
    }
    return 'Other';
  }

  handleSubmitFeedbackDialog = () => {
    const query = this.createQueryObject();
    submitFeedback('/api/Feedback', query, this.state.feedbackFiles, 'POST', 'DIWA').then(() => {
      // Provide a toast when feedback has been stored
      this.resetDialogInput();
      addToast({ text: 'Feedback has been saved.', type: ToastTypes.SUCCESS });
    });
  }

  resetDialogInput = () => {
    this.setState({ feedbackTitle: '', feedbackDescription: '', feedbackFiles: [] });
  }

  handleFeedbackFileUpload = (event) => {
    const validFileTypes = ['.jpg', '.jpeg', '.png', '.svg', '.txt', '.csv', '.json', '.pdf'];
    const inputFiles = Array.from(event.target.files);
    const uploadedFiles = this.state.feedbackFiles;
    const invalidFiles = [];
    for (let index = 0; index < inputFiles.length; index += 1) {
      const inputFile = inputFiles[index];
      if (validFileTypes.indexOf(inputFile.name.substr(inputFile.name.lastIndexOf('.')).toLowerCase()) === -1) {
        invalidFiles.push(inputFile);
        addToast({ text: `Removed file with invalid filetype:${inputFile.name}`, type: ToastTypes.WARNING });
      } else if (uploadedFiles.findIndex((x) => x.name === inputFile.name) === -1) {
        uploadedFiles.push(inputFile);
      }
    }
    this.setState(() => ({ feedbackFiles: uploadedFiles }));
  }

  handleRemoveFile = (filename) => {
    this.setState((prevState) => ({ feedbackFiles: prevState.feedbackFiles.filter((x) => x.name !== filename) }));
  }

  instanceShow() {
    $('#FeedbackModal').modal();
  }

  render() {
    return (
      <div className="modal fade" id="FeedbackModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">Improvement and suggestions</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Title*"
                placeholder="Enter a short title here"
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={this.state.feedbackTitle}
                onChange={(e) => this.setState({ feedbackTitle: e.target.value })}
              />
              <TextField
                margin="dense"
                id="name"
                label="Description*"
                fullWidth
                variant="standard"
                placeholder="Enter your description here"
                multiline
                InputLabelProps={{ shrink: true }}
                value={this.state.feedbackDescription}
                onChange={(e) => this.setState({ feedbackDescription: e.target.value })}
              />
              <input
                id="files"
                type="file"
                name="attachments"
                multiple
                accept=".jpg, .jpeg, .png, .svg, .pdf, .csv, .txt, .json"
                hidden
                onChange={this.handleFeedbackFileUpload}
              />
              <DialogContentText
                margin="dense"
              >
                Attachments
              </DialogContentText>
              <FormLabel htmlFor="files">
                <Button variant="contained" color="primary" component="div">
                  Select file(s)
                </Button>
              </FormLabel>
              <div className="files">
                {this.state.feedbackFiles.map((file) => (
                  <DialogContentText>
                    {file.name}
                    <IconButton aria-label="delete" size="small" onClick={() => this.handleRemoveFile(file.name)}>
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </DialogContentText>
                ))}
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.onClose}>Cancel</button>
              <button
                type="button"
                disabled={this.state.feedbackTitle === '' || this.state.feedbackDescription === ''}
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={this.handleSubmitFeedbackDialog}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FeedbackModal;
