import React, { Component } from 'react';
import PropTypes from 'prop-types';

// This is a basic component for all additional ranking options
export default class BaseRankingOption extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isElementVisible: PropTypes.func.isRequired,
    toggleElementVisibility: PropTypes.func.isRequired,
    inputDivID: PropTypes.string.isRequired,
  };

  toggleCheckbox = (status) => {
    if (this.props.isElementVisible(this.props.inputDivID) && !status) {
      this.props.toggleElementVisibility(this.props.inputDivID);
    } else if (!this.props.isElementVisible(this.props.inputDivID) && status) {
      this.props.toggleElementVisibility(this.props.inputDivID);
    }
  }

  render() {
    const {
      name,
      label,
      isElementVisible,
      toggleElementVisibility,
      inputDivID,
    } = this.props;

    return (
      <div className="row input-group mb-3">
        <div className="input-group-prepend">
          <div className="col">
            <span className="">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={name}
                  name={name}
                  checked={isElementVisible(inputDivID)}
                  onChange={() => toggleElementVisibility(inputDivID)}
                />
                <label className="custom-control-label" htmlFor={name}>{label}</label>
              </div>
            </span>
          </div>
        </div>
      </div>
    );
  }
}
