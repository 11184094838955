import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Title } from '../../utils/main';
import Loader from './Loader';

export default class Panel extends Component {
  static propTypes = {
    panelId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    toggle: PropTypes.func.isRequired,
    disableToggle: PropTypes.bool,
    isVisible: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    navItems: PropTypes.array,
    navItemLabelFunction: PropTypes.func,
    selectNavItem: PropTypes.func,
  };

  static defaultProps = {
    description: null,
  };

  componentDidMount = () => {
    const { navItems, selectNavItem } = this.props;
    if (navItems && navItems.length > 0 && selectNavItem) {
      selectNavItem(navItems[0]);
    }
  }

  handleSelectPanelValue = (event, value) => {
    if (value) {
      this.props.selectNavItem(value);
    }
  }

  render() {
    const {
      panelId,
      title,
      titleView,
      description,
      toggle,
      disableToggle,
      isVisible,
      isLoading,
      navItems,
      navItemLabelFunction,
      selectNavItem,
      selectedNavItem,
      children,
    } = this.props;

    return (
      <div className="card bg-light border-dark text-dark custom-margin">
        {navItems && (
        <nav className="navbar navbar-dark bg-dark">
          <p className="navbar-brand font-weight-bold">Score per group</p>
          <ToggleButtonGroup
            className="bg-light"
            value={selectedNavItem}
            exclusive
            onChange={this.handleSelectPanelValue}
            aria-label="panelOptions"
          >
            {
              navItems.map(
                (item) => (
                  <ToggleButton key={item} value={item} aria-label={item}>
                    <strong>{navItemLabelFunction ? navItemLabelFunction(item) : item}</strong>
                  </ToggleButton>
                ),
              )
            }
          </ToggleButtonGroup>
        </nav>
        )}
        {titleView || (
        <div className="card-header">
          <Title value={title} />
          {isLoading && <Loader id={`${panelId}Loader`} size="xs" />}
          {!disableToggle && (
          <button
            className="btn btn-link float-right"
            data-toggle="collapse"
            data-target={`#${panelId}`}
            aria-expanded="true"
            aria-controls={panelId}
            onClick={() => toggle(panelId)}
            type="button"
          >
            {
              isVisible(panelId)
                ? <FontAwesomeIcon icon="chevron-up" />
                : <FontAwesomeIcon icon="chevron-down" />
            }
          </button>
          )}
        </div>
        )}
        <div
          id={panelId}
          className={isVisible(panelId) || titleView ? 'card-body show' : 'card-body collapse'}
        >
          {description && (
          <div className="alert alert-light" role="alert">
            {description}
          </div>
          )}
          {children}
        </div>
      </div>
    );
  }
}
