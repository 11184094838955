import watchFetchChangelog from './changelog/changelogSaga';
import * as myListSagas from './myLists/myListsSaga';

const root = function* rootSaga() {
  yield [
    watchFetchChangelog(),
    myListSagas.watchInitWellboreLists(),
    myListSagas.watchAddWellboreList(),
    myListSagas.watchRemoveWellboreList(),
    myListSagas.watchRenameWellboreList(),
    myListSagas.watchAddWellboreToMyLists(),
    myListSagas.watchRemoveWellbore(),
    myListSagas.watchMoveWellbore(),
    myListSagas.watchSelectWellboreList(),
    myListSagas.watchGetRecommendedWellbores(),
    myListSagas.watchAddWellboreToSpecificList(),
  ];
};

export default root;
