import { _, chain } from 'lodash';
import {
  rankingTypes,
  rankingMethods,
  mainRankingMethods,
  rankingSectionGroupConstants,
  keywordTopics,
  getDisplayNameForSectionGroup,
} from '../../types';

const getRankingInputInSearchState = (searchState, type, method) => searchState.ranking[type][method].input;
const getRankingInputParamsInSearchState = (searchState, type, method) => searchState.ranking[type][method];
const isRankingOnInSearchState = (searchState, type, method) => searchState.ranking[type][method].selected;
const getRankingWeightInSearchState = (searchState, type, method) => searchState.ranking[type][method].weight;

const errorMessageConstants = {
  ERROR_NO_RANKING_METHOD_SELECTED: 'Please select at least one ranking method.',
  ERROR_MISSING_INPUT: 'Please select at least one value.',
  ERROR_MISSING_WELLPATH_INPUT: 'Please choose wellpath file.',
  ERROR_INVAID_WEIGHT: 'Weight of a ranking method can only be between 1 and 10.',
  ERROR_HOLE_SECTION_REQUIRED: 'Please select at least one hole section.',
  ERROR_HOLE_SECTION_MAX_LIMIT: 'Cannot select more than 10 hole sections in each search.',
  ERROR_HOLE_OR_OTHER_SECTION_REQUIRED: 'Please select at least one section for ranking.',
  ERROR_OTHER_SECTION_REQUIRES_INPUT: 'Required input for',
};

const duplicateInputValidation = (arrayOfInputObjects, keySelectorFunction) => {
  const grouppedByKey = chain(arrayOfInputObjects)
    .groupBy(keySelectorFunction)
    .value();

  // this step is to convert the lodash output object to an array that is suitable for filtering
  const grouppedByKeyArray = Object.entries(grouppedByKey)
    .map((entry) => {
      const [key, value] = entry;
      return { key, grouppedObjects: value };
    });

  const duplicatedObjectGroups = grouppedByKeyArray
    .filter((grouppedObjectsArray) => (grouppedObjectsArray.grouppedObjects.length > 1));

  let isAllValid = true;
  // extend input object with error info
  if (duplicatedObjectGroups.length > 0) {
    arrayOfInputObjects.forEach((inputObject) => {
      duplicatedObjectGroups.forEach((duplicatedObjectGroup) => {
        if (keySelectorFunction(inputObject) === duplicatedObjectGroup.key) {
          inputObject.isValid = false;
          inputObject.errorMessage += 'Duplicated line.';
          isAllValid = false;
        }
      });
    });
  }

  return isAllValid;
};

const overlappingDepthInputValidation = (arrayOfInputObjects, depthIntervalSelectorFunction, startEndValidation) => {
  const inputObjectAndDepthIntervals = arrayOfInputObjects.map((inputObject) => ({ inputObject, depthInterval: depthIntervalSelectorFunction(inputObject) }));

  const validInputObjectAndDepthIntervals = inputObjectAndDepthIntervals.filter((inputObject) => startEndValidation(inputObject.depthInterval.start, inputObject.depthInterval.end));

  const overlappingInputObjects = [];
  validInputObjectAndDepthIntervals.forEach((inputObjectAndDepthInterval) => {
    validInputObjectAndDepthIntervals.forEach((inputObjectAndDepthInterval2) => {
      if (inputObjectAndDepthInterval !== inputObjectAndDepthInterval2 && (
        (inputObjectAndDepthInterval.depthInterval.start >= inputObjectAndDepthInterval2.depthInterval.start
        && inputObjectAndDepthInterval.depthInterval.start < inputObjectAndDepthInterval2.depthInterval.end)

        || (inputObjectAndDepthInterval.depthInterval.end > inputObjectAndDepthInterval2.depthInterval.start
         && inputObjectAndDepthInterval.depthInterval.end <= inputObjectAndDepthInterval2.depthInterval.end)

         || (inputObjectAndDepthInterval2.depthInterval.start >= inputObjectAndDepthInterval.depthInterval.start
          && inputObjectAndDepthInterval2.depthInterval.end <= inputObjectAndDepthInterval.depthInterval.end))) {
        if (!overlappingInputObjects.includes(inputObjectAndDepthInterval)) {
          overlappingInputObjects.push(inputObjectAndDepthInterval);
        }
      }
    });
  });

  overlappingInputObjects.forEach((inputObjectAndDepthInterval) => {
    inputObjectAndDepthInterval.inputObject.isValid = false;
    inputObjectAndDepthInterval.inputObject.errorMessage += 'Depth interval is overlapping.';
  });

  return overlappingInputObjects.length === 0;
};

const nullToEmptyString = (input) => (input === null || '' ? 'empty' : input);

// #region Hole sections ranking input validations

const getHoleSectionInputKey = (holeSectionInput) => `(${holeSectionInput.sectionName} - ${nullToEmptyString(holeSectionInput.startDepth)} - ${nullToEmptyString(holeSectionInput.endDepth)})`;

const getDepthIntervalFromHoleSection = (holeSectionInput) => ({ start: holeSectionInput.startDepth, end: holeSectionInput.endDepth });

const iteratorContainsPropertyWithValue = (iterator, property, value) => {
  for (let item of iterator) {
    if (item[property] === value) {
      return true;
    }
  }
  return false;
};

const iteratorContainsValue = (iterator, value) => {
  for (let item of iterator) {
    if (item === value) {
      return true;
    }
  }
  return false;
};

const validateSectionRankingHoleSectionsInput = (searchState, type) => {
  let isAllValid = true;

  // validate if at least one hole section is specified for ranking input
  const holeSectionRankingInputs = getRankingInputInSearchState(searchState, type, rankingMethods.HOLESECTION);
  const holeSectionRankingInputParams = getRankingInputParamsInSearchState(searchState, type, rankingMethods.HOLESECTION);

  const keywordRankingInput = getRankingInputInSearchState(searchState, type, rankingMethods.KEYWORD);
  const keywordRankingInputParams = getRankingInputParamsInSearchState(searchState, type, rankingMethods.KEYWORD);
  const tubingRankingInput = getRankingInputInSearchState(searchState, type, rankingMethods.TUBING);
  const tubingRankingInputParams = getRankingInputParamsInSearchState(searchState, type, rankingMethods.TUBING);
  const wellPathRankingInput = getRankingInputInSearchState(searchState, type, rankingMethods.GEOMETRIC);
  const wellPathRankingInputParams = getRankingInputParamsInSearchState(searchState, type, rankingMethods.GEOMETRIC);
  const casingExitSizeRankingInput = getRankingInputInSearchState(searchState, type, rankingMethods.CASING_EXIT_SIZE);
  const casingExitSizeRankingInputParams = getRankingInputParamsInSearchState(searchState, type, rankingMethods.CASING_EXIT_SIZE);
  const otherSectionRankingInputs = getRankingInputInSearchState(searchState, type, rankingMethods.OTHER_SECTION);
  const otherSectionRankingInputParams = getRankingInputParamsInSearchState(searchState, type, rankingMethods.OTHER_SECTION);

  const prepareSidetrackConditionsMet = casingExitSizeRankingInput.casingExitSize !== ''
    || (keywordRankingInput !== null
      && (iteratorContainsPropertyWithValue(keywordRankingInput.values(), 'groupName', keywordTopics.WELL)));

  const lowerCompletionConditionsMet = holeSectionRankingInputs.length > 0
    || (keywordRankingInput !== null
        && (iteratorContainsPropertyWithValue(keywordRankingInput.values(), 'groupName', keywordTopics.WELL)
          || iteratorContainsPropertyWithValue(keywordRankingInput.values(), 'groupName', keywordTopics.COMPLETION_SECTIONS)
          || iteratorContainsPropertyWithValue(keywordRankingInput.values(), 'groupName', keywordTopics.COMPLETION_EQUIPMENT)
        )
    );

  const upperCompletionConditionsMet = tubingRankingInput.averageSize !== ''
    || tubingRankingInput.mainSize !== ''
    || tubingRankingInput.secondarySize !== ''
    || wellPathRankingInput.length > 0
    || (keywordRankingInput !== null
      && (iteratorContainsPropertyWithValue(keywordRankingInput.values(), 'groupName', keywordTopics.WELL)
        || iteratorContainsPropertyWithValue(keywordRankingInput.values(), 'groupName', keywordTopics.COMPLETION_SECTIONS)
        || iteratorContainsPropertyWithValue(keywordRankingInput.values(), 'groupName', keywordTopics.COMPLETION_EQUIPMENT)
      )
    );

  const isPrepareSidetrackStrategyOn = iteratorContainsValue(otherSectionRankingInputs.values(), rankingSectionGroupConstants.PREPARE_SIDETRACK.value);
  const isLowerCompletionStrategyOn = iteratorContainsValue(otherSectionRankingInputs.values(), rankingSectionGroupConstants.LOWER_COMPLETION.value);
  const isUpperCompletionStrategyOn = iteratorContainsValue(otherSectionRankingInputs.values(), rankingSectionGroupConstants.UPPER_COMPLETION.value);

  // Set defaults (or error messages/highlight will not disappear when fixed)
  holeSectionRankingInputParams.isValid = true;
  keywordRankingInputParams.isValid = true;
  otherSectionRankingInputParams.isValid = true;

  wellPathRankingInputParams.isValid = true;
  tubingRankingInputParams.isValid = true;
  casingExitSizeRankingInputParams.isValid = true;

  holeSectionRankingInputParams.errorMessage = '';
  otherSectionRankingInputParams.errorMessage = '';
  keywordRankingInputParams.errorMessage = '';

  tubingRankingInputParams.errorMessage = '';
  casingExitSizeRankingInputParams.errorMessage = '';
  wellPathRankingInputParams.errorMessage = '';

  let otherSectionsWithError = [];
  let expectedButMissingOneOfKeywords = [];
  let tubingMissing = false;
  let casingExitSizeMissing = false;
  let wellPathMissing = false;
  let holeSizeMissing = false;
  if (isLowerCompletionStrategyOn && !lowerCompletionConditionsMet) {
    otherSectionsWithError.push(rankingSectionGroupConstants.LOWER_COMPLETION.value);
    expectedButMissingOneOfKeywords.push(keywordTopics.COMPLETION_SECTIONS);
    expectedButMissingOneOfKeywords.push(keywordTopics.COMPLETION_EQUIPMENT);
    expectedButMissingOneOfKeywords.push(keywordTopics.WELL);
    holeSizeMissing = true;
  }

  if (isUpperCompletionStrategyOn && !upperCompletionConditionsMet) {
    wellPathMissing = true;
    tubingMissing = true;
    otherSectionsWithError.push(rankingSectionGroupConstants.UPPER_COMPLETION.value);
    expectedButMissingOneOfKeywords.push(keywordTopics.COMPLETION_SECTIONS);
    expectedButMissingOneOfKeywords.push(keywordTopics.COMPLETION_EQUIPMENT);
    expectedButMissingOneOfKeywords.push(keywordTopics.WELL);
  }

  if (isPrepareSidetrackStrategyOn && !prepareSidetrackConditionsMet) {
    casingExitSizeMissing = true;
    otherSectionsWithError.push(rankingSectionGroupConstants.PREPARE_SIDETRACK.value);
    expectedButMissingOneOfKeywords.push(keywordTopics.WELL);
  }

  // Unique values in lists
  otherSectionsWithError = otherSectionsWithError.filter((v, i, a) => a.indexOf(v) === i);
  expectedButMissingOneOfKeywords = expectedButMissingOneOfKeywords.filter((v, i, a) => a.indexOf(v) === i);

  if (otherSectionsWithError.length > 0) {
    const otherSectionsChosenString = otherSectionsWithError.length === 1 ? otherSectionsWithError[0] : 'the chosen \'Other Sections\'';
    const keywordGroupsConcatenatedString = `${expectedButMissingOneOfKeywords.length > 1
      ? expectedButMissingOneOfKeywords.slice(0, -1).join(', ')
      : expectedButMissingOneOfKeywords[0]} \
    ${expectedButMissingOneOfKeywords.length > 1
    ? `or ${expectedButMissingOneOfKeywords.slice(-1)}`
    : ''}`;
    keywordRankingInputParams.errorMessage = `${errorMessageConstants.ERROR_OTHER_SECTION_REQUIRES_INPUT} ${getDisplayNameForSectionGroup(otherSectionsChosenString)}.\
      Choose keyword from group: ${keywordGroupsConcatenatedString}.`;
    keywordRankingInputParams.isValid = false;
    isAllValid = false;
  }

  if (casingExitSizeMissing) {
    casingExitSizeRankingInputParams.isValid = false;
    casingExitSizeRankingInputParams.errorMessage = `${errorMessageConstants.ERROR_OTHER_SECTION_REQUIRES_INPUT} 'Prepare sidetrack'`;
  }

  if (tubingMissing) {
    tubingRankingInputParams.isValid = false;
    tubingRankingInputParams.errorMessage = `${errorMessageConstants.ERROR_OTHER_SECTION_REQUIRES_INPUT} 'Upper completion'`;
  }

  if (wellPathMissing) {
    wellPathRankingInputParams.isValid = false;
    wellPathRankingInputParams.errorMessage = `${errorMessageConstants.ERROR_OTHER_SECTION_REQUIRES_INPUT} 'Upper completion'`;
  }

  if (holeSizeMissing) {
    holeSectionRankingInputParams.isValid = false;
    holeSectionRankingInputParams.errorMessage = `${errorMessageConstants.ERROR_OTHER_SECTION_REQUIRES_INPUT} 'Lower completion'`;
  }

  if ((!holeSectionRankingInputs || holeSectionRankingInputs.length === 0) && (!otherSectionRankingInputs || otherSectionRankingInputs.length === 0)) {
    holeSectionRankingInputParams.isValid = false;
    otherSectionRankingInputParams.isValid = false;
    holeSectionRankingInputParams.errorMessage = errorMessageConstants.ERROR_HOLE_OR_OTHER_SECTION_REQUIRED;
    otherSectionRankingInputParams.errorMessage = errorMessageConstants.ERROR_HOLE_OR_OTHER_SECTION_REQUIRED;
    isAllValid = false;
  } else {
    if (holeSectionRankingInputs.length > 10) {
      holeSectionRankingInputParams.isValid = false;
      holeSectionRankingInputParams.errorMessage = errorMessageConstants.ERROR_HOLE_SECTION_MAX_LIMIT;
      isAllValid = false;
    }

    // validate if start and end depth is specfied for hole sections
    holeSectionRankingInputs.forEach((holeSectionRankingInput) => {
      holeSectionRankingInput.isValid = true;
      holeSectionRankingInput.errorMessage = '';

      if (holeSectionRankingInput.startDepth < 0) {
        holeSectionRankingInput.startDepthIsValid = false;
        holeSectionRankingInput.startDepthErrorMessage = 'Start depth must be a positive value';
        isAllValid = false;
      } else {
        holeSectionRankingInput.startDepthIsValid = true;
        holeSectionRankingInput.startDepthErrorMessage = '';
      }
      if (holeSectionRankingInput.endDepth < 0) {
        holeSectionRankingInput.endDepthIsValid = false;
        holeSectionRankingInput.endDepthErrorMessage = 'End depth must be a positive value';
        isAllValid = false;
      } else {
        holeSectionRankingInput.endDepthIsValid = true;
        holeSectionRankingInput.endDepthErrorMessage = '';
      }

      if (holeSectionRankingInput.startDepth !== null
        && holeSectionRankingInput.endDepth !== null
        && holeSectionRankingInput.startDepth >= holeSectionRankingInput.endDepth) {
        holeSectionRankingInput.isValid = false;
        holeSectionRankingInput.errorMessage += 'Start depth must be smaller than end depth.';
        isAllValid = false;
      }
    });

    // validate if (section name, startDepth, endDepth) is unique in the list

    const duplicationValidationResult = duplicateInputValidation(holeSectionRankingInputs, getHoleSectionInputKey);
    const overlappingDepthIntervalsValidationResult = overlappingDepthInputValidation(
      holeSectionRankingInputs,
      getDepthIntervalFromHoleSection,
      (start, end) => start > 0 && end > 0 && start < end,
    );

    isAllValid = isAllValid && duplicationValidationResult && overlappingDepthIntervalsValidationResult;
  }

  return isAllValid;
};

const validateSectionRankingFormationsInput = (searchState, type) => {
  let isAllValid = true;

  // validate if start and end depth is specfied for formation sections
  const formationRankingInputs = getRankingInputInSearchState(searchState, type, rankingMethods.FORMATION);

  formationRankingInputs.forEach((formationRankingInput) => {
    formationRankingInput.isValid = true;
    formationRankingInput.errorMessage = '';

    if (formationRankingInput.entryMd === null || formationRankingInput.entryMd < 0) {
      formationRankingInput.entryMdIsValid = false;
      formationRankingInput.entryMdErrorMessage = 'A non-negative entry value is required for formation input';
      isAllValid = false;
    } else {
      formationRankingInput.entryMdIsValid = true;
      formationRankingInput.entryMdErrorMessage = '';
    }

    if (formationRankingInput.exitMd === null || formationRankingInput.exitMd < 0) {
      formationRankingInput.exitMdIsValid = false;
      formationRankingInput.exitMdErrorMessage = 'A non-negative exit value is required for formation input';
      isAllValid = false;
    } else {
      formationRankingInput.exitMdIsValid = true;
      formationRankingInput.exitMdErrorMessage = '';
    }

    if (formationRankingInput.entryMd !== null
        && formationRankingInput.exitMd !== null
        && formationRankingInput.entryMd > formationRankingInput.exitMd) {
      formationRankingInput.isValid = false;
      formationRankingInput.errorMessage += 'Entry must be smaller or equal to exit.';
      isAllValid = false;
    }
  });

  return isAllValid;
};

export const validateSectionRankingInputInSearchState = (searchState) => {
  const type = rankingTypes.SECTION_RANKING;

  const holeSectionsValidationResult = validateSectionRankingHoleSectionsInput(searchState, type);
  const formationValidationResult = validateSectionRankingFormationsInput(searchState, type);
  return holeSectionsValidationResult && formationValidationResult;
};

// #endregion

// #region Wellbore ranking input validation

const IsWeightValid = (weight) => (weight && weight >= 1 && weight <= 10);

export const validateWellboreRankingInputInSearchState = (searchState) => {
  const type = rankingTypes.WELLBORE_RANKING;
  let isAllValid = true;

  // Validate if at least one ranking method is selected
  const selectedMethods = mainRankingMethods.filter((rankingMethod) => isRankingOnInSearchState(searchState, type, rankingMethod));

  const wellboreRanking = searchState.ranking[type];
  wellboreRanking.isValid = true;
  wellboreRanking.errorMessage = '';

  if (selectedMethods.length === 0) {
    wellboreRanking.isValid = false;
    wellboreRanking.errorMessage = errorMessageConstants.ERROR_NO_RANKING_METHOD_SELECTED;
    isAllValid = false;
  } else {
    // Validate if at least one input value exists for each selected ranking method
    mainRankingMethods.forEach((rankingMethod) => {
      const rankingInputParam = getRankingInputParamsInSearchState(searchState, type, rankingMethod);
      if (isRankingOnInSearchState(searchState, type, rankingMethod)
        && getRankingInputInSearchState(searchState, type, rankingMethod).length <= 0) {
        rankingInputParam.isValid = false;

        if (rankingMethod === rankingMethods.GEOMETRIC) {
          rankingInputParam.errorMessage = errorMessageConstants.ERROR_MISSING_WELLPATH_INPUT;
        } else {
          rankingInputParam.errorMessage = errorMessageConstants.ERROR_MISSING_INPUT;
        }
        isAllValid = false;
      } else {
        rankingInputParam.isValid = true;
        rankingInputParam.errorMessage = '';
      }
    });

    // Validate if weight value is in the valid range for each selected ranking method
    mainRankingMethods.forEach((rankingMethod) => {
      const rankingInputParam = getRankingInputParamsInSearchState(searchState, type, rankingMethod);
      if (isRankingOnInSearchState(searchState, type, rankingMethod)
         && !IsWeightValid(getRankingWeightInSearchState(searchState, type, rankingMethod))) {
        rankingInputParam.weightIsValid = false;
        rankingInputParam.weightErrorMessage = errorMessageConstants.ERROR_INVAID_WEIGHT;
        isAllValid = false;
      } else {
        rankingInputParam.weightIsValid = true;
        rankingInputParam.weightErrorMessage = '';
      }
    });
  }

  return isAllValid;
};

// #endregion
