import React, { Component } from 'react';

class ConfirmModal extends Component {
  static show(config) {
    ConfirmModal.singletonRef.instanceShow(config);
  }

  constructor(props) {
    super(props);
    ConfirmModal.singletonRef = this;
  }

  state = {
    title: 'Title',
    message: 'Message0',
    onOK: () => {},
    onCancel: () => {},
  };

  instanceShow(config) {
    this.setState({
      title: config.title,
      message: config.message,
      onOK: config.onOK,
      onCancel: config.onCancel,
    });
    $('#confirmModal').modal();
  }

  render() {
    return (
      <div className="modal fade" id="confirmModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">{this.state.title}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {this.state.message}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.state.onOK}>Yes</button>
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.state.onCancel}>No</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmModal;
