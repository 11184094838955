import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField, Tooltip, Paper } from '@material-ui/core';
import * as actions from '../store';
import * as selectors from '../store/selectors';
import {
  targetDivs, rankingMethods, rankingSectionGroupConstants, getDisplayNameForSectionGroup, rankingTypes, sectionTypes, rankingConstants, propertyNames,
} from '../types';
import Panel from '../components/common/Panel';
import RankingInputBox from '../components/search/RankingInputBox';
import RankingInput from '../components/search/RankingInput';
import ImportWellpath from '../components/search/importWellpath';
import OutlineButton from '../components/common/OutlineButton';
import Loader from '../components/common/Loader';
import { GetUniqueNonEmptyStringArray } from '../utils/main';
import BaseRankingOption from '../components/search/BaseRankingOption';
import BaseDropdownWithButton from '../components/search/BaseDropdownWithButton';
import ConfirmModal from '../components/common/ConfirmModal';

import { NumberFormatPositiveDecimal } from '../utils/formatters';

export const IMPORT_WELLPATH_REF = 'importWellPathElement';
export const SELECT_WELLBORE_REF = 'selectWellboreElement';

class SectionRankingPanel extends Component {
  handeWellPathReset = () => {
    this.props.onWellPathReset(rankingTypes.SECTION_RANKING);
  }

  clearAllParameters = () => {
    const { clearRankingInput, refs } = this.props;
    clearRankingInput(rankingTypes.SECTION_RANKING);
    refs[IMPORT_WELLPATH_REF].current.reset();
    this.handeWellPathReset();
  }

  handleSelectWellboreWithClearPrevious = (wellboreUID, overwrite) => {
    const { handleSelectWellbore } = this.props;
    if (overwrite) {
      this.clearAllParameters();
    }
    handleSelectWellbore(wellboreUID, overwrite);
  }

  handleHoleSectionClearSelectedValues = () => {
    const { clearIndividualRankingInput } = this.props;

    const modalConfig = {
      title: 'Remove hole sections',
      message: 'Do you really want to remove all hole sections?',
      onOK: () => clearIndividualRankingInput(rankingTypes.SECTION_RANKING, rankingMethods.HOLESECTION),
    };

    ConfirmModal.show(modalConfig);
  }

  handleFormationClearSelectedValues = () => {
    const { clearIndividualRankingInput } = this.props;

    const modalConfig = {
      title: 'Remove formations',
      message: 'Do you really want to remove all formations?',
      onOK: () => clearIndividualRankingInput(rankingTypes.SECTION_RANKING, rankingMethods.FORMATION),
    };

    ConfirmModal.show(modalConfig);
  }

  handleKeywordClearSelectedValues = () => {
    const { clearIndividualRankingInput } = this.props;

    const modalConfig = {
      title: 'Remove keywords',
      message: 'Do you really want to remove all keywords?',
      onOK: () => clearIndividualRankingInput(rankingTypes.SECTION_RANKING, rankingMethods.KEYWORD),
    };

    ConfirmModal.show(modalConfig);
  }

  render() {
    const {
      // Passed from Parent
      refs,
      onWellPathLoaded,
      loading,
      handleStartSearch,

      // Store
      isElementVisible,
      toggleElementVisibility,
      loadingResults,
      loadingFilters,
      addRankingInput,
      removeRankingInput,
      modifyValueProperty,
      getRankingInput,
      setRankingInput,
      getRankingInputParams,
      getSelectedWellboreName,
      availableFormations,
      availableSections,
      availableKeywords,
      availableWellBores,
      rankingSectionGroups,
      maxResults,
      setMaxResults,
      sourceWellPathFileName,
      linkedWellboreId,
      sectionRankingEnabled,
      setTubingRankingInputAverageSize,
      setTubingRankingInputMainSize,
      setTubingRankingInputSecondarySize,
      getTubingRankingInputAverageSize,
      getTubingRankingInputMainSize,
      getTubingRankingInputSecondarySize,
      setCasingExitSize,
      getCasingExitSize,
    } = this.props;

    const selectedSectionGroups = getRankingInput(rankingTypes.SECTION_RANKING, rankingMethods.OTHER_SECTION);
    const keywordInputParams = getRankingInputParams(rankingTypes.SECTION_RANKING, rankingMethods.KEYWORD);
    const wellPathRankingInputParams = getRankingInputParams(rankingTypes.SECTION_RANKING, rankingMethods.GEOMETRIC);
    const casingExitSizeRankingInputParams = getRankingInputParams(rankingTypes.SECTION_RANKING, rankingMethods.CASING_EXIT_SIZE);
    const tubingRankingInputParams = getRankingInputParams(rankingTypes.SECTION_RANKING, rankingMethods.TUBING);
    const otherSectionInputParams = getRankingInputParams(rankingTypes.SECTION_RANKING, rankingMethods.OTHER_SECTION);
    const holeSectionInputParams = getRankingInputParams(rankingTypes.SECTION_RANKING, rankingMethods.HOLESECTION);

    const casingExitSizeActive = selectedSectionGroups.indexOf(rankingSectionGroupConstants.PREPARE_SIDETRACK.value) !== -1;
    const tubingFeaturesActive = selectedSectionGroups.indexOf(rankingSectionGroupConstants.UPPER_COMPLETION.value) !== -1;

    const otherSectionIsValid = otherSectionInputParams.isValid === undefined || otherSectionInputParams.isValid;
    const holeSectionIsValid = holeSectionInputParams.isValid === undefined || holeSectionInputParams.isValid;
    const isKeywordsValid = keywordInputParams.isValid === undefined || keywordInputParams.isValid;
    const isTubingInputValid = tubingRankingInputParams.isValid === undefined || tubingRankingInputParams.isValid;
    const isCasingExitSizeInputValid = casingExitSizeRankingInputParams.isValid === undefined || casingExitSizeRankingInputParams.isValid;
    const isWellPathInputValid = wellPathRankingInputParams.isValid === undefined || wellPathRankingInputParams.isValid;

    const holeSectionErrorMessage = holeSectionIsValid ? '' : holeSectionInputParams.errorMessage;
    const keywordInputErrorMessage = isKeywordsValid ? '' : keywordInputParams.errorMessage;
    const otherSectionErrorMessage = otherSectionIsValid ? '' : otherSectionInputParams.errorMessage;
    const tubingErrorMessage = isTubingInputValid ? '' : tubingRankingInputParams.errorMessage;
    const casingExitSizeErrorMessage = isCasingExitSizeInputValid ? '' : casingExitSizeRankingInputParams.errorMessage;
    const wellPathErrorMessage = isWellPathInputValid ? '' : wellPathRankingInputParams.errorMessage;

    return (
      <Panel
        panelId={targetDivs.SECTION_RANKING_PANEL}
        title="Section Ranking"
        description="Fill in the desired input parameters to rank wellbores based on hole sections."
        toggle={toggleElementVisibility}
        isVisible={isElementVisible}
        isLoading={loadingFilters}
      >
        <div>

          <div className="row">

            <div className="col-xl-2">
              <BaseRankingOption
                name="selectWellboreByID"
                label="Autofill by wellbore"
                isElementVisible={isElementVisible}
                toggleElementVisibility={toggleElementVisibility}
                inputDivID={targetDivs.SELECT_WELLBORE}
                ref={refs[SELECT_WELLBORE_REF]}
              />
            </div>
            <div className="col-xl-4">
              {isElementVisible(targetDivs.SELECT_WELLBORE) && (
              <div id={targetDivs.SELECT_WELLBORE}>
                <BaseDropdownWithButton
                  name="selectWellboreByIDListSectionRanking"
                  availableValues={availableWellBores}
                  selectedValue={getSelectedWellboreName()}
                  buttonLabel="Load information for this wellbore"
                  buttonClicked={this.handleSelectWellboreWithClearPrevious}
                  defaultValue={decodeURIComponent(linkedWellboreId)}
                  defaultValueContains={rankingConstants.PREFIX_LINKED_WELLBORE}
                />
              </div>
              )}
            </div>
            <div className="col-xl-3">
              <div style={{ marginTop: 8 }}>
                <RankingInput
                  type={rankingTypes.SECTION_RANKING}
                  method={rankingMethods.OTHER_SECTION}
                  availableValues={rankingSectionGroups}
                  selectedValues={getRankingInput}
                  setValues={setRankingInput}
                  label="Other sections"
                  placeholder="Select..."
                  isInputValid={otherSectionIsValid}
                  inputErrorMessage={otherSectionErrorMessage}
                  optionLabelFunction={getDisplayNameForSectionGroup}
                />
              </div>
            </div>
            <div className="col-xl-3">
              <Tooltip
                interactive
                title={!casingExitSizeActive ? 'Used in Prepare sidetrack ranking' : ''}
              >
                <TextField
                  disabled={!casingExitSizeActive}
                  label='Casing exit size (")'
                  fullWidth
                  error={!isCasingExitSizeInputValid}
                  helperText={casingExitSizeErrorMessage}
                  value={getCasingExitSize()}
                  onChange={(event) => setCasingExitSize(event.target.value)}
                  InputProps={{
                    inputComponent: NumberFormatPositiveDecimal,
                  }}
                />
              </Tooltip>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <RankingInputBox title="Wellpath">
                <ImportWellpath
                  name="ImportCSVForSectionRanking"
                  type={rankingTypes.SECTION_RANKING}
                  afterParsingCSV={onWellPathLoaded}
                  onWellPathReset={this.handeWellPathReset}
                  loading={loading}
                  ref={refs[IMPORT_WELLPATH_REF]}
                  sourceWellPathName={sourceWellPathFileName}
                  sourceWellPathCoordinates={
                    getRankingInput(
                      rankingTypes.SECTION_RANKING,
                      rankingMethods.GEOMETRIC,
                    )
                  }
                  isInputValid={isWellPathInputValid}
                  inputErrorMessage={wellPathErrorMessage}
                />
              </RankingInputBox>
            </div>
            <div className="col-xl-6">
              <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                <span>Tubing features</span>
              </div>
              <Paper className="row bg-light" variant="outlined" style={{ marginLeft: 0, marginRight: 0 }}>
                <div className="col-xl-4">
                  <Tooltip
                    interactive
                    title={!tubingFeaturesActive ? 'Used in Upper Completion ranking' : ''}
                  >
                    <TextField
                      disabled={!tubingFeaturesActive}
                      label='Average size (")'
                      fullWidth
                      error={!isTubingInputValid}
                      helperText={tubingErrorMessage}
                      style={{ marginBottom: 2 }}
                      value={getTubingRankingInputAverageSize()}
                      onChange={(event) => setTubingRankingInputAverageSize(event.target.value)}
                      InputProps={{
                        inputComponent: NumberFormatPositiveDecimal,
                      }}
                    />
                  </Tooltip>
                </div>
                <div className="col-xl-4">
                  <Tooltip
                    interactive
                    title={!tubingFeaturesActive ? 'Used in Upper Completion ranking' : ''}
                  >
                    <TextField
                      disabled={!tubingFeaturesActive}
                      label='Main size (")'
                      fullWidth
                      error={!isTubingInputValid}
                      style={{ marginBottom: 2 }}
                      value={getTubingRankingInputMainSize()}
                      onChange={(event) => setTubingRankingInputMainSize(event.target.value)}
                      InputProps={{
                        inputComponent: NumberFormatPositiveDecimal,
                      }}
                    />
                  </Tooltip>
                </div>
                <div className="col-xl-4">
                  <Tooltip
                    interactive
                    title={!tubingFeaturesActive ? 'Used in Upper Completion ranking' : ''}
                  >
                    <TextField
                      disabled={!tubingFeaturesActive}
                      fullWidth
                      label='Secondary size (")'
                      style={{ marginBottom: 2 }}
                      error={!isTubingInputValid}
                      value={getTubingRankingInputSecondarySize()}
                      onChange={(event) => setTubingRankingInputSecondarySize(event.target.value)}
                      InputProps={{
                        inputComponent: NumberFormatPositiveDecimal,
                      }}
                    />
                  </Tooltip>
                </div>
              </Paper>
              <div className="row">
                <div className="col-xl-4">
                  <RankingInputBox
                    title="Hole Sections"
                    type={rankingTypes.SECTION_RANKING}
                    method={rankingMethods.HOLESECTION}
                    allowSelectMultipleTimes
                    propertyForSelectionId="uiId"
                    propertyForOptionLabel={propertyNames.SECTION_NAME_PROPERTY}
                    propertiesForColumnValues={['startDepth', 'endDepth']}
                    handleSelectValue={addRankingInput}
                    handleRemoveValue={removeRankingInput}
                    handleClearSelectedValues={this.handleHoleSectionClearSelectedValues}
                    availableValues={availableSections[sectionTypes.HOLE]}
                    selectedValues={getRankingInput}
                    orderSelectedValuesBy="numericValue"
                    orderType="desc"
                    headers={['Type', 'Depth (mMD)']}
                    subHeaders={{ 'Depth (mMD)': ['Start', 'End'] }}
                    maximumSelected={10}
                    modifyValueProperty={modifyValueProperty}
                    isInputValid={holeSectionIsValid}
                    inputErrorMessage={holeSectionErrorMessage}
                  />
                </div>

                <div className="col-xl-4">
                  <RankingInputBox
                    title="Formations"
                    type={rankingTypes.SECTION_RANKING}
                    method={rankingMethods.FORMATION}
                    allowSelectMultipleTimes
                    propertyForSelectionId="uiId"
                    propertyForOptionLabel={propertyNames.FORMATION_NAME_PROPERTY}
                    propertiesForColumnValues={['entryMd', 'exitMd']}
                    handleSelectValue={addRankingInput}
                    handleRemoveValue={removeRankingInput}
                    handleClearSelectedValues={this.handleFormationClearSelectedValues}
                    availableValues={availableFormations}
                    selectedValues={getRankingInput}
                    orderSelectedValuesBy="entryMd"
                    orderType="asc"
                    headers={['Type', 'Depth (mMD)']}
                    subHeaders={{ 'Depth (mMD)': ['Entry', 'Exit'] }}
                    modifyValueProperty={modifyValueProperty}
                  />
                </div>
                <div className="col-xl-4">
                  <RankingInputBox
                    title="Keywords"
                    type={rankingTypes.SECTION_RANKING}
                    method={rankingMethods.KEYWORD}
                    propertyForOptionLabel="keyword"
                    propertyForGroupBy="groupName"
                    handleSelectValue={addRankingInput}
                    handleRemoveValue={removeRankingInput}
                    handleClearSelectedValues={this.handleKeywordClearSelectedValues}
                    availableValues={availableKeywords}
                    selectedValues={getRankingInput}
                    isInputValid={isKeywordsValid}
                    inputErrorMessage={keywordInputErrorMessage}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-end" style={{ marginTop: '10px' }}>
            {loadingResults && (
            <div className="col-sm-3">
              <span className="alert alert-info">Ranking reference wellbores...</span>
              <Loader id="resultsLoaderForSectionRanking" size="xs" />
            </div>
            )}
            <div className="col-xl-1">
              <OutlineButton
                label="Clear"
                onClick={this.clearAllParameters}
              />
            </div>
            <div className="col-xl-2">
              <OutlineButton
                label="Search & rank"
                onClick={() => handleStartSearch(rankingTypes.SECTION_RANKING, null)}
                disabled={!sectionRankingEnabled}
                title={!sectionRankingEnabled ? 'Please correct the search criteria' : ''}
              />
            </div>
          </div>
          <div className="row justify-content-end" style={{ marginTop: '10px' }}>
            <div className="col-sm-2">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Max results</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  value={maxResults}
                  onChange={(event) => setMaxResults(rankingTypes.SECTION_RANKING, parseInt(event.target.value))}
                  onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      handleStartSearch(rankingTypes.SECTION_RANKING, null);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Panel>
    );
  }
}

SectionRankingPanel.propTypes = {
  onWellPathLoaded: PropTypes.func.isRequired,
  onWellPathReset: PropTypes.func.isRequired,
  refs: PropTypes.object.isRequired,
  isMultipleRankingMethodsSelected: PropTypes.func,
  loading: PropTypes.func.isRequired,
  handleSelectWellbore: PropTypes.func.isRequired,
  areRankingParametersApplied: PropTypes.func.isRequired,
  clearRankingInput: PropTypes.func.isRequired,
  handleStartSearch: PropTypes.func.isRequired,

  isElementVisible: PropTypes.func.isRequired,
  toggleElementVisibility: PropTypes.func.isRequired,
  loadingResults: PropTypes.bool.isRequired,
  loadingFilters: PropTypes.bool.isRequired,
  addRankingInput: PropTypes.func.isRequired,
  removeRankingInput: PropTypes.func.isRequired,
  modifyValueProperty: PropTypes.func.isRequired,
  getRankingInput: PropTypes.func.isRequired,
  getRankingInputParams: PropTypes.func.isRequired,
  getSelectedWellboreName: PropTypes.func.isRequired,
  availableFormations: PropTypes.array.isRequired,
  availableSections: PropTypes.object.isRequired,
  availableKeywords: PropTypes.array.isRequired,
  maxResults: PropTypes.number.isRequired,
  setMaxResults: PropTypes.func.isRequired,
  sourceWellPathFileName: PropTypes.string.isRequired,
  sectionRankingEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  // General
  isElementVisible: (elementId) => selectors.isElementVisible(state, elementId),

  // Search
  // --- Ranking Methods
  getRankingInput: (type, method) => selectors.getRankingInput(state, type, method),
  getRankingInputParams: (type, method) => selectors.getRankingInputParams(state, type, method),
  getTubingRankingInputAverageSize: () => selectors.getTubingRankingInputAverageSize(state),
  getTubingRankingInputMainSize: () => selectors.getTubingRankingInputMainSize(state),
  getTubingRankingInputSecondarySize: () => selectors.getTubingRankingInputSecondarySize(state),
  getSelectedWellboreName: () => selectors.getSelectedWellboreName(state, rankingTypes.SECTION_RANKING),
  getCasingExitSize: () => selectors.getCasingExitSize(state),

  sectionRankingEnabled: selectors.getSectionRankingEnabled(state),

  // --- Source WellPath
  sourceWellPathFileName: selectors.getSourceWellPathFileName(state, rankingTypes.SECTION_RANKING),
  // --- Process
  loadingFilters: selectors.isLoadingFilters(state),
  loadingResults: selectors.isLoadingResults(state),

  // Filters
  // --- Formation
  availableFormations: selectors.getAvailableFormations(state),
  // --- Section
  availableSections: {
    [sectionTypes.HOLE]: selectors.getAvailableSections(state, sectionTypes.HOLE),
    [sectionTypes.COMPLETION]: selectors.getAvailableSections(state, sectionTypes.COMPLETION),
  },
  // --- Keyword
  availableKeywords: selectors.getAvailableKeywords(state),

  availableWellBores: selectors.getAvailableWellBores(state),
  rankingSectionGroups: selectors.getRankingSectionGroups(state),

  // --- Number of results
  maxResults: selectors.getMaxResults(state, rankingTypes.SECTION_RANKING),
});

const mapDispatchToProps = (dispatch) => ({
  // General
  toggleElementVisibility: (elementId) => dispatch(actions.toggleElementVisibility(elementId)),

  // Search
  // --- Ranking Methods
  addRankingInput: (type, method, value) => dispatch(
    actions.addRankingInput({ type, method, value }),
  ),
  removeRankingInput: (type, method, value) => dispatch(
    actions.removeRankingInput({ type, method, value }),
  ),
  modifyValueProperty: (type, method, uiId, property, value) => dispatch(
    actions.modifyValueProperty({
      type, method, uiId, property, value,
    }),
  ),
  clearIndividualRankingInput: (type, method) => dispatch(actions.clearRankingMethodInput({ type, method })),
  setRankingInput: (type, method, value) => dispatch(actions.setRankingInput({ type, method, value })),
  setTubingRankingInputAverageSize: (value) => dispatch(actions.setTubingRankingInputAverageSize({ value })),
  setTubingRankingInputMainSize: (value) => dispatch(actions.setTubingRankingInputMainSize({ value })),
  setTubingRankingInputSecondarySize: (value) => dispatch(actions.setTubingRankingInputSecondarySize({ value })),
  setCasingExitSize: (value) => dispatch(actions.setCasingExitSize({ value })),

  // Filters
  // --- Number of results
  setMaxResults: (type, number) => dispatch(actions.setMaxResults({ type, value: number })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SectionRankingPanel);
