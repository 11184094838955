import { handleActions } from 'redux-actions';
import * as actions from './guidesActions';

const defaultState = {
  videoList: [],
};

export default handleActions({
  [actions.setVideoList]: (state, action) => ({
    ...state,
    videoList: action.payload,
  }),
}, defaultState);
