import {
  put,
  call,
  takeLatest,
  takeEvery,
  select,
  all,
} from 'redux-saga/effects';
import api from '../../services/api';
import * as actions from './myListsActions';
import { getDefaultWellboreList, getSelectedWellboreList } from './myListsSelector';
import { appInsightsLogError } from '../../services/appInsights/appInsights';

const reportError = (error) => {
  if (appInsightsLogError) {
    appInsightsLogError(error);
  }
};

function* getSelectedWellboreListContents(action) {
  try {
    const wellboreListId = action.payload;
    yield put(actions.getSelectedWellboreListContentsRequested());
    const wellboresResponse = yield call(api.getWellboresOfList, wellboreListId);
    yield put(actions.getSelectedWellboreListContentsSucceeded({
      wellboreListId,
      wellbores: wellboresResponse.body.wellbores,
    }));
  } catch (ex) {
    yield put(actions.getSelectedWellboreListContentsFailed());
    reportError(ex);
  }
}

export function* watchSelectWellboreList() {
  yield takeEvery(actions.selectWellboreList.toString(), getSelectedWellboreListContents);
}

function* getRecommendedWellbores(action) {
  const wellboreListId = action.payload;
  try {
    yield put(actions.getRecommendedWellboresRequested());
    const recommendedWellboresResponse = yield call(api.getRecommendedWellbores, wellboreListId);

    yield put(actions.getRecommendedWellboresSucceeded({
      wellboreListId,
      recommendedWellbores: recommendedWellboresResponse.body.recommendedWellbores,
    }));
  } catch (ex) {
    const selectedWellboreList = yield select(getSelectedWellboreList);
    // Sometimes the wellborelist can be deleted before the getRecommended has started, so we suppress this error.
    if (wellboreListId === selectedWellboreList.id) {
      yield put(actions.getRecommendedWellboresFailed());
      reportError(ex);
    }
  }
}

export function* watchGetRecommendedWellbores() {
  yield takeEvery(actions.getRecommendedWellbores.toString(), getRecommendedWellbores);
}

function* initWellboreLists(action) {
  try {
    yield put(actions.fetchMyListsRequested());
    const response = yield call(api.getMyLists, action.payload);
    yield put(actions.fetchMyListsSucceeded(response));
    const selectedWellboreListId = response.body.wellboreLists[0].id;
    yield all([
      call(getRecommendedWellbores, { payload: selectedWellboreListId }),
      call(getSelectedWellboreListContents, { payload: selectedWellboreListId }),
    ]);
  } catch (ex) {
    yield put(actions.fetchMyListsFailed());
    reportError(ex);
  }
}

export function* watchInitWellboreLists() {
  yield takeLatest(actions.initWellboreLists.toString(), initWellboreLists);
}

function* addWellboreList(action) {
  try {
    yield put(actions.addWellboreListRequested());
    const response = yield call(api.createWellboreList, action.payload);
    yield put(actions.addWellboreListSucceeded(response));
  } catch (ex) {
    yield put(actions.addWellboreListFailed());
    reportError(ex);
  }
}

export function* watchAddWellboreList() {
  yield takeEvery(actions.addWellboreList.toString(), addWellboreList);
}

function* removeWellboreList(action) {
  try {
    yield put(actions.removeWellboreListRequested());
    const response = yield call(api.removeWellboreList, action.payload);
    yield put(actions.removeWellboreListSucceeded(response));
    const selectedWellboreList = yield select(getSelectedWellboreList);
    // if the last wellboreList is deleted then we get the default one from the server
    // server always creates an empty wellboreList after deleting the last one
    if (!selectedWellboreList) {
      yield put(actions.initWellboreLists());
    }
  } catch (ex) {
    yield put(actions.removeWellboreListFailed());
    reportError(ex);
  }
}

export function* watchRemoveWellboreList() {
  yield takeEvery(actions.removeWellboreList.toString(), removeWellboreList);
}

function* renameWellboreList(action) {
  try {
    yield put(actions.renameWellboreListRequested());
    yield call(api.renameWellboreList, action.payload.wellboreListId, action.payload.newName);
    yield put(actions.renameWellboreListSucceeded(action.payload));
  } catch (ex) {
    yield put(actions.renameWellboreListFailed());
    reportError(ex);
  }
}

export function* watchRenameWellboreList() {
  yield takeEvery(actions.renameWellboreList.toString(), renameWellboreList);
}

function* addWellboreToMyLists(action) {
  try {
    yield put(actions.addWellboreToMyListsRequested());
    const selectedWellboreList = yield select(getDefaultWellboreList);
    const uniqueWellboreIdentifier = action.payload;
    const response = yield call(
      api.addWellboreToMyLists,
      selectedWellboreList.id,
      uniqueWellboreIdentifier,
    );
    yield put(actions.addWellboreToMyListsSucceeded(response));
  } catch (ex) {
    yield put(actions.addWellboreToMyListsFailed());
    reportError(ex);
  }
}

export function* watchAddWellboreToMyLists() {
  yield takeEvery(actions.addWellboreToMyLists.toString(), addWellboreToMyLists);
}

function* addWellboreToSpecificList(action) {
  try {
    yield put(actions.addWellboreToSpecificListRequested());
    const { wellboreListId, uniqueWellboreIdentifier } = action.payload;
    const response = yield call(
      api.addWellboreToMyLists,
      wellboreListId,
      uniqueWellboreIdentifier,
    );
    const wellboreInfo = response;
    yield put(actions.addWellboreToSpecificListSucceeded({ wellboreListId, wellboreInfo }));

    const selectedWellboreList = yield select(getSelectedWellboreList);
    yield call(getSelectedWellboreListContents, { payload: selectedWellboreList.id });
  } catch (ex) {
    yield put(actions.addWellboreToSpecificListFailed());
    reportError(ex);
  }
}

export function* watchAddWellboreToSpecificList() {
  yield takeEvery(actions.addWellboreToSpecificList.toString(), addWellboreToSpecificList);
}

function* removeWellbore(action) {
  const params = {
    wellboreListId: action.payload.wellboreListId,
    uniqueWellboreIdentifier: action.payload.wellbore.uniqueWellboreIdentifier,
  };

  try {
    yield put(actions.removeWellboreRequested(params));
    const selectedWellboreListId = action.payload.wellboreListId;
    const { uniqueWellboreIdentifier } = action.payload.wellbore;
    yield call(api.removeWellbore, selectedWellboreListId, uniqueWellboreIdentifier);
    yield put(actions.removeWellboreSucceeded(params));
  } catch (ex) {
    yield put(actions.removeWellboreFailed(params));
    reportError(ex);
  }
}

export function* watchRemoveWellbore() {
  yield takeEvery(actions.removeWellbore.toString(), removeWellbore);
}

function* moveWellbore(action) {
  try {
    yield put(actions.moveWellboreRequested());
    const { sourceWellboreListId, targetWellboreListId, uniqueWellboreIdentifier } = action.payload;
    const moveWellboreResponse = yield call(
      api.moveWellbore,
      sourceWellboreListId,
      targetWellboreListId,
      uniqueWellboreIdentifier,
    );

    yield put(actions.moveWellboreSucceeded(moveWellboreResponse));
  } catch (ex) {
    yield put(actions.moveWellboreFailed());
    reportError(ex);
  }
}

export function* watchMoveWellbore() {
  yield takeEvery(actions.moveWellbore.toString(), moveWellbore);
}
