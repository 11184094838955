import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as actions from '../store';
import * as selectors from '../store/selectors';
import { addToast, ToastTypes } from '../utils/addToastByType';
import MyListItem from '../components/myLists/MyListItem';
import WellboreItem from '../components/myLists/WellboreItem';
import Recommendations from '../components/myLists/Recommendations';
import Loader from '../components/common/Loader';
import './MyListsPage.css';
import { getAuthServiceSingleton, getLoginErrorMessage } from '../services/adal/authService';
import { ensureAppInsightsInitialization } from '../services/appInsights/appInsightsSetupIntegration'

class MyListsPage extends Component {
  componentDidMount = () => {
    const authService = getAuthServiceSingleton();
    authService.ensureUserLoggedIn()
      .then(() => ensureAppInsightsInitialization(this))
      .then(() => this.props.initWellboreLists())
      .catch((e) => {
        throw new Error(getLoginErrorMessage(e));
      });
  }

  handleWellboreListClick = (wellboreList) => {
    this.props.selectWellboreList(wellboreList.id);
    this.props.getRecommendedWellbores(wellboreList.id);
  }

  handleEditedStateChange = (wellboreList, isEdited) => {
    this.props.changeEditedWellboreList(wellboreList.id, isEdited);
  }

  handleAddWellboreList = () => {
    this.props.addWellboreList();
  }

  handleRenameWellboreList = (wellboreList, newName) => {
    this.props.renameWellboreList(wellboreList.id, newName);
  }

  handleRemoveWellboreList = (wellboreList) => {
    this.props.removeWellboreList(wellboreList.id);
    addToast({ text: `List "${wellboreList.name}" was deleted!`, type: ToastTypes.INFO });
  }

  handleRemoveWellbore = (wellbore) => {
    const wellboreListId = this.props.selectedWellboreList.id;
    this.props.removeWellbore(wellboreListId, wellbore);
    wellbore.isDisabled = true;
  }

  handleMoveWellbore = (sourceWellboreListId, targetWellboreListId, uniqueWellboreIdentifier) => {
    this.props.moveWellbore(sourceWellboreListId, targetWellboreListId, uniqueWellboreIdentifier);
  }

  hanldeAddToWellboreList = (uniqueWellboreIdentifier) => {
    const wellboreListId = this.props.selectedWellboreList.id;
    this.props.addWellboreToSpecificList(wellboreListId, uniqueWellboreIdentifier);
  }

  render() {
    return (
      <div className="mx-2" style={{ paddingTop: '75px' }}>
        { this.props.isLoading && <Loader /> }
        <div style={this.props.isLoading ? { pointerEvents: 'none', opacity: '0.2' } : {}}>
          <div className="row">
            <div className="col-md-2">
              <button
                type="button"
                className="btn"
                onClick={this.handleAddWellboreList}
                data-toggle="tooltip"
                data-placement="right"
                title="Create new list"
              >
                <FontAwesomeIcon icon="plus" />
              </button>
            </div>
          </div>
          <div className="row">
            <div id="my-lists-left-panel" className="col-md-2">
              {this.props.wellboreLists.map((wellboreList) => (
                <MyListItem
                  key={wellboreList.id}
                  wellboreList={wellboreList}
                  isSelected={wellboreList === this.props.selectedWellboreList}
                  onClick={this.handleWellboreListClick}
                  onRemove={this.handleRemoveWellboreList}
                  onRename={this.handleRenameWellboreList}
                  onEditedStateChange={this.handleEditedStateChange}
                  isEdited={wellboreList === this.props.editedWellboreList}
                  onMoveWellbore={this.handleMoveWellbore}
                />
              ))}
            </div>
            <div className="col-md-10">
              <div className="row">
                <div id="selected-my-list-contents" className="col-md-12">
                  <div className="list-group">
                    {this.props.wellboreListLoading
                      && <Loader id="wellboreListLoader" size="s" />}
                    {!this.props.wellboreListLoading && this.props.selectedWellboreList && this.props.selectedWellboreList.wellbores
                      && this.props.selectedWellboreList.wellbores.map((wellbore) => (
                        <WellboreItem
                          key={wellbore.uniqueWellboreIdentifier}
                          wellbore={wellbore}
                          wellboreList={this.props.selectedWellboreList}
                          onRemove={this.handleRemoveWellbore}
                        />
                      ))}
                  </div>
                </div>
              </div>
              <div className="row">
                {(this.props.recommendedWellboresLoading || (this.props.recommendedWellbores && this.props.recommendedWellbores.length > 0))
                && (
                <Recommendations
                  wellbores={this.props.recommendedWellbores}
                  onAddToWellboreListClick={this.hanldeAddToWellboreList}
                  isLoading={this.props.recommendedWellboresLoading}
                />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  wellboreLists: selectors.getWellboreLists(state),
  recommendedWellbores: selectors.getRecommendedWellbores(state),
  selectedWellboreList: selectors.getSelectedWellboreList(state),
  editedWellboreList: selectors.getEditedWellboreList(state),
  isLoading: selectors.getIsLoading(state),
  recommendedWellboresLoading: selectors.getRecommendedWellboresLoading(state),
  wellboreListLoading: selectors.getWellboreListLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  initWellboreLists: () => dispatch(actions.initWellboreLists()),

  selectWellboreList: (wellboreListId) => dispatch(actions.selectWellboreList(wellboreListId)),

  getRecommendedWellbores: (wellboreListId) => dispatch(actions.getRecommendedWellbores(wellboreListId)),

  addWellboreList: () => dispatch(actions.addWellboreList()),
  addWellboreToSpecificList: (wellboreListId, uniqueWellboreIdentifier) => dispatch(actions.addWellboreToSpecificList({ wellboreListId, uniqueWellboreIdentifier })),
  removeWellboreList: (wellboreListId) => dispatch(actions.removeWellboreList(wellboreListId)),

  renameWellboreList: (wellboreListId, newName) => dispatch(actions.renameWellboreList({ wellboreListId, newName })),

  changeEditedWellboreList: (wellboreListId, isEdited) => dispatch(actions.changeEditedWellboreList({ wellboreListId, isEdited })),

  removeWellbore: (wellboreListId, wellbore) => dispatch(actions.removeWellbore({ wellboreListId, wellbore })),

  moveWellbore: (sourceWellboreListId, targetWellboreListId, uniqueWellboreIdentifier) => dispatch(actions.moveWellbore({
    sourceWellboreListId,
    targetWellboreListId,
    uniqueWellboreIdentifier,
  })),
});

MyListsPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  wellboreListLoading: PropTypes.bool.isRequired,
  recommendedWellboresLoading: PropTypes.bool.isRequired,

  wellboreLists: PropTypes.array.isRequired,
  recommendedWellbores: PropTypes.array.isRequired,
  selectedWellboreList: PropTypes.object,
  editedWellboreList: PropTypes.object,

  selectWellboreList: PropTypes.func.isRequired,
  getRecommendedWellbores: PropTypes.func.isRequired,

  addWellboreList: PropTypes.func.isRequired,
  addWellboreToSpecificList: PropTypes.func.isRequired,
  removeWellboreList: PropTypes.func.isRequired,
  renameWellboreList: PropTypes.func.isRequired,
  changeEditedWellboreList: PropTypes.func.isRequired,

  moveWellbore: PropTypes.func.isRequired,
  removeWellbore: PropTypes.func.isRequired,
};

MyListsPage.defaultProps = {
  selectedWellboreList: null,
  editedWellboreList: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyListsPage);
