import React from 'react';

const UnauthorizedPage = (props) => (
  <div className="container-fluid" style={{ paddingTop: '100px' }}>
    <div className="jumbotron" style={{ marginTop: 16, textAlign: 'center' }}>
      <h3>Sorry but you are not authorized to view that content. Please apply for the needed role(s).</h3>
    </div>
    <div style={{
      display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
    }}
    >
      <button className="btn btn-primary" onClick={() => props.history.goBack()} style={{ marginLeft: '15px' }}>
        Back
      </button>
    </div>
  </div>
);

export default UnauthorizedPage;
