import React from 'react';
import { Buffer } from 'buffer';

export const Title = (props) => <b>{props.value}</b>;

export const GetUniqueNonEmptyStringArray = (resultSet) => {
  const t = {};
  return JSON.parse(JSON.stringify(resultSet.filter((element) => !(t[element] = element in t) && !/^\s*$/.test(element))));
};

export const GetUniqueObjectArrayByProperty = (array, propertyName) => {
  const uniqueArray = [];
  const map = new Map();
  for (const element of array) {
    if (!map.has(element[propertyName])) {
      map.set(element[propertyName], true);
      uniqueArray.push(element);
    }
  }

  return uniqueArray;
};

export const CompareRankingAsc = (wpA, wpB) => {
  if (wpA.rank < wpB.rank) {
    return -1;
  }

  if (wpA.rank > wpB.rank) {
    return 1;
  }

  return 0;
};

export const CompareRankingDesc = (wpA, wpB) => {
  if (wpA.rank < wpB.rank) {
    return 1;
  }

  if (wpA.rank > wpB.rank) {
    return -1;
  }

  return 0;
};

export const Parse3DCoordinates = (data, processValues) => {
  const result = [];

  if (!data) return;

  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].length < 3) {
        continue;
      }

      const xValue = parseFloat(data[i][2]);
      const yValue = parseFloat(data[i][1]);
      const zValue = parseFloat(data[i][0]);

      if (isNaN(xValue) || isNaN(yValue) || isNaN(zValue)) {
        continue;
      }

      result.push({
        x: xValue,
        y: yValue,
        z: zValue,
      });
    }
  } else {
    const lines = data.split('\n');
    for (let i = 0; i < lines.length; i += 1) {
      let values = lines[i].split(',');

      if (values.length < 6) {
        values = lines[i].split('\t');
      }

      if (values.length < 6) {
        continue;
      }

      const xValue = parseFloat(values[5]);
      const yValue = parseFloat(values[4]);
      const zValue = parseFloat(values[3]);
      const md = parseFloat(values[0]);

      if (isNaN(xValue) || isNaN(yValue) || isNaN(zValue)) {
        continue;
      }

      result.push({
        x: xValue,
        y: yValue,
        z: zValue,
        md,
      });
    }
  }

  if (processValues) {
    Process3DCoordinates(result);
  }

  return result;
};

export const Process3DCoordinates = (data) => {
  if (data.length > 0) {
    let moveX = 0.0;
    let moveY = 0.0;
    let reverseZ = false;

    if (data[0].x !== 0.0) moveX = data[0].x;
    if (data[0].y !== 0.0) moveY = data[0].y;
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].z && data[i].z > 0) {
        reverseZ = true;
        break;
      }
    }

    for (let i = 0; i < data.length; i += 1) {
      if (moveX !== 0.0) data[i].x -= moveX;
      if (moveY !== 0.0) data[i].y -= moveY;
      if (reverseZ) data[i].z *= -1;
    }
  }
};

export const Unpack = (rows, key) => rows.map((row) => row[key]);

export const NormalizeObjectProperties = (object) => {
  const max = Math.max(...Object.values(object));
  for (const prop in object) {
    object[prop] /= max;
  }
};

export const GoToDiv = (divID) => {
  const el = $(`#${$.escapeSelector(divID)}`);
  if (el && el.offset()) {
    $('html, body').animate({
      scrollTop: el.offset().top,
    }, 500);
  }
};

export const ToHex = (str) => Buffer.from(str).toString('hex');

export const FromHex = (str) => Buffer.from(str, 'hex').toString();

export const IsHex = (str) => /^[0-9A-Fa-f]+$/.test(str);

export const RegExpQuote = (str) => str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');

export const ArrayToCSV = (arr) => {
  const array = typeof arr !== 'object' ? JSON.parse(arr) : arr;
  let str = '';

  if (array.length <= 0) return str;

  for (let j = 0; j < Object.keys(array[0]).length; j++) {
    str += Object.keys(array[0])[j];
    if (j < Object.keys(array[0]).length - 1) {
      str += '\t';
    } else {
      str += '\r\n';
    }
  }

  for (let i = 0; i < array.length; i += 1) {
    let line = '';
    for (const index in array[i]) {
      if (line !== '') line += '\t';
      line += array[i][index];
    }

    str += `${line}\r\n`;
  }

  return str;
};

export const MaxLengthOf = (arrOfObjects) => {
  let max = 0;
  arrOfObjects.forEach((obj) => {
    if (obj && obj.length > max) max = obj.length;
  });

  return max;
};

export const XOR = (x, y) => ((x && !y) || (!x && y));
