import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/file';
import './VideoItem.css';

export default class VideoItem extends Component {
  getSubtitleTrack = function (subtitle) {
    return {
      kind: 'subtitles',
      src: subtitle.subtitleUri,
      srcLang: subtitle.languageCode,
      default: subtitle.defaultCaption,
    };
  }

  render() {
    const { url, videoName, subtitles } = this.props;
    return (
      <div className="col-sm-12 videoItem">
        <p>
          Video:
          <strong>{videoName}</strong>
        </p>
        <ReactPlayer
          width="600px"
          height="337px"
          url={url}
          controls
          config={{
            attributes: {
              crossOrigin: 'anonymous',
            },
            file: {
              tracks: !subtitles ? [] : subtitles.map((item) => this.getSubtitleTrack(item)),
            },
          }}
        />
      </div>
    );
  }
}

VideoItem.propTypes = {
  url: PropTypes.string.isRequired,
  videoName: PropTypes.string.isRequired,
};

VideoItem.defaultProps = {
  //
};
