import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronUp, faChevronDown, faEllipsisH, faTrashAlt, faPlus, faEllipsisV, faStar,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import { AppNavigator } from '../navigation';
import AppHeader from '../components/common/AppHeader';
import { authStatusTypes } from '../types';
import { getAppVersion } from '../settings';
import ErrorBoundary from '../components/common/ErrorBoundary';
import * as actions from '../store';
import * as selectors from '../store/selectors';
import ConfirmModal from '../components/common/ConfirmModal';
import FeedbackModal from '../components/common/FeedbackModal';

library.add(faChevronUp, faChevronDown, faEllipsisH, faTrashAlt, faPlus, faEllipsisV, faStar);

class App extends React.Component {
  static propTypes = {
    version: PropTypes.string,
    setVersion: PropTypes.func.isRequired,
  };

  static defaultProps = {
    version: null,
  };

  componentDidMount() {
    const appVersion = getAppVersion();
    if (this.props.version !== appVersion) {
      this.props.setVersion(appVersion);
    }
  }

  render() {
    const lightTheme = createTheme({
      palette: {
        type: 'light',
      },
    });

    return (
      <ThemeProvider theme={lightTheme}>
        <ErrorBoundary>
          <div>
            <AppNavigator
              appHeader={() => (
                <AppHeader />
              )}
            />
            <ConfirmModal />
            <FeedbackModal />
            <ToastContainer />
          </div>
        </ErrorBoundary>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  version: selectors.getVersion(state),
});

const mapDispatchToProps = (dispatch) => ({
  setVersion: (version) => dispatch(actions.setVersion(version)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
